<template>
	<svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path :fill="colourRef"
					d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"/>
	</svg>
</template>

<script>
export default {
	props: ['fill'],
	data() {
		return {
			colourRef: this.fill ? this.fill : '#FFFFFF'
		}
	}
}
</script>
