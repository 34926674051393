<template>
	<div>
		<div v-observe-visibility="{ callback: (isVisible, entry) => getCategoryLatest(isVisible, entry, id), once: true }">
			<div class="bg-light pb-5 pt-3" v-show="clips.length > 2">
				<div class="container">
					<h2 class="text-center">{{ name }}</h2>
				</div>
				<transition name="fade">
					<Slider :clips="clips" v-if="clips.length"></Slider>
				</transition>
			</div>
		</div>
		<div class="text-center bg-light py-5" v-if="!!clipsLoading">
			<div class="py-5 my-4">
				<IconLoading width="50px" height="50px" color="#333"></IconLoading>
			</div>
		</div>
	</div>

</template>

<script>
import Slider from '@/components/Slider';
import IconLoading from '@/components/icons/IconLoading';

export default {
	data() {
		return {
			clips: [],
			clipsLoading: true,
			noClips: false
		}
	},
	props: ['id', 'name'],
	components: {
		Slider,
		IconLoading
	},
	methods: {
		getCategoryLatest(isVisible, entry, category) {
			if (isVisible) {
				this.clipsLoading = true;
				try {
					let url = this.$apiBaseURL + `/clip/category/${this.id}`

					const response = async () => {
						await fetch(url, {
							method: 'GET',
						})

						const result = await response.json()

						if (result.success === true) {
							this.clips = result.data;
							this.clipsLoading = false;

							if (this.clips.length < 4) {
								this.noClips = true;
							}
						}
					}
				} catch (error) {
					console.error(error);
				}
			}
		}
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
