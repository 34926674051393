<template>
	<div class="SearchBar">
		<h5 class="SearchBar-heading">{{ searchMessage }}</h5>
		<form class="SearchBox" @submit.prevent="submitSearch">
			<input type="text" class="form-control" placeholder="Enter keyword" v-model="keyword">
			<select class="custom-select" v-model="type">
				<option value="video">Videos</option>
				<option value="image">Images</option>
				<option value="packages">Packages</option>
				<option value="short">Shorts</option>
			</select>
			<button type="submit" class="SearchBox-button">
				<IconSearch></IconSearch>
			</button>
		</form>
	</div>
</template>

<script>
import IconSearch from '@/components/icons/IconSearch';
import {EventBus} from '@/event-bus.js';

export default {
	data() {
		return {
			keyword: '',
			type: ''
		}
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		searchMessage() {
			let message = "";

			if (this.type === 'image')
				message = "Search for an image";
			else if (this.type === 'video')
				message = "Search for a video";
			else if (this.type === 'short')
				message = "Search for a short";
			else
				message = "Search for a package";

			return message;
		}
	},
	components: {
		IconSearch
	},
	methods: {
		submitSearch() {
			let query = Object.assign({}, this.$route.query, {
				term: this.keyword,
				type: this.type,
				page: 1
			});

			if (this.$route.name === 'search') {
				this.$router.replace({query: query});
				this.$emit('keywordSearchUpdated');
			} else {
				this.$router.push({name: 'search', query: query});
			}
		},
		clearSearchInput() {
			this.keyword = '';
		}
	},
	mounted() {
		this.keyword = this.$route.query.term;
		this.type = this.$route.query.type ? this.$route.query.type : 'video';
	}
}
</script>
