<template>
	<transition name="fade">
		<div class="Download p-3">
			<div class="Purchases-text">
				<div class="Download-text-ref mt-2" v-if="purchaseDate">
					Purchase Date: <b>{{ purchaseDate | moment("Do MMMM YYYY") }}</b>
				</div>
				<div class="Download-text-ref mt-2">
					Purchase Ref: <b>{{ opid}}-{{ purchaseRef }}</b>
				</div>
				<div class="Download-text-ref mt-2" v-if="amount">
					Fee: <b>{{ amount.toFixed(2) }} {{ currency.toUpperCase() }}</b>
				</div>

				<div class="Download-text-ref mt-2">
					Clip(s) associated with this purchase:

					<div v-if="purchasedClips.length" class="d-inline-block">
						<template v-for="(purchasedClip, idx) in purchasedClips" class="d-inline">
							<router-link
								:to="{ name: 'clip', params: { id: parseInt(purchasedClip) }}"
								:key="purchasedClip"
								target="_blank"
								class="alert-link"
							>
								{{ purchasedClip }}{{ (purchasedClips.length - 1) === idx ? '' : ',' }}
							</router-link>
						</template>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>

export default {
	props: {
		id: {Type: String, default: ''},
		purchaseDate: {Type: String, default: ''},
		opid: {Type: Number, default: null},
		purchaseRef: {Type: String, default: ''},
		product: {Type: String, default: null},
		amount: {Type: Number, default: ''},
		currency: {Type: String, default: ''},
		purchasedClips: {Type: Array, default: null},
	},
	components: {},
	data() {
		return {}
	},
	computed: {
		canPurchase() {
			return this.$store.state.account.canPurchase;
		},
	},
	methods: {},
	mounted() {
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
