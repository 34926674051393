<template>
	<div class="py-4 py-md-5">
		<div class="container">
			<div class="row">
				<div class="col-md-10 col-lg-6 col-xl-4 offset-md-1 offset-lg-3 offset-xl-4">
					<div class="shadow-lg p-4 bg-white">
						<h1 class="text-uppercase">Login</h1>
						<form @submit.prevent="onSubmit">
							<div class="form-group">
								<label for="username">Email address</label>
								<input
									type="email"
									class="form-control"
									id="username"
									name="username"
									placeholder="Enter email"
									v-model="username"
									required
								>
							</div>
							<div class="form-group">
								<label for="password">Password</label>
								<input
									type="password"
									class="form-control"
									id="password"
									name="password"
									placeholder="Password"
									v-model="password"
									required
								>
							</div>
							<div class="d-flex justify-content-between align-items-center mt-4">
								<button type="submit" class="btn btn-primary">Log in</button>
								<small>
									<router-link
										:to="{ name: 'forgotPassword' }"
									>
										Forgot password?
									</router-link>
								</small>
							</div>

						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			username: '',
			password: '',
			errors: ''
		}
	},
	beforeCreate() {
		if (this.$route.query.checkout !== undefined) {
			this.$store.dispatch('updateAuthCheckout', true)
		}

		if (this.$store.state.isLoggedIn) {
			this.$router.push({name: 'home'});
		}
	},
	methods: {
		async onSubmit() {
			let requestBody = {
				email: this.username,
				password: this.password
			}

			try {
				let url = this.$apiBaseURL + '/clip/users/login'

				const response = await fetch(url, {
					headers: {
						"Content-Type": "application/json"
					},
					method: 'POST',
					body: JSON.stringify(requestBody)
				})

				const result = await response.json()

				if (result.success === true) {
					if (result.httpStatusCode !== 200) { // unsuccessful login
						this.showNotification(false, result.message);
					} else {
						// successful login
						const data = result.data
						await this.$store.dispatch('login', {token: data.token, id: data.id});
						this.showNotification(result.success, result.message);
					}
				}
			} catch (error) {
				console.error(error);
			}
		}
	}
}
</script>
