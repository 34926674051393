<template>
	<div class="Comparison">
		<div class="modal-text-small">
			<div class="row">
				<div class="col-12">
					<h5>Licensing Options</h5>
					<p>
						<b>Digital Editorial:</b> includes owned & operated websites, social media and blogs.<br>
						<b>National News/Talk Show:</b> includes all nationally airing news and talk show produced either daily or
						weekly<br>
						<b>Clips Shows:</b> includes all longform (non-theatrical) non-scripted productions including clip shows,
						documentaries, nature shows, and reality shows
					</p>
				</div>
			</div>

			<div class="row">

				<div class="col-3">
					<p class="text-info">&nbsp;</p>
					<div class=" Comparison--features-panel">
						<span>Fee <span class="d-inline text-danger">**</span></span>
						<span>Monetization Allowed</span>
						<span>Number of social media platforms</span>
						<span>Total number of subscribers across your network</span>
						<span>Number of Posts per Channel</span>
						<span>Number of brands that may post the video</span>
						<span>Number of on-air uses/episodes</span>
						<span>Videos can be edited for editorial purposes</span>
						<span>Distribution</span>
						<span>Indemnification + Reps & Warranties (non negotiable terms)</span>
					</div>
				</div>

				<div class="col-9">
					<div class="row">

						<template v-for="po in pricingOptions.video">
							<div class="col text-center">
								<span class="Comparison--options-header">
									{{ po.name }}
									<template v-if="po.code === 'DEMA'">
											<small class="text-danger">*</small>
										</template>
								</span>
								<div class="border Comparison--options-panel">
									<span class="Comparison--options-panel-price"><b>{{ currencySymbol }}{{ po.options.bundle_1 }}</b> per video</span>
									<TierComparisonOptions :tierCode="po.code"></TierComparisonOptions>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div class="col-xs-12">
					<small class="d-block text-danger pl-2">
					<span style="font-size: 10px;"><i>*</i> Not Applicable for networks over 10 million, price on application. Please
								contact licensing@catersnews.com</span>
					</small>
					<small v-if="currency==='GBP'|| currency==='AUD'" class="d-block text-danger mb-3 pl-2">
						<span style="font-size: 10px;"><i>** Local sales taxes apply</i></span>
					</small>
				</div>
			</div>


			<div class="row mt-5">
				<div class="col-12 text-right">
					<router-link
						:to="{ name: 'faq' }"
						class="Button Button--smaller Button--purple"
					>
						FAQ
					</router-link>

					<a href="./docs/licensing-terms.pdf" target="_blank" class="Button Button--smaller">FULL TERMS</a>
				</div>
			</div>

		</div>
	</div>

</template>

<script>

import TierComparisonOptions from "@/components/TierComparisonOptions.vue";
import IconSettings from "@/components/icons/IconSettings.vue";

export default {
	components: {
		IconSettings,
		TierComparisonOptions
	},
	computed: {
		pricingOptions() {
			return this.$store.state.pricingOptions[this.currency]
		},
		currency() {
			return this.$store.state.defaultCurrency.currency
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol
		},
		selectedTier() {
			return this.$store.state.selectedTier
		},
	},
	methods: {
		selectPricing(tier) {

			this.$emit('tier-selected', tier)
			// this.$store.commit('setPricingTier', tier)


			// this.$emit('selected-tier', tier)
			this.$modal.hide('pricing-options-modal');
		}
	}
}


</script>

<style scoped>

</style>
