<template>
	<div>
		<SearchBar></SearchBar>

		<template v-if="!isError && !isLoading">
			<div class="py-5">
				<div class="container page">
					<div class="row">
						<div class="col-12">
							<h2 class="text-uppercase" v-if="name">
								Shared Collection: <span class="u-textColour--green">{{ name }}</span>
							</h2>
							<div class="col-12">
								<transition name="fade">
									<div v-show="!isLoading && !imagesLoading">
										<div class="ResultsGrid pl-lg-2">
											<template v-for="clip in clips">
												<div class="ResultsGrid-item">

													<ClipCard
														enablePopup="true"
														:type="clip.type"
														:id="clip.id"
														:title="clip.title"
														:rating="clip.meta.rating"
														:duration="clipIsVideo(clip) ? clip.meta.duration : ''"
														:definition="clipIsVideo(clip) ? clip.meta.definition : ''"
														:thumbnail="fileUrl(clip.thumbpath)"
														:hlsActive="clipIsVideo(clip) ? clip.meta.hls : false"
														:media="getClipMedia(clip)"
														:hlsmedia="(clipIsVideo(clip) && clip.meta.hls_path !==null) ? fileUrl(clip.meta.hls_path) : ''"
														:graphic="clipIsVideo(clip) ? clip.graphic : ''"
														:dateAdded="clip.created"
														:notForSocial="clip.meta.notSocial"
														:enquiriesOnly="clip.meta.enquire">
													</ClipCard>

												</div>
											</template>
										</div>
									</div>
								</transition>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-else-if="isError && !isLoading">
			<div class="container py-5 mt-1">
				<div class="row">
					<div class="col-lg-8 offset-lg-2">
						<div class="alert alert-danger text-center">
							{{ errorMessage }}
						</div>
					</div>
				</div>
			</div>
		</template>

		<div class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>
	</div>
</template>

<script>
import DownloadCard from '@/components/DownloadCard'
import Loading from 'vue-loading-overlay'
import SearchBar from '@/components/SearchBar'
import ClipCard from "@/components/ClipCard.vue";
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	data() {
		return {
			ref: this.$route.params.ref,
			name: null,
			created: null,
			clips: [],
			id: null,
			type: 'video',
			imagesLoading: false,
			isLoading: true,
			isError: false,
			errorMessage: ''
		}
	},
	components: {
		Loading,
		DownloadCard,
		SearchBar,
		ClipCard
	},
	computed:{
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
	},
	methods: {
		async fetchList() {
			try {
				let url = this.$apiBaseURL + `/clip/savelists/shared/${this.ref}`

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Content-Type': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.name = result.data.name;
							this.created = result.data.created;
							this.clips = result.data.clips;
						}

						this.isLoading = false;
					})
					.catch(error => {
						console.log(error);
						this.isLoading = false;
					});
			} catch (error) {
				console.log(error)
			}
		},
		getClipMedia(clip) {
			return this.clipIsVideo(clip) ? this.fileUrl(clip.filepath) : this.fileUrl(clip.thumbpath);
		},
		clipIsVideo(clip) {
			return clip.type == 'video';
		}
	},
	created() {
		this.fetchList();
	},
}
</script>

<style>
</style>
