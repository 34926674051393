<template>
	<div>
		<SearchBar></SearchBar>
		<div class="py-4 py-md-5">
			<div class="container page">
				<div class="row">
					<div class="col-md-10 col-lg-8 col-xxl-6 offset-md-1 offset-lg-2 offset-xxl-3">
						<h2 class="text-uppercase">Download Link</h2>

						<form class="mt-2" v-if="!verified" @submit.prevent="onSubmit">
							<p class="u-textColour--purple">Please verify your email address before proceeding to download.</p>
							<div class="form-group">
								<label for="email">Email</label>
								<input
									type="email"
									class="form-control"
									id="email"
									v-model="email"
									required
								>
							</div>
							<button
								type="submit"
								class="btn btn-primary btn-block"
								:disabled="lockSubmit"
							>
								{{lockSubmit ? 'Checking...' : 'Proceed'}}
							</button>
						</form>

						<template v-if="verified">
							<div class="mb-4"
								v-for="item in clips"
								:key="item.id"
							>
								<DownloadCard
									:id="item.id"
									:clipType="item.type">
								</DownloadCard>
							</div>
						</template>

						<template v-if="isDownloading">
							<div class="vld-parent">
								<loading
									:active.sync="isDownloading"
									:is-full-page="false"
									:opacity="0"
								>
								</loading>
							</div>
							<div class="text-center mt-4">
								We are now preparing your file(s). Please stay on this page.
							</div>
						</template>

						<template v-if="isDownloadReady">
							<a
								:href="downloadLink"
								class="bg-white d-flex w-100 py-4 px-3 justify-content-center text-center"
							>
								<b>Your files are ready! Click here to download.</b>
							</a>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import SearchBar from '@/components/SearchBar';
import DownloadCard from '@/components/DownloadCard';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	props: {},
	data() {
		return {
			ref: this.$route.params.ref,
			email: '',
			verified: false,
			lockSubmit: false,
			clientIp: '',
			clips: [],
			isDownloading: false,
			isDownloadReady: false,
			downloadLink: '',
			linkId: null,
		}
	},
	components: {
		SearchBar,
		DownloadCard,
		Loading
	},
	methods: {
		async onSubmit() {
			this.lockSubmit = true;

			// Capture the ip address
			if (this.clientIp === '') {
				await fetch('https://api.ipify.org?format=json')
					.then(response => response.json())
					.then(response => {
						this.clientIp = response.ip;
					});
			}

			let requestBody = {
				ref: this.ref,
				email: this.email,
				clientIp: this.clientIp,
			}

			try {
				let url = this.$apiBaseURL + '/clip/downloads/link';

				const response = await fetch(url, {
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(requestBody)
				})

				const result = await response.json();

				if (result.success === true) {
					this.verified = true;
					this.clips = result.data.clips;
					this.linkId = result.data.linkId;

					// Start downloading the clip
					await this.processDownloads();
				} else {
					this.lockSubmit = false;
					this.showNotification(false, result.message);
				}
			} catch (error) {
				this.showNotification(false, 'An error occurred');
			}
		},
		async processDownloads() {
			this.isDownloading = true;
			let ids = [];

			this.clips.forEach((clip) => {
				ids.push(clip.id);
			})

			try {
				let payload = {
					ids: ids,
					link: this.linkId
				};

				let url = this.$apiBaseURL + '/clip/clips/download-link'

				const response = await fetch(url, {
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(payload),
				})

				const result = await response.json();
				this.isDownloading = false;

				if (result.success === true) {
					this.isDownloadReady = true;
					this.downloadLink = result.data.file_url;
				} else {
					this.showNotification(false, result.message);
				}
			} catch (error) {
				this.isDownloading = false;
			}
		},
	},
}
</script>

<style scoped>
.vld-parent {
	width: 64px;
	height: 64px;
	margin: 0 auto;
}

.vld-overlay:focus {
	outline: 0;
}
</style>
