<template>
	<div class="row">
		<template v-for="po in pricingOptions.video">
			<div class="col-3 text-center">
				<p class="u-textColour--orange font-weight-bold">{{ po.name.toUpperCase() }}</p>
				<b-btn
					v-if="!selectedTier || selectedTier === po.code"
					squared
					@click.prevent="$emit('tierSelected', {
							tierUid:po.uid,
							tierCode:po.code,
							tierName:po.name,
							tierUsageType:po.usage_type,
							bundleRef:'bundle_1',
							price:parseInt(po.options.bundle_1),
							currency:currency
						})"
					size="sm"
					variant="success"
				>
					{{ currencySymbol }}{{ po.options.bundle_1 }}&nbsp;{{ currency }}
				</b-btn>
				<b-btn
					v-else
					@click.prevent="alert('you cannot choose this option')"
					squared
					size="sm"
					variant="danger"
				>
					{{ currencySymbol }}{{ po.options.bundle_1 }}&nbsp;{{ currency }}
				</b-btn>
			</div>
		</template>
	</div>
</template>

<script>

export default {
	computed: {
		pricingOptions() {
			return this.$store.state.pricingOptions[this.currency]
		},
		currency() {
			return this.$store.state.defaultCurrency.currency
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol
		},
		selectedTier() {
			return this.$store.state.selectedTier
		},
	},
	methods: {}
}


</script>

<style scoped>

</style>
