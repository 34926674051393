<template>
	<div class="d-lg-flex">
		<div class="u-bg-gradient--v px-3 py-4 px-md-5 py-sm-5 col-lg-6">
			<div class="container">
				<h1 class="text-uppercase mb-2">OUR LOCATIONS</h1>
				<div class="contact-wrap py-3">
					<Locations></Locations>
				</div>
			</div>
		</div>

		<div class="bg-white shadow-lg px-3 py-4 px-md-5 py-sm-5 col-lg-6">
			<div class="container">
				<div class="row">
					<div class="col-xl-11 col-xxl-10">
						<h1 class="text-uppercase mb-1">Send us a message</h1>
						<form :model="form" class="mt-4" v-if="!success" @submit.prevent="onSubmit">
							<p class="u-textColour--green font-weight-bold">Please fill in the details below</p>
							<div class="form-group">
								<label for="name">Your full name (required)</label>
								<input type="text" class="form-control" id="name" v-model="name" required>
							</div>
							<div class="form-group">
								<label for="email">Your email address (required)</label>
								<input type="email" class="form-control" id="email" v-model="email" required>
							</div>
							<div class="form-group">
								<label for="company">Your organisation (required)</label>
								<input type="text" class="form-control" id="company" v-model="company" required>
							</div>
							<div class="form-group">
								<label for="type">Type of enquiry (required)</label>
								<select class="custom-select" id="type" v-model="type" required>
									<option value="" selected disabled>Select an option</option>
									<option value="1">Licensing</option>
									<option value="2">Editorial</option>
									<option value="3">Support</option>
									<option value="0">Other</option>
								</select>
							</div>
							<div class="form-group">
								<label for="message">Your message</label>
								<textarea id="message" class="form-control" rows="5" v-model="message" required></textarea>
							</div>
							<div class="alert alert-danger" role="alert" v-if="errors">
								{{ errorMessage }}
							</div>

							<vue-recaptcha
								class="pb-2"
								ref="recaptcha"
								@verify="onVerify"
								sitekey="6LdweM4oAAAAAA9feCcNBOtVLZVOCn9yOQss0wcR">
							</vue-recaptcha>

							<div class="form-group">
								<button type="submit" class="btn btn-primary">Send
									<IconArrow class="ml-2"></IconArrow>
								</button>
							</div>
						</form>
						<div v-if="success" class="alert alert-success">
							{{ successMessage }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {VueRecaptcha} from "vue-recaptcha";
import Locations from '@/components/Locations.vue'
import IconArrow from '@/components/icons/IconArrow'

export default {
	data() {
		return {
			form: {
				robot: false
			},
			name: '',
			email: '',
			company: '',
			type: '',
			message: '',
			ashp: '', // Anti-Spam Honey Pot
			errors: false,
			success: false,
			successMessage: '',
			errorMessage: '',
		}
	},
	components: {
		'vue-recaptcha': VueRecaptcha,
		Locations,
		IconArrow
	},
	computed: {},
	methods: {
		onVerify: function (response) {
			if (response) this.form.robot = true;
		},
		async onSubmit() {
			if (this.form.robot === true) {

				let requestBody = {
					name: this.name,
					email: this.email,
					company: this.company,
					type: this.type,
					message: this.message,
					ashp: this.ashp,
				}

				try {
					let url = this.$apiBaseURL + `/clip/forms/contact`

					const response = await fetch(url, {
						headers: {
							'Content-Type': 'application/json',
						},
						method: 'POST',
						body: JSON.stringify(requestBody)
					})

					const result = await response.json()

					if (result.success === true) {
						this.success = result.success;
						this.successMessage = result.message;
					} else {
						this.errors = result.success;
						this.errorMessage = result.message;
					}
				} catch (error) {
					this.showNotification(false, 'An error occurred');
					console.error(error);
				}

			}
		},
	},
	created() {
	},
}
</script>

<style>
.u-bg-gradient--v a {
	color: #fff;
}

@media (min-width: 768px) and (max-width: 991px), (min-width: 1600px) {
	.contact-wrap {
		display: flex;
		flex-wrap: wrap;
	}

}
</style>
