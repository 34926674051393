<!--suppress ALL -->
<template>
	<div>
		<SearchBar ref="search" @keywordSearchUpdated="submitSearchUpdate"></SearchBar>
		<div class="bg-grey py-3 active-filters" v-if="isFiltered">
			<div class="container">
				<div class="row">
					<div class="col-lg-9 col-xl-10 offset-lg-3 offset-xl-2">
						<h5 class="my-1 mx-1 pl-lg-2">

							<div class="d-inline-block py-2 mr-3" v-if="keyword">
								Keywords: <span class="font-weight-normal">{{ keyword }}</span>
								<a href="#" @click.prevent="dismissKeyword()">
									<IconClose width="22px" fill="#5F007F">X</IconClose>
								</a>
							</div>

							<div class="d-inline-block py-2 mr-2" v-if="cat_name && typeIsVideoOrImage">
								<span class="mr-2">Category: </span>
								<span class="mr-2 pr-1 font-weight-normal">
									{{ cat_name }}
									<a href="#" @click.prevent="dismissCategory()">
										<IconClose width="22px" fill="#5F007F">X</IconClose>
									</a>
								</span>
							</div>

							<div class="d-inline-block py-2 mr-2"
									 v-if="filters.category_chosen.length && !cat_name && typeIsVideoOrImage">
								<span class="mr-2">Category: </span>
								<span class="mr-2 pr-1 font-weight-normal" v-if="!cat_name" v-for="cat in filters.category_chosen">
									{{ getCategoryName(cat) }}
									<a href="#" @click.prevent="dismissFilter(filters.category_chosen, cat)">
										<IconClose width="22px" fill="#5F007F">X</IconClose>
									</a>
								</span>
							</div>

							<div class="d-inline-block py-2 mr-2"
									 v-if="filters.tones_chosen.length && filters.tones.length && typeIsVideo">
								<span class="mr-2">Tone:</span>
								<span class="mr-2 pr-1 font-weight-normal" v-for="tone in filters.tones_chosen">
									{{ getToneName(tone) }}
									<a href="#" @click.prevent="dismissFilter(filters.tones_chosen, tone)">
										<IconClose width="22px" fill="#5F007F">X</IconClose>
									</a>
								</span>
							</div>

							<div class="d-inline-block py-2 mr-2"
									 v-if="filters.ftypes_chosen.length && filters.ftypes.length && typeIsVideo">
								<span class="mr-2">Footage type:</span>
								<span class="mr-2 pr-1 font-weight-normal" v-for="ftype in filters.ftypes_chosen">
									{{ getFtypeName(ftype) }}
									<a href="#" @click.prevent="dismissFilter(filters.ftypes_chosen, ftype)">
										<IconClose width="22px" fill="#5F007F">X</IconClose>
									</a>
								</span>
							</div>

							<div class="d-inline-block py-2 mr-2" v-if="isDurationFiltered && typeIsVideo">
								<span class="mr-2">Duration:</span>
								<span class="mr-2 pr-1 font-weight-normal">
									{{ displayDurationFilter() }}
									<a href="#" @click.prevent="dismissDurationFilter()">
										<IconClose width="22px" fill="#5F007F">X</IconClose>
									</a>
								</span>
							</div>

							<div class="d-inline-block py-2" v-if="filters.quality_chosen.length && typeIsVideo">
								<span class="mr-2">Quality:</span>
								<span class="mr-2 pr-1 font-weight-normal" v-for="quality in filters.quality_chosen">
									{{ quality }}
									<a href="#" @click.prevent="dismissFilter(filters.quality_chosen, quality)">
										<IconClose width="22px" fill="#5F007F">X</IconClose>
									</a>
								</span>
							</div>

							<div class="d-inline-block py-2 mr-3" v-if="filters.meme && typeIsVideoOrImage">
								MEME
								<a href="#" @click.prevent="dismissMemeFilter()">
									<IconClose width="22px" fill="#5F007F">X</IconClose>
								</a>
							</div>

							<div class="d-inline-block py-2" v-if="filters.date_period_chosen">
								<span class="mr-2">Period:</span>
								<span class="mr-2 pr-1 font-weight-normal">
									{{ filters.date_period_chosen }}
									<a href="#" @click.prevent="dismissDateFilter()">
										<IconClose width="22px" fill="#5F007F">X</IconClose>
									</a>
								</span>
							</div>
						</h5>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid py-3 ResultsGrid--container">
			<div class="row">
				<div class="col-lg-3 col-xl-2 p-0">
					<div class="Filters mt-1 p-0">
						<h5 class="d-flex align-items-center p-3 mb-0">
							<IconFilter></IconFilter>
							<span class="ml-2">Filters</span>
						</h5>
						<form>

							<!-- Sorting -->
							<div class="form-group border-top border-bottom mb-0">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse0 variant="primary"><span>Sort by</span><span
									class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse0">
									<div class="px-3 pb-3 pt-2">
										<select class="custom-select" v-model="filters.sort" @change="searchUpdated(true)">
											<option value="most_recent">Most Recent</option>
											<option value="most_rel">Most Relevant</option>
											<option value="least_recent">Least Recent</option>
											<option value="longest_duration">Longest Duration</option>
											<option value="shortest_duration">Shortest Duration</option>
										</select>
									</div>
									<div class="px-3 pb-3" v-if="typeIsVideoOrImage">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" id="cb-meme" name="meme"
														 class="custom-control-input" value="1"
														 @change="searchUpdated()"
														 v-model="filters.meme">
											<label class="custom-control-label" for="cb-meme">MEME</label>
										</div>
									</div>
								</b-collapse>
							</div>

							<!-- Location -->
							<div class="form-group border-bottom mb-0">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse8 variant="primary"><span>Location</span>
									<span class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse8">
									<div class="px-3 pb-3 pt-2">
										<template v-for="location in filters.locations">
											<div class="custom-control custom-checkbox">
												<input type="checkbox" :id="`${location.id}`" name="location"
															 class="custom-control-input" :value="location.id"
															 @change="searchUpdated()"
															 v-model="filters.location_chosen">
												<label class="custom-control-label" :for="`${location.id}`">{{
														location.name
													}}</label>
											</div>
										</template>
										<div class="mt-2 input-group-sm">
											<input type="text" class="form-control" placeholder="Enter location"
														 name="loc2"
														 v-model="filters.loc2" @keyup="searchUpdated()">
										</div>
									</div>
								</b-collapse>
							</div>

							<!-- Rating -->
							<!-- Hidden by team request -->
							<div class="form-group border-bottom mb-0" v-if="false">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse7 variant="primary"><span>Rating</span>
									<span class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse7">
									<div class="px-3 pb-3 pt-2">
										<template v-for="rating in filters.ratings">
											<div class="custom-control custom-checkbox">
												<input type="checkbox" :id="`${rating.id}`" name="clip_rating"
															 class="custom-control-input" :value="rating.id"
															 @change="searchUpdated()"
															 v-model="filters.rating_chosen">
												<label class="custom-control-label" :for="`${rating.id}`">
													{{ rating.name }}
												</label>
											</div>
										</template>
									</div>
								</b-collapse>
							</div>

							<!-- Categories -->
							<div class="form-group border-bottom mb-0" v-if="!this.cat_name && typeIsVideoOrImage">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse1 variant="primary"><span>Category</span><span
									class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse1">
									<div class="px-3 pb-3 pt-2">
										<template v-for="category in filters.categories">
											<div class="custom-control custom-checkbox">
												<input type="checkbox" :id="`cat${category.id}`" name="category"
															 class="custom-control-input" :value="category.id"
															 @change="searchUpdated()" v-model="filters.category_chosen">
												<label class="custom-control-label" :for="`cat${category.id}`">
													{{ category.name }}
												</label>
											</div>
										</template>
									</div>
								</b-collapse>
							</div>

							<!-- Tones/moods -->
							<div class="form-group border-bottom mb-0" v-if="typeIsVideo">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse2 variant="primary"><span>Tone</span><span
									class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse2">
									<div class="px-3 pb-3 pt-2">
										<template v-for="tone in filters.tones">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													:id="`tone${tone.id}`"
													name="tone"
													class="custom-control-input" :value="tone.id"
													@change="searchUpdated()" v-model="filters.tones_chosen"
												>
												<label class="custom-control-label" :for="`tone${tone.id}`">
													{{ tone.displayname }}
												</label>
											</div>
										</template>
									</div>
								</b-collapse>
							</div>

							<!-- Footage Type -->
							<div class="form-group border-bottom mb-0" v-if="typeIsVideo">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse3 variant="primary"><span>Footage type</span><span
									class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse3">
									<div class="px-3 pb-3 pt-2">
										<template v-for="ftype in filters.ftypes">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													:id="`ftype${ftype.id}`"
													name="ftype"
													class="custom-control-input"
													:value="ftype.id"
													@change="searchUpdated()"
													v-model="filters.ftypes_chosen"
												>
												<label class="custom-control-label"
															 :for="`ftype${ftype.id}`">
													{{ ftype.displayname }}
												</label>
											</div>
										</template>
									</div>
								</b-collapse>
							</div>

							<!-- Duration -->
							<div class="form-group border-bottom mb-0" v-if="typeIsVideo">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse4 variant="primary"><span>Duration</span><span
									class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse4">
									<div class="px-3 pb-4 pt-2">
										<select class="custom-select" v-model="filters.duration" @change="searchUpdated()">
											<option value="">Any</option>
											<option value="0-30">0 - 30 secs</option>
											<option value="31-60">31 secs - 1 min</option>
											<option value="61-180">1:01 - 3 min</option>
											<option value="181">3 min+</option>
										</select>
									</div>
								</b-collapse>
							</div>

							<!-- Quality/definition -->
							<div class="form-group border-bottom mb-0" v-if="typeIsVideo">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse5 variant="primary"><span>Quality</span><span
									class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse5">
									<div class="px-3 pb-4 pt-2">
										<template v-for="(quality, index) in filters.qualities">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													:id="`quality${index}`"
													name="quality"
													class="custom-control-input"
													:value="quality"
													@change="searchUpdated()"
													v-model="filters.quality_chosen"
												>
												<label class="custom-control-label" :for="`quality${index}`">
													{{ quality }}
												</label>
											</div>
										</template>
									</div>
								</b-collapse>
							</div>

							<!-- Date -->
							<div class="form-group mb-3">
								<label class="Filters-section font-weight-bold d-flex justify-content-between"
											 v-b-toggle.collapse6 variant="primary"><span>Date period</span><span
									class="when-closed">+</span><span class="when-open">–</span></label>
								<b-collapse id="collapse6">
									<div class="px-3 pb-4 pt-2">
										<select
											v-if="typeIsVideoOrImage"
											class="custom-select mb-2"
											v-model="filters.date_field_chosen"
											@change="dateSearchUpdated"
										>
											<template v-for="(dateField) in filters.date_field">
												<option :selected="filters.date_field_chosen" :value="dateField.id">
													{{ dateField.name }}
												</option>
											</template>
										</select>
										<select
											class="custom-select"
											v-model="filters.date_period_chosen"
											@change="dateSearchUpdated"
										>
											<option value="" selected>All</option>
											<template v-for="(period) in filters.date_period">
												<option :value="period.id">{{ period.name }}</option>
											</template>
										</select>
										<template v-if="filters.custom_date">
											<div class="form-row mb-2">
												<div class="col-md-6 col-lg-12 mt-1">
													<label class="mb-0"><small>Start date</small></label>
													<datepicker
														v-model="filters.custom_date_start"
														name="custom_date_start"
														:format="customDateFormatter"
														input-class="form-control"
													>
													</datepicker>
												</div>
												<div class="col-md-6 col-lg-12 mt-1">
													<label class="mb-0"><small>End date</small></label>
													<datepicker
														v-model="filters.custom_date_end"
														name="custom_date_end"
														:format="customDateFormatter"
														input-class="form-control"
													>
													</datepicker>
												</div>
											</div>
											<a href="#" class="btn btn-secondary btn-sm py-0 mt-2"
												 @click.prevent="searchUpdated()">Apply</a>
										</template>
									</div>
								</b-collapse>
							</div>

						</form>
						<div class="Filters--select" v-if="isLoggedIn && typeIsVideoOrImage">
							<div class="form-group mb-0 px-2 text-sm">
								<div>
									<input type="checkbox" class="sr-only" id="selectAllCheckbox" v-model="selectAll">
									<label class="font-weight-normal" for="selectAllCheckbox">
									<span class="btn btn-link p-0 border-0 text-nowrap">
										<IconTick width="22px" fill="#5F007F"></IconTick>
										<span class="ml-2">Select all on page</span>
									</span>
									</label>
								</div>

								<a href="#" @click.prevent="deselectAll" class="d-inline-flex">
									<IconClose width="22px" height="25px" fill="#5F007F"></IconClose>
									<span class="ml-2">Deselect all</span>
								</a>
							</div>
						</div>
					</div>


				</div>

				<!-- Results -->
				<div class="col-lg-9 col-xl-10">
					<div class="ResultsGrid pl-lg-2">
						<template v-for="result in results">
							<div class="ResultsGrid-item" :id="'anchor-' + getResultID(result)">
								<ClipCard
									@viewItemSearched="bosSaveLastItemViewed"
									enablePopup="true"
									:type="getResultType(result)"
									:id="getResultID(result)"
									:title="result.title"
									:rating="result.meta.rating ?? false"
									:duration="result.meta.duration ?? false"
									:definition="result.meta.definition ?? ''"
									:thumbnail="fileUrl(result.thumbpath)"
									:hlsActive="result.meta.hls ?? false"
									:media="getResultMedia(result)"
									:hlsmedia="clipIsVideo(result) ? fileUrl(result.meta.hls_path) : ''"
									:graphic="result.meta.graphic ?? false"
									:packageCount="result.meta.clip_count ?? ''"
									:packageSummary="result.meta.summary ?? ''"
									:dateAdded="result.created"
									:notForSocial="result.meta.notSocial ?? false"
									:enquiriesOnly="result.meta.enquire ?? false"
									:isShort="result.meta.short ?? false"
									:fromSearch="true"
								>
								</ClipCard>

								<div class="ResultsGrid-item-checkbox" v-if="isLoggedIn && typeIsVideoOrImage">
									<div class="custom-control custom-checkbox" v-if="!result.meta.enquire">
										<input
											type="checkbox" class="custom-control-input"
											:id="`checkbox${result.id}-${result.type}`"
											:value="`${result.id}-${result.type}`"
											v-model="selectedClips"
										>
										<label class="custom-control-label" :for="`checkbox${result.id}-${result.type}`"></label>
									</div>
									<div v-else>
										<span class="text-muted text-uppercase small"><small>Enquiries only</small></span>
									</div>
								</div>
							</div>
						</template>

						<div class="ml-1" v-if="results.length == 0 && !isLoading">No results</div>

						<transition name="fade">
							<div class="mx-auto px-2 py-2 w-25" v-show="!isLoading">
								<div v-if="pagination.nextPage">
									<button type="button" class="Button Button--green w-100" @click.prevent="moreResults">
										MORE RESULTS
									</button>
								</div>
							</div>
						</transition>
					</div>

					<div v-if="resultCount > 0" class="d-md-flex align-items-center justify-content-between">
						<button type="button" class="Button Button--orange Button--smallest btn-sm" @click.prevent="backToTop">
							BACK TO TOP
						</button>
						<div class="px-1 align-items-center">
							<h6 class="mb-0"><b>{{ resultCount.toLocaleString() }} {{ countString }}</b></h6>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>

		<transition name="fade">
			<div class="SelectionBar" v-if="selectedClips.length">
				<div class="container px-2 px-lg-3">
					<div class="d-flex align-items-center justify-content-between justify-content-sm-end">
						<span class="mr-2 mr-sm-3">
							<b>{{ selectedClips.length }} {{ 'clip' | pluralize(selectedClips.length) }} selected</b>
						</span>
						<button
							v-if="canPurchase"
							class="Button Button--orange px-3 px-lg-4 text-uppercase mr-3"
							@click="showModalOrAddToCart"
						>
							BUY
						</button>
						<button
							v-if=""
							class="Button Button--green px-3 px-lg-4 text-uppercase"
							@click.prevent="addSelectionsToDownloadQueue"
						>
							DOWNLOAD
						</button>
					</div>
				</div>
			</div>
		</transition>

		<PricingOptionsModal @tierSelected="setTier"></PricingOptionsModal>
	</div>
</template>

<script>
import SearchBar from '@/components/SearchBar';
import ClipCard from '@/components/ClipCard';
import ImageCard from '@/components/ImageCard';
import PricingOptionsModal from '@/components/PricingOptionsModal.vue';
import IconFilter from '@/components/icons/IconFilter';
import IconClose from '@/components/icons/IconClose';
import IconTick from '@/components/icons/IconTick';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'
import Loading from 'vue-loading-overlay';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import image from "@/assets/placeholder.png";
import backOnSearch from '@/mixins/backOnSearch'

const placeholder = new Image();
placeholder.src = image;
import {EventBus} from '@/event-bus.js';

export default {
	data() {
		return {
			selectedClips: [],
			type: 'video',
			keyword: '',
			pageTitle: '',
			cat_name: '',
			results: [],
				page: this.$route.query.page ? this.$route.query.page : 1,
			pagination: '',
			placeholder: placeholder.src,
			isLoading: true,
			es_after: null,
			filters: {
				sort: this.$route.query.sort ? this.$route.query.sort : 'most_recent',
				ratings: [
					{id: 'GD', name: 'Gold'},
					{id: 'SV', name: 'Silver'},
					{id: 'BR', name: 'Bronze'},
				],
				locations: [
					{id: 'gb', name: 'UK'},
					{id: 'us', name: 'USA'},
					{id: 'ca', name: 'Canada'},
					{id: 'au', name: 'Australia'},
					{id: 'de', name: 'Germany'},
					{id: 'fr', name: 'France'},
				],
				location_chosen: this.$route.query.location ? this.$route.query.location.toString().split(",") : [],
				loc2: this.$route.query.loc2,
				rating_chosen: this.$route.query.rating ? this.$route.query.rating.toString().split(",") : [],
				qualities: [
					'SD',
					'720p',
					'1080p',
					'4K',
					'8K',
				],
				quality_chosen: this.$route.query.quality ? this.$route.query.quality.toString().split(",") : [],
				categories: this.$store.state.categories,
				category_chosen: this.$route.query.cat ? this.$route.query.cat.toString().split(",") : [],
				tones: [],
				tones_chosen: this.$route.query.tone ? this.$route.query.tone.toString().split(",") : [],
				ftypes: [],
				ftypes_chosen: this.$route.query.ftype ? this.$route.query.ftype.toString().split(",") : [],
				date_field: [
					{name: 'Date Added', id: 'created'},
					{name: 'Date Shot/Taken', id: 'date_taken'},
					{name: 'Date Posted', id: 'date_posted'},
				],
				date_field_chosen: this.$route.query.datefield ? this.$route.query.datefield : 'created',
				date_period: [
					{name: 'Today', id: 'today'},
					{name: 'Week', id: 'week'},
					{name: 'Month', id: 'month'},
					{name: 'Year', id: 'year'},
					{name: 'Custom', id: 'custom'}
				],
				date_period_chosen: this.$route.query.period ? this.$route.query.period : '',
				custom_date: this.$route.query.period == 'custom' ? true : false,
				custom_date_start: this.$route.query.cdatefrom ? this.$route.query.cdatefrom : '',
				custom_date_end: this.$route.query.cdateto ? this.$route.query.cdateto : '',
				duration: this.getDurationFromQuery(),
				meme: this.$route.query.meme ? this.$route.query.meme : false,
			}
		}
	},
	components: {
		PricingOptionsModal,
		SearchBar,
		ClipCard,
		ImageCard,
		Loading,
		IconFilter,
		IconClose,
		IconTick,
		VueSlider,
		Datepicker
	},
	mixins: [backOnSearch],
	computed: {
		isFiltered() {
			let filtered = false;

			if (this.keyword
				|| this.cat_name
				|| this.filters.rating_chosen.length
				|| this.filters.category_chosen.length
				|| this.filters.tones_chosen.length
				|| this.filters.ftypes_chosen.length
				|| this.isDurationFiltered
				|| this.filters.quality_chosen.length
				|| this.filters.date_period_chosen
				|| this.filters.location_chosen.length
				|| this.filters.loc2 !== undefined
				|| this.filters.meme
			) {
				filtered = true;
			}
			return filtered;
		},
		isDurationFiltered() {
			return this.filters.duration != '';
		},
		newPageTitle() {
			return this.cat_name ? ' - ' + this.cat_name : '';
		},
		countString() {
			return this.resultCount > 1 ? 'results' : 'result';
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		selectedClipsMessage() {
			return this.selectedClips.length > 1 ? this.selectedClips.length + ' items selected' : this.selectedClips.length + ' item selected';
		},
		typeIsVideo() {
			return this.type === 'video' || this.type === 'short';
		},
		typeIsVideoOrImage() {
			return this.typeIsVideo || this.type === 'image';
		},
		typeIsPackage() {
			return this.type === 'packages';
		},
		canPurchase() {
			if (this.$store.state.account) {
				return this.$store.state.account.options.canPurchase;
			}

			return false
		},
		selectAll: {
			get: function () {
				return this.results ? this.selectedClips.length == this.results.length : false;
			},
			set: function (value) {
				let selected = [];

				if (value) {
					this.results.forEach(function (result) {
						let item = result.id + '-' + result.type
						let itemEnquire = result.meta.enquire ?? false

						if (!itemEnquire) {
							selected.push(item);
						}
					});
				}

				this.selectedClips = selected;
			}
		},
		resultCount() {
			return this.pagination.count;
		},
		selectedTier() {
			return this.$store.state.selectedTier
		},
	},
	methods: {
		backToTop() {
			window.scrollTo(0, 0);
		},
		getDurationFromQuery() {
			let queryDur = [
				this.$route.query.duration_start,
				this.$route.query.duration_end,
			];

			// Remove empty values
			queryDur.forEach((duration, idx) => {
				if (duration == undefined || duration == '') {
					queryDur.splice(idx, 1);
				}
			})

			return queryDur.join('-');
		},
		displayDurationFilter() {
			switch (this.filters.duration) {
				case '0-30':
					return '0 - 30 secs';
				case '31-60':
					return '31 secs - 1 min';
				case '61-180':
					return '1:01 - 3 min';
				case '181':
					return '3 min+';
				default:
					return '';
			}
		},
		getDurationQuery() {
			let durValues = this.filters.duration.split('-');

			let durQuery = {
				duration_start: durValues[0]
			};

			if (durValues[1] !== undefined) {
				durQuery.duration_end = durValues[1];
			}

			return durQuery;
		},
		getSortQuery() {
			switch (this.filters.sort) {
				case 'most_rel':
					return {sort: 'rel', dir: 'desc'};
				case 'least_recent':
					return {sort: 'created', dir: 'asc'};
				case 'longest_duration':
					return {sort: 'duration', dir: 'desc'};
				case 'shortest_duration':
					return {sort: 'duration', dir: 'asc'};
				default:
					return {sort: 'created', dir: 'desc'};
			}
		},
		submitSearch() {
			if (this.results.length === 0) {
				this.selectedClips = [];
			}

			this.isLoading = true;
			this.keyword = this.$route.query.term;
			this.type = this.$route.query.type;
			this.cat_name = this.$route.query.category;

			// If sort is unset by the user
			if (Boolean(this.$route.query.sort) == false) {
				if (Boolean(this.keyword) == true) {
					this.filters.sort = 'most_rel';
				} else {
					this.filters.sort = 'most_recent';
				}
			}

			let query = {
				term: this.$route.query.term,
				period: this.$route.query.period,
				cdatefrom: this.$route.query.cdatefrom,
				cdateto: this.$route.query.cdateto,
				page: this.page ?? 1,
				es_after: this.es_after,
			};

			query = this.bosSetFlag(query);

			let sort_query = this.getSortQuery();
			query = {...query, ...sort_query};

			if (this.typeIsVideoOrImage) {
				let clip_query = {
					type: this.type,
					cat: this.$route.query.cat,
					rating: this.$route.query.rating,
					location: this.$route.query.location,
					loc2: this.$route.query.loc2,
					datefield: this.$route.query.datefield,
					meme: this.$route.query.meme,
				};

				query = {...query, ...clip_query};
			}

			if (this.typeIsVideo) {
				let vid_query = {
					quality: this.$route.query.quality,
					mood: this.$route.query.tone,
					ftype: this.$route.query.ftype,
					duration_start: this.$route.query.duration_start,
					duration_end: this.$route.query.duration_end,
				}

				query = {...query, ...vid_query};
			}

			// remove empties or nulls from query object
			Object.keys(query).forEach((key) => (query[key] == null || query[key] == '') && delete query[key]);

			let endpoint = this.typeIsPackage ? 'packages' : 'clips';

			// Save last search in the session
			this.bosSaveLastSearch(query);

			try {
				let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
				let url = this.$apiBaseURL + `/clip/${endpoint}?${queryString}`;
				let headers = {};

				// Check for auth users and add token to headers
				if (this.$store.state.token) {
					headers = {
						Authorization: `Bearer ${this.$store.state.token}`,
					};
				}

				fetch(url, {
					headers: headers,
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.results.push(...result.data);
							this.pagination = result.pagination;
							this.es_after = null;

							setTimeout(() => {
								this.isLoading = false;
								// Scroll when applicable
								this.bosScrollToLastItemViewed();
							}, 500);
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		loaded(instance) {
			this.isLoading = false;
		},
		getCategoryName(id) {
			let cat = this.filters.categories.find(x => x.id == id);
			return cat.name;
		},
		getToneName(id) {
			let tone = this.filters.tones.find(x => x.id == id);
			return tone.displayname;
		},
		getFtypeName(id) {
			let ftype = this.filters.ftypes.find(x => x.id == id);
			return ftype.displayname;
		},
		getRatingName(id) {
			let rating = this.filters.ratings.find(x => x.id == id);
			return rating.name;
		},
		updateURL(sortFilter = false) {
			let type = this.$route.query.type;

			let query = {
				type: this.$route.query.type,
				term: this.$route.query.term,
				period: this.filters.date_period_chosen,
				cdatefrom: this.filters.date_period_chosen == 'custom' ? this.customDateFormatter(this.filters.custom_date_start) : '',
				cdateto: this.filters.date_period_chosen == 'custom' ? this.customDateFormatter(this.filters.custom_date_end) : '',
			};

			if (sortFilter || Boolean(this.$route.query.sort) == true) {
				query.sort = this.filters.sort;
			}

			if (type === 'video' || type === 'image' || type === 'short') {
				let clip_query = {
					category: this.$route.query.category,
					location: this.filters.location_chosen.join(),
					loc2: this.filters.loc2,
					cat: this.cat_name ? this.$route.query.cat : this.filters.category_chosen.join(),
					rating: this.filters.rating_chosen.join(),
					datefield: this.filters.date_field_chosen !== 'created' ? this.filters.date_field_chosen : '',
					meme: this.filters.meme,
				};

				query = {...query, ...clip_query};
			}

			if (type === 'video' || type === 'short' || !type) {
				let vid_query = {
					quality: this.filters.quality_chosen.join(),
					tone: this.filters.tones_chosen.join(),
					ftype: this.filters.ftypes_chosen.join(),
				}

				if (this.isDurationFiltered) {
					let dur_query = this.getDurationQuery();
					vid_query = {...vid_query, ...dur_query};
				}

				query = {...query, ...vid_query};
			}

			// Remove empty or null items:
			Object.keys(query).forEach((key) => (query[key] == null || query[key] == '') && delete query[key]);
			this.$router.replace({query: query}).catch(err => {
				console.log(err)
			});
		},
		searchUpdated(sortFilter = false) {
			this.page = 1;
			this.updateURL(sortFilter);
			this.results = [];
			this.submitSearch();
		},
		dismissKeyword() {
			let query = Object.assign({}, this.$route.query, {term: ''});
			this.$router.replace({query: query});
			this.keyword = '';
			this.$refs.search.clearSearchInput();
			this.results = [];
			this.submitSearch();
		},
		dismissCategory() {
			let query = Object.assign({}, this.$route.query, {category: null, cat: null});
			this.$router.replace({query: query});
			this.filters.category_chosen = [];
			this.results = [];
			this.submitSearch();
		},
		renderSlider() {
			this.$nextTick(() => {
				this.$refs.slider.refresh
			})
		},
		dateSearchUpdated() {
			if (this.filters.date_period_chosen == 'custom') {
				this.filters.custom_date = true;
			} else {
				this.filters.custom_date = false;
				this.searchUpdated();
			}
		},
		prevPage() {
			this.page--;
			this.updateURL();
			this.submitSearch();
		},
		nextPage() {
			this.page++;
			this.updateURL();
			this.submitSearch();
		},
		moreResults() {
			this.page++;
			this.es_after = this.pagination.es_after ?? null;
			this.submitSearch();
		},
		goToPage() {
			this.updateURL();
			this.submitSearch();
		},
		customDateFormatter(date) {
			return moment(date).format('DD-MM-YYYY');
		},
		dismissFilter(filter, item) {
			filter.splice(filter.indexOf(item), 1);
			this.searchUpdated();
		},
		dismissDateFilter() {
			this.filters.date_period_chosen = '';
			this.searchUpdated();
		},
		dismissDurationFilter() {
			this.filters.duration = '';
			this.searchUpdated();
		},
		dismissMemeFilter() {
			this.filters.meme = false;
			this.searchUpdated();
		},
		clipIsVideo(result) {
			return result.type === 'video' || result.type === 'short';
		},
		clipIsVideoOrImage(result) {
			return this.clipIsVideo(result) || result.type === 'image';
		},
		setTier(selection) {
			if (!this.selectedTier) {
				this.$store.commit('setPricingTier', selection)
			}

			if (selection !== undefined) {
				this.$modal.hide('pricing-options-modal')
				this.addSelectionsToCart()
			} else {
				this.showNotification(false, 'There seems to be an issue with your selection - Please refresh the page and try again');
			}
		},
		showModalOrAddToCart() {
			this.$store.commit('getPricingTier');

			if (!this.selectedTier) {
				this.$modal.show('pricing-options-modal');
			} else {
				this.addSelectionsToCart()
			}
		},
		addSelectionsToCart() {
			if (!this.selectedTier) {
				this.showNotification(false, 'There seems to be an issue with your selection - Please refresh the page and try again');

				return false;
			}

			this.selectedClips.forEach((item) => {
				let clipInfo = item.split("-");
				let clipId = clipInfo[0];
				let clipData = this.results.find(resultsItem => resultsItem.id == clipId)

				// Skip it
				if (clipData === undefined) {
					return;
				}

				let mediaType = clipInfo[1] === 'video' ? 'VID' : 'PX'
				let currency = this.selectedTier.currency.toLowerCase()
				let tierData = {
					tierUid: this.selectedTier.tierUid,
					tierCode: this.selectedTier.tierCode,
					tierName: this.selectedTier.tierName,
					tierDescription: this.selectedTier.tierDescription,
					tierUsageType: this.selectedTier.tierUsageType,
					bundleRef: this.selectedTier.bundleRef,
				}

				// Build product
				let product = {
					id: clipId,
					type: clipInfo[1], // video/image
					ourRef: `${mediaType}-${clipId}`,
					media: mediaType,
					productRef: `${tierData.tierCode}-${mediaType}-${clipId}`,
					description: clipData.title,
					qty: 1,
					price: this.selectedTier.price,
					currency: currency,
					tier: tierData,
				}

				this.$store.commit('addToCart', product);
			});

			this.showNotification(true, 'Clips added to cart');
			this.selectedClips = [];
		},
		addSelectionsToDownloadQueue() {
			this.selectedClips.forEach((item) => {
				let clipInfo = item.split("-");

				let clip = {
					id: clipInfo[0],
					type: clipInfo[1],
				}

				this.$store.commit('addToDownloadQueue', clip);
			});

			this.showNotification(true, 'Clips added to your download queue');
			this.selectedClips = [];
		},
		deselectAll() {
			this.selectedClips = [];
		},
		getResultType(result) {
			return result.type;
		},
		getResultID(result) {
			return result.id;
		},
		submitSearchUpdate() {
			this.page = 1;
			this.results = [];
			this.submitSearch();
		},
		getResultMedia(result) {
			if (this.typeIsPackage) {
				return this.fileUrl(result.thumbpath);
			} else {
				return this.clipIsVideo(result) ? this.fileUrl(result.filepath) : this.fileUrl(result.thumbpath);
			}
		}
	},
	created() {
		this.pageTitle = document.title;

		try {
			let url = this.$apiBaseURL + '/clip/tones'

			fetch(url, {
				method: 'GET',
			}).then(response => response.json())
				.then(result => {
					if (result.success === true) {
						this.filters.tones = result.data;
					}
				})
				.catch(error => console.log(error));
		} catch (error) {
			console.log(error)
		}

		try {
			let url = this.$apiBaseURL + '/clip/footageTypes'

			fetch(url, {
				method: 'GET',
			}).then(response => response.json())
				.then(result => {
					if (result.success === true) {
						this.filters.ftypes = result.data;
					}
				})
				.catch(error => console.log(error));
		} catch (error) {
			console.log(error)
		}

		//EventBus.$on('keywordSearchUpdated', this.searchUpdated);
	},
	mounted() {
		this.submitSearch();
	},
	watch: {
		cat_name() {
			document.title = this.pageTitle + this.newPageTitle;
		}
	}
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to

	/* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}

.collapsed > .when-open,
:not(.collapsed) > .when-closed {
	display: none;
}
</style>
