<template>
	<transition name="fade">
		<div>
			<router-link :to="{ name: 'clip', params: { id: id }}" class="Download">
				<div class="Download-image" v-bind:style="{ backgroundImage: 'url('+thumbnail+')' }"></div>
				<div class="Download-text">
					<p class="u-textColour--purple text-uppercase">{{ clipTitle }}</p>

					<span class="Download-text-ref"><b>CMG ID:</b> {{ id }}</span><br>

					<div class="mt-2" v-if="licensingTerm && notForPublicationOnSocialMedia(licensingTerm)">
						<not-for-social-small></not-for-social-small>
					</div>
					<div class="Download-text-ref mt-2" v-if="date">
						<time :datetime="date" :title="date">{{ date | moment("from") }}</time>
					</div>
				</div>

				<div
					v-if="this.trashable"
					@click.prevent="deleteItem()"
					class="Download-trash border-left p-1 d-flex align-items-center"
				>
					<IconTrash width="25px"></IconTrash>
				</div>
			</router-link>
		</div>
	</transition>
</template>

<script>
import IconTrash from '@/components/icons/IconTrash';
import NotForSocialSmall from '@/components/messages/NotForSocialSmall';
import Hls from "hls.js";

export default {
	props: {
		id: null,
		clipType: null,
		canPurchase: false,
		trashable: false,
		date: null,
	},
	components: {
		IconTrash,
		NotForSocialSmall
	},
	data() {
		return {
			clipTitle: null,
			thumbnail: null,
			licensingTerm: null,
		}
	},
	computed: {},
	methods: {
		deleteItem() {
			this.$emit('itemDeleted');
		},
		async fetchClip() {
			try {
				let url = this.$apiBaseURL + `/clip/clips/${this.id}`;
				let headers = {};

				// Check for auth users and add token to headers
				if (this.$store.state.token) {
					headers = {
						Authorization: `Bearer ${this.$store.state.token}`,
					};
				}

				await fetch(url, {
					headers: headers,
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							const data = result.data;

							this.clipType = data.clip_type;
							this.clipTitle = data.title;
							this.licensingTerm = data.licensing_terms;
							this.thumbnail = this.fileUrl(data.media.thumbpath);
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
	},
	mounted() {
		// we do this just to make sure we have the correct info
		this.fetchClip();
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
