<template>
	<div>
		<SearchBanner :heroVideo="heroVideo">
			<template slot="title">Caters Media Group</template>
			<template slot="tagline">Exclusive content from around the world</template>
		</SearchBanner>
		<Categories></Categories>
		<div class="sliders-wrap bg-light pb-lg-5 pt-lg-2">

			<TopPicks></TopPicks>

			<div class="bg-light pb-5 pt-lg-3" v-observe-visibility="{ callback: getLatest, once: true }">
				<div class="container">
					<h2 class="h1 text-center">Latest videos</h2>
				</div>
				<transition name="fade">
					<Slider :clips="latest" v-if="latest.length"></Slider>
				</transition>
				<div class="text-center" v-if="latestLoading">
					<IconLoading width="50px" height="50px" color="#333"></IconLoading>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SearchBanner from "@/components/SearchBanner";
import Categories from "@/components/Categories";
import TopPicks from "@/components/TopPicks";
import Slider from '@/components/Slider';
import CategorySlider from '@/components/CategorySlider';
import IconLoading from '@/components/icons/IconLoading';

export default {
	data() {
		return {
			heroVideo: '',
			latest: [],
			latestLoading: false,
			categories: this.$store.state.categories
		}
	},
	components: {
		SearchBanner,
		Categories,
		TopPicks,
		Slider,
		CategorySlider,
		IconLoading
	},
	methods: {
		getLatest(isVisible, entry) {
			if (isVisible) {
				this.latestLoading = true;

				try {
					let url = this.$apiBaseURL + '/clip/clips/latest';
					let headers = {};

					// Check for auth users and add token to headers
					if (this.$store.state.token) {
						headers = {
							Authorization: `Bearer ${this.$store.state.token}`,
						};
					}

					fetch(url, {
						headers: headers,
						method: 'GET',
					}).then(response => response.json())
						.then(result => {
							if (result.success === true) {
								this.latest = result.data
								this.latestLoading = false;
							}
						})
						.catch(error => console.log(error));
				} catch (error) {
					console.log(error)
				}
			}
		},
		fetchHeroVideo() {
			try {
				let url = this.$apiBaseURL + '/clip/clips/hero'

				fetch(url, {
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							const data = result.data
							this.heroVideo = this.fileUrl(data.media.filepath);
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		}
	},
	beforeCreate() {
	},
	beforeMount() {
		this.fetchHeroVideo()
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}

.sliders-wrap {
	overflow-x: hidden;
}
</style>
