<!--suppress ALL -->
<template>
	<div>
		<SearchBar></SearchBar>
		<div class="py-4 py-md-5">
			<div class="container page">
				<div class="row">
					<div class="col-md-10 col-lg-8 col-xxl-6 offset-md-1 offset-lg-2 offset-xxl-3">
						<h2 class="text-uppercase">Download Queue ({{ numberInQueue }})</h2>

						<template v-if="!isDownloading && !isDownloadReady">
							<div
								v-for="item in myDownloads"
								:key="item.id"
							>
								<DownloadCard
									:id="item.id"
									:clipType="item.type"
									:trashable="true"
									:canPurchase="canPurchase"
									@itemDeleted="deleteFromDownloadQueue(item)">
								</DownloadCard>
							</div>

							<div
								v-if="myDownloads.length"
								class="d-sm-flex text-right justify-content-between mt-4 align-items-center"
							>
								<button
									class="Button Button--orange order-1 mb-2"
									@click.prevent="processDownloads"
								>
									Download all
									<IconDownload width="20px" class="ml-2"></IconDownload>
								</button>

								<!-- CLEAR QUEUE MODAL-->
								<button
									@click.prevent="showConfirmClearQueueModal"
									class="Button Button--grey-outline Button--smallest order-0 mb-2"
								>
									Clear Downloads
								</button>
							</div>

							<div v-else>
								There are no downloads currently in your queue
							</div>
						</template>

						<template v-else-if="isDownloading && !isDownloadReady">
							<div class="vld-parent">
								<loading
									:active.sync="isDownloading"
									:is-full-page="loadingFullPage"
									:opacity="loadingOpacity"
								>
								</loading>
							</div>
							<div class="text-center mt-4">
								We are now preparing your files. Please stay on this page.
							</div>
						</template>

						<template v-else-if="isDownloadReady">
							<a
								:href="downloadLink"
								class="bg-white d-flex w-100 py-4 px-3 justify-content-center text-center"
							>
								<b>Your files are ready. Click here to download.</b>
							</a>
						</template>
					</div>
				</div>
			</div>
		</div>

		<modal name="confirm-delete-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
			<div class="p-4">
				<h3 class="pr-5">Remove all downloads</h3>
				<button
					type="button"
					name="button"
					class="btn btn-danger"
					@click.prevent="clearDownloadQueue()"
				>
					Confirm
				</button>
				<button
					type="button"
					name="button"
					class="btn btn-link"
					@click.prevent="$modal.hide('confirm-delete-modal')"
				>
					Cancel
				</button>
			</div>
		</modal>
	</div>

</template>

<script>
import SearchBar from '@/components/SearchBar';
import DownloadCard from '@/components/DownloadCard';
import IconDownload from '@/components/icons/IconDownload';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	props: {},
	data() {
		return {
			isDownloading: false,
			isDownloadReady: false,
			loadingFullPage: false,
			loadingOpacity: 0,
			downloadLink: '',
			mediaType: false,
			prepaidCredits: false
		}
	},
	components: {
		SearchBar,
		DownloadCard,
		IconDownload,
		Loading
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn
		},
		token() {
			return this.$store.state.token
		},
		accountId() {
			return this.$store.state.account.accountId
		},
		canPurchase() {
			return this.$store.state.account.options.canPurchase;
		},
		queueHasClips() {
			return this.$store.state.downloadQueue.length > 0;
		},
		numberInQueue() {
			return this.$store.state.downloadQueue.length;
		},
		myDownloads() {
			return this.$store.state.downloadQueue;
		},
	},
	methods: {
		showConfirmClearQueueModal() {
			this.$modal.show('confirm-delete-modal');
		},
		async processDownloads() {
			if (this.myDownloads.length > 0) {
				let ids = [];

				for (let o in this.myDownloads) {
					ids.push(this.myDownloads[o]);
				}

				this.isDownloading = true;

				try {
					let payload = { ids: ids };
					let url = this.$apiBaseURL + `/clip/clips/download`

					const response = await fetch(url, {
						headers: {
							'Authorization': `Bearer ${this.token}`,
							'Content-Type': 'application/json',
						},
						method: 'POST',
						body: JSON.stringify(payload),
					})

					const result = await response.json()

					if (result.success === true) {
						this.isDownloadReady = true;
						this.downloadLink = result.data.file_url;
						this.$store.commit('emptyDownloadQueue');
					} else {
						this.isDownloading = false;
						this.showNotification(result.success, result.message);
					}
				} catch (error) {
					this.isDownloading = false;
					console.log(error);
				}
			}
		},
		deleteFromDownloadQueue(item) {
			this.$store.commit('removeFromDownloadQueue', item);
			this.showNotification(true, 'The item was removed from your downloads');
		},
		clearDownloadQueue() {
			this.$store.commit('emptyDownloadQueue');
			this.$modal.hide('confirm-delete-modal')
		},
		fetchPrepaidCreditStatus() {
			try {
				let url = this.$apiBaseURL + `/clip/prepaidCredits/account/${this.accountId}/status`

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.prepaidCredits = result.data[0].available;
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
	},
	mounted() {
		this.fetchPrepaidCreditStatus()
	},
	created() {
		this.$store.commit('getQueuedDownloads');
	},
	updated() {
	}
}
</script>

<style scoped>
.vld-parent {
	width: 64px;
	height: 64px;
	margin: 0 auto;
}

.vld-overlay:focus {
	outline: 0;
}
</style>
