<template>
	<div class="Card Card--image">
		<div>
			<router-link :to="{ name: 'clip', params: { id: id }}" :key="id" class="Card--image-img"
									 :style="{ backgroundImage: `url('${thumbnail}')` }">
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	props: ['enablePopup', 'id', 'title', 'thumbnail', 'alttext', 'image', 'graphic']
}
</script>
