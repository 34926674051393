<template>
	<div class="py-4 py-md-5">
		<div class="container">
			<div class="row">
				<div class="col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
					<div class="shadow-lg p-4 bg-white">
						<h1 class="h2 mb-3">Reset your password</h1>
						<div v-if="verified && !success">
							<p>Enter a new password here.</p>
							<form @submit.prevent="onSubmit">
								<div class="form-group">
									<label for="password">New password</label>
									<input type="password" name="password" v-model="password" class="form-control" required>
								</div>
								<div class="form-group">
									<label for="confirmPassword">Confirm password</label>
									<input type="password" name="confirmPassword" v-model="confirmPassword" class="form-control" required>
								</div>
								<div class="alert alert-danger" role="alert" v-if="resetError">
									{{ resetError }}
								</div>
								<div class="form-group">
									<button type="submit" name="button" class="Button Button--purple">Submit</button>
								</div>
							</form>
						</div>
						<div class="alert alert-danger" role="alert" v-if="!verified && verificationError">
							{{ verificationError }}
						</div>
						<div v-if="success" class="mb-2">
							<div class="alert alert-success my-4">
								{{ successMessage }}
							</div>
							<router-link :to="{ name: 'login' }" class="Button Button--purple">Log in here</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			token: this.$route.query.token,
			userId: '',
			verified: false,
			success: false,
			successMessage: '',
			verificationError: '',
			resetError: '',
			password: '',
			confirmPassword: ''
		}
	},
	methods: {
		async onSubmit() {
			let requestBody = {
				user_id: this.userId,
				password: this.password,
				pwd_confirm: this.confirmPassword,
				token: this.token,
			}

			try {
				let url = this.$apiBaseURL + '/clip/users/reset-password'

				const response = await fetch(url, {
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(requestBody)
				})

				const result = await response.json()
				console.log(result)

				if (result.success === true) {
					this.success = true;
					this.successMessage = result.message;
				} else {
					this.success = false;
					this.errors = result.message;
				}
			} catch (error) {
				console.error(error);
			}
		}
	},
	beforeCreate() {
		if (this.$store.state.isLoggedIn) {
			this.$router.push({name: 'home'});
		}
	},
	async created() {
		if (!this.token || this.token === '') {
			await this.$router.push({name: 'home'});
		} else {
			try {
				let url = this.$apiBaseURL + `/clip/users/reset-password?token=${this.token}`

				const response = await fetch(url, {
					method: 'GET',
				})

				const result = await response.json()

				if (result.success === true) {
					this.verified = true;
					this.userId = result.data.user_id;
				} else {
					this.verified = false;
					this.verificationError = result.message;
				}
			} catch (error) {
				console.error(error);
			}
		}
	}
}
</script>
