<template>
	<div>
		<SearchBar></SearchBar>
		<template v-if="!isError && !isLoading">
			<div class="py-5">
				<div class="container page">
					<div class="row">
						<div class="col-md-10 col-lg-8 col-xxl-6 offset-md-1 offset-lg-2 offset-xxl-3">
							<h2 class="text-uppercase" v-if="name">List: {{ name }}</h2>
							<div v-if="!isLoading && clips.length">
								<template v-for="(item, index) in clips">
									<DownloadCard
										:id="item.id"
										:thumbnail_prop="fileUrl(item.thumbpath)"
										:title_prop="item.title"
										:key="index"
										:trashable="canTrash()"
										@itemDeleted="removeClipFromList(item)"
									>
									</DownloadCard>
								</template>
							</div>
							<div v-else>
								List is empty
							</div>
							<div class="py-4" v-if="!isLoading && (!isCompany || (isCompany && roles.includes(this.userRole)))">
								<button type="button" name="button" class="Button Button--purple" @click="showConfirmDeleteModal">
									Delete list
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<modal name="confirm-delete-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
				<div class="p-4">
					<h3 class="pr-5">Deleting list: {{ name }}</h3>
					<p><b>Are you sure?</b></p>
					<button type="button" name="button" class="btn btn-danger" @click.prevent="deleteList()">Yes, delete</button>
					<button type="button" name="button" class="btn btn-link" @click.prevent="$modal.hide('confirm-delete-modal')">
						Cancel
					</button>
				</div>
			</modal>
		</template>

		<template v-else-if="isError && !isLoading">
			<div class="container py-5 page">
				<div class="row">
					<div class="col-lg-8 offset-lg-2">
						<div class="alert alert-danger text-center">
							{{ errorMessage }}
						</div>
					</div>
				</div>
			</div>
		</template>

		<div class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>
	</div>
</template>

<script>
import DownloadCard from '@/components/DownloadCard'
import Loading from 'vue-loading-overlay'
import SearchBar from '@/components/SearchBar'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	data() {
		return {
			id: this.$route.params.id,
			name: null,
			created: null,
			clips: [],
			isLoading: true,
			isError: false,
			errorMessage: '',
			isCompany: false,
			roles: ['Superuser', 'Administrator'],
		}
	},
	components: {
		Loading,
		DownloadCard,
		SearchBar
	},
	computed:{
		token(){
			return this.$store.state.token
		},
		userRole() {
			return this.$store.state.account.userRole;
		},
	},
	methods: {
		canTrash() {
			return !this.isCompany || (this.isCompany && this.roles.includes(this.userRole));
		},
		fetchList() {
			try {
				let url = this.$apiBaseURL + `/clip/savelists/${this.id}`

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.name = result.data.name;
							this.created = result.data.created;
							this.clips = result.data.clips;
							this.isCompany = result.data.user_id === 0;
						} else {
							this.isError = true;
							this.errorMessage = result.message;
						}

						this.isLoading = false;
					})
					.catch(error => {
						console.log(error);
						this.isLoading = false;
					});
			} catch (error) {
				console.log(error)
			}
		},
		removeClipFromList(item) {
			let url = this.$apiBaseURL + '/clip/savelists/removeClip';
			try {
				let payload = {
					list_id: this.id,
					clip_id: item.id,
				};

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(payload),
				}).then(response => response.json())
					.then(result => {
						this.fetchList();
						this.showNotification(result.success, result.message);
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		deleteList() {
			let url = this.$apiBaseURL + `/clip/savelists/${this.id}`;
			try {
				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.$router.push({name: 'dashboard'});
						}

						this.showNotification(result.success, result.message);
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		showConfirmDeleteModal() {
			this.$modal.show('confirm-delete-modal');
		}
	},
	created() {
		this.fetchList();
	},
}
</script>

<style>
</style>
