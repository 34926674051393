<template>
	<transition-group name="fade">
		<div class="Dashboard-sidebar-lists" v-show="this.lists" key="myLists">
			<div class="Dashboard-sidebar-lists-heading">My lists</div>
			<template v-for="list in this.lists">
				<div class="Dashboard-sidebar-lists-item" :key="list.id">
					<router-link :to="{ name: 'list', params: { id: list.id } }" class="Dashboard-sidebar-lists-item-link">{{ list.name }}</router-link>
					<a v-on:click.prevent="copyUrl(list.url_ref)" href="#" title="Copy list URL" class="Dashboard-sidebar-lists-item-url" ref="listUrl">
						<IconCopy class="icon--copy"></IconCopy>
					</a>
				</div>
			</template>
		</div>

		<div class="Dashboard-sidebar-lists" v-show="this.companyLists.length > 0" key="companyLists">
			<div class="Dashboard-sidebar-lists-heading">Company lists</div>
			<template v-for="list in this.companyLists">
				<div class="Dashboard-sidebar-lists-item" :key="list.id">
					<router-link :to="{ name: 'list', params: { id: list.id } }" class="Dashboard-sidebar-lists-item-link">{{ list.name }}</router-link>
					<a v-on:click.prevent="copyUrl(list.url_ref)" href="#" title="Copy list URL" class="Dashboard-sidebar-lists-item-url" ref="listUrl">
						<IconCopy class="icon--copy"></IconCopy>
					</a>
				</div>
			</template>
		</div>
	</transition-group>
</template>

<script>
import IconCopy from "@/components/icons/IconCopy";

export default {
	components: {
		IconCopy
	},
	props: [
		'lists',
		'companyLists'
	],
	methods: {
		async copyUrl(ref) {
			if (ref) {
				let url = 'https://clips.catersnews.com/#/list/share/'+ ref;
				await navigator.clipboard.writeText(url);
				this.showNotification(true,'URL Successfully copied to your clipboard')
			}
		},
		showNotification: function (success, message, duration) {
			if (message) {
				let typename = success ? 'success' : 'error';
				this.$notify({
					type: typename,
					text: message,
					duration: duration ? duration : 6000,
				});
			}
		}
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.icon--copy {
	width: 20px;
	height: 20px;
}
</style>

<style scoped>
.Dashboard-sidebar-lists {
	max-height: 400px;
	overflow-y: scroll;
}

.Dashboard-sidebar-lists .Dashboard-sidebar-lists-heading {
	position: sticky;
	top: 0;
}
</style>
