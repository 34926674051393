<!--suppress ALL -->
<template @testEmit="recieveEmit()">
	<div class="Detail page">
		<SearchBar></SearchBar>

		<template v-if="isDownloading">
			<div class="text-center mt-4">
				Preparing file - Please stay on this page.
			</div>
		</template>
		<transition name="fade">
			<div class="container py-4 my-lg-3" v-show="!isLoading && !isError">

				<div class="row">
					<div class="Detail-media col-xl-8 d-flex">
						<vue-plyr ref="player" class="w-100" v-show="isVideo">
							<video ref="video" :poster="imagePath" class="w-100"></video>
						</vue-plyr>
						<div class="Detail-image" v-if="isImage">
							<img :src="imagePath" class="w-100">
						</div>
					</div>
					<div class="col-xl-4 d-flex">
						<div class="ContentAttributes flex-grow-1 shadow-lg">
							<div class="ContentAttributes-content">
								<div v-if="isLoggedIn && roles.includes(userRole)">
									<a
										:href="`https://clipsadmin.catersnews.com/admin/clips/view/${this.id}`"
										target="_blank"
										class="Button Button--orange text-uppercase w-100"
									>
										VIEW IN CLIPS ADMIN
									</a>
								</div>
								<div v-if="rating" class="ContentAttributes-content-rating">
									<span :class="'ContentAttributes-content-rating-' + rating"></span>
								</div>

								<div class="ContentAttributes-content-title">
									<h1>{{ clipTitle }}</h1>
								</div>
								<div class="ContentAttributes-content-details">
									<p class="font-weight-bold mb-3">Details:</p>
									<p v-if="clip.location">Location: <b>{{ clip.location }}</b></p>
									<p>Category:
										<template v-for="(cat, i) in category">
											<router-link
												:to="{
												name: 'search',
												query: { category: cat.displayname, cat: cat.id, type: isImage ? 'image' : 'video' }
											}"
												class="font-weight-bold">{{ cat.displayname }}
											</router-link>
											<span v-if="i < (category.length - 1)">{{ ', ' }}</span>
										</template>
									</p>
									<p v-if="isVideo">Length: <b>{{ rounded(meta_data.duration) | moment("mm:ss") }}</b>
									</p>
									<p v-if="clip.file_type && isVideo">Format: <b>{{ clip.file_type }}</b></p>
									<p v-if="meta_data.definition && isVideo">Quality: <b>{{ meta_data.definition }}</b>
									</p>
									<p v-if="clip.created">Date added:
										<b>{{ clip.created | moment("Do MMMM YYYY") }}</b>
									</p>
									<p v-if="clip.date_taken">Date taken:
										<b>{{ clip.date_taken | moment("Do MMMM YYYY") }}</b>
									</p>
									<p v-if="clip.date_posted">Date posted:
										<b>{{ clip.date_posted | moment("Do MMMM YYYY") }}</b>
									</p>
									<p class="mt-4">Clip Ref:<span class="font-weight-bold">&nbsp;{{ id }}</span></p>

									<div class="mt-4 alert alert-danger" v-if="restrictions">
										<b>Restrictions apply</b>
										<div class="ContentAttributes-restrictions">
											{{ restrictions }}
										</div>
									</div>

									<div class="mt-4 alert alert-danger" v-if="enquiriesOnly">
										<div class="ContentAttributes-restrictions">
											If you are interested in purchasing this clip, please use the ‘Make An
											Enquiry’ button below or you can contact our team directly at <a
											href="mailto:licensing@catersnews.com">licensing@catersnews.com</a>
										</div>
									</div>
								</div>
							</div>

							<div class="ContentAttributes-buttons">
								<div class="bg-white">
									<template v-if="type === 'video' && isMaster">
										<div
											v-if="!isLoggedIn || (isLoggedIn && canPurchase && clip.downloadable_by_user)"
										>
											<button
												class="Button Button--orange text-uppercase w-100"
												@click="showModalOrAddToCart"
												:disabled="isClipInCart"
											>
											<span class="Button-icon">
												<IconCart width="20px"></IconCart>
											</span>
												<template v-if="!isClipInCart">
													BUY
												</template>
												<template v-else>
													<IconTick width="20px"></IconTick>
												</template>
											</button>
										</div>
									</template>

									<div v-if="isLoggedIn && clip.downloadable_by_user">
										<button
											v-if="canAccess"
											class="Button Button--green text-uppercase"
											@click="addToDownloadQueue"
											:disabled="isClipInQueue"
										>
										<span class="Button-icon">
											<IconDownload width="20px"></IconDownload>
										</span>
											<template v-if="!isClipInQueue">
												Download Clip
											</template>
											<template v-else>
												<IconTick width="20px"></IconTick>
											</template>
										</button>

										<!-- THIS BUTTON BELOW WILL BE FOR WHEN WE NEED TO SPECIFY OPTIONS FOR A DOWNLOAD -->
										<!--										<button-->
										<!--											v-if="canPurchase"-->
										<!--											class="Button Button&#45;&#45;green text-uppercase"-->
										<!--											@click="showDownloadOptionsModal"-->
										<!--											:disabled="isClipInQueue"-->
										<!--										>-->
										<!--										<span class="Button-icon">-->
										<!--											<IconDownload width="20px"></IconDownload>-->
										<!--										</span>-->
										<!--											<template v-if="!isClipInQueue">-->
										<!--												Download Clip (as credit)-->
										<!--											</template>-->
										<!--											<template v-else>-->
										<!--												<IconTick width="20px"></IconTick>-->
										<!--											</template>-->
										<!--										</button>-->
									</div>


									<div v-if="!enquiriesOnly">
										<button
											v-if="isLoggedIn && clip.downloadable_by_user"
											v-b-toggle.collapse
											class="Button Button--grey text-uppercase w-100"
										>
										<span class="Button-icon">
											<IconPlus></IconPlus>
										</span>
											Save to list
										</button>
										<b-collapse id="collapse" @shown="fetchLists">
											<div class="Dashboard-sidebar-lists">
												<template v-for="(list, index) in lists">
													<div class="p-2 px-3 border-bottom">
														<div class="custom-control custom-checkbox">
															<input
																type="checkbox"
																class="custom-control-input"
																:id="`listCheck_${index}`"
																@change="addOrRemoveFromList(list.id, $event)"
																:checked="isClipInList(list.clips)"
															>
															<label
																class="custom-control-label"
																:for="`listCheck_${index}`"
															>
																{{ list.name }}
															</label>
														</div>
													</div>
												</template>
											</div>

											<button
												v-if="isLoggedIn"
												type="button"
												class="Button Button--grey--light pl-5 text-left"
												v-b-toggle.collapse1
											>
											<span class="Button-icon">
												<IconPlus width="20px" fill="#444"></IconPlus>
											</span>
												Save to a new list
											</button>

											<b-collapse id="collapse1">
												<form @submit.prevent="createList">
													<div class="d-flex justify-content-between">
													<span class="d-flex flex-grow-1 flex-column">
														<input
															type="text"
															class="form-control flex-grow-1 border-0 form-control-sm pl-3"
															v-model="newListName"
															placeholder="Enter list name"
														>
													</span>
														<span class="input-group-btn">
														<button class="Button Button--green Button--smaller" type="submit">Save</button>
													</span>
													</div>
												</form>
											</b-collapse>
										</b-collapse>
									</div>

									<button class="Button Button--purple text-uppercase" @click="showEnquiryModal">
										<span class="Button-icon">
											<IconEnquiry width="20px"></IconEnquiry>
										</span>
										Make an enquiry
									</button>

								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-8 pt-4 mt-2">
						<div
							class="alert alert-danger mb-3 font-weight-bold text-center"
							v-if="notForPublicationOnSocialMedia(licensingTerm)"
						>
							{{ notForSocialMessage }}
						</div>
						<div v-if="isLoggedIn" class="mb-2 text-center">
							<button
								class="Button Button--grey--light text-uppercase w-100"
								v-if="textFile"
								@click="prepareTextFile">
										<span class="Button-icon">
<!--											<IconDownload width="20px"></IconDownload>-->
										</span>
								DOWNLOAD FULL TEXT HERE
							</button>
						</div>
						<p v-if="isLoggedIn">{{ clipDescription }}</p>


					</div>
					<div class="col-xl-4 pt-lg-4 mt-lg-2" v-if="isLoggedIn && packages.length">
						<div class="PackageList mt-3" style="background-color: #efefef;">
							<p>This clip is part of the following {{ 'package' | pluralize(packages.length) }}:</p>
							<template v-for="pkg in packages">
								<router-link
									:to="{ name: 'package', params: { id: pkg.id } }"
									class="PackageList-item"
								>
									<span class="PackageList-item-icon">
										<IconAlbum width="22px" height="22px"></IconAlbum>
									</span>
									<span class="PackageList-item-name text-uppercase">{{ pkg.name }}</span>
								</router-link>
							</template>
						</div>
					</div>
					<div v-if="isLoggedIn && tags.length" class="col-xl-8 pt-2 mb-3">
						<div class="mb-1">
							<b>Tags</b>
						</div>
						<span v-for="(keyword, index) in tags">
							<router-link
								:to="{ name: 'search', query: { term: keyword, type: isImage ? 'image' : 'video' } }"
							>
								{{ keyword }}
							</router-link>
							<template v-if="index < tags.length - 1"> | </template>
						</span>
					</div>
				</div>

				<div v-if="!isLoggedIn" class="cta-login-register">
					<p><b>Please login or register below to view full details for this story</b></p>
					<div class="cta-lr-btn-container d-flex">
						<div class="cta-lr-buttons">
							<router-link
								:to="{ name: 'login' }"
								class="Button Button--purple py-1"
							>
							<span class="d-sm-none">
								<IconPerson width="20px" height="20px" fill="#fff"></IconPerson>
							</span>
								<span class="d-none d-sm-inline">Login</span>
							</router-link>&nbsp;
							<router-link
								:to="{ name: 'register' }"
								class="Button Button--purple-outline py-1"
							>
								Register
							</router-link>
						</div>
					</div>
				</div>

				<div class="py-4 mt-lg-3" v-if="related.length">
					<h3 class="text-center">More like this</h3>
					<div class="ResultsGrid">
						<template v-for="clip in related">
							<div class="ResultsGrid-item">
								<ClipCard
									enablePopup=true :type="type" :id="clip.id" :title="clip.title"
									:duration="isVideo ? clip.meta.duration : ''"
									:definition="isVideo ? clip.meta.definition : ''"
									:thumbnail="fileUrl(clip.thumbpath)"
									:hlsActive="isVideo ? clip.meta.hls : false"
									:media="fileUrl(clip.filepath)"
									:hlsmedia="(isVideo && clip.meta.hls) ? fileUrl(clip.meta.hls_path) : ''"
									:graphic="isVideo ? clip.meta.graphic : ''"
									:enquiriesOnly="clip.meta.enquire"
									:dateAdded="clip.created"
									:notForSocial="clip.meta.notSocial"
								>
								</ClipCard>
							</div>
						</template>
					</div>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<div class="container py-5 mt-1" v-if="!isLoading && isError">
				<div class="row">
					<div class="col-lg-8 offset-lg-2">
						<div class="alert alert-danger text-center">
							{{ errorMessage }}
						</div>
					</div>
				</div>
			</div>
		</transition>

		<PricingOptionsModal @tierSelected="setTier"></PricingOptionsModal>

		<modal name="download-options-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
			<div class="p-4">
				<h3>Available Download Options</h3>
				<div class="modal-text-small">
					<p>Please select your option below. For more information & terms please <br>visit our
						<router-link
							:to="{name: 'pricing'}"
							target="_blank"
						>
							<b>Pricing</b>
						</router-link>
						page.
					</p>

					<DownloadOptions @tierSelected="setTier"></DownloadOptions>

					<div class="modal-close">
						<a href="#" @click.prevent="$modal.hide('download-options-modal')">
							<IconClose width="50px" height="50px" fill="#5F007F"></IconClose>
						</a>
					</div>
				</div>
			</div>
		</modal>

		<modal name="restrictions-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
			<div class="p-4">
				<h3 class="pr-5">Restrictions apply</h3>
				<p class="mb-3">Please note that there are restrictions applied to the usage of this clip, as shown in
					the right hand panel so please be aware of this if you download this content.</p>
				<button type="button" @click.prevent="$modal.hide('restrictions-modal')"
								class="Button Button--purple Button--smallest">OK
				</button>
			</div>
		</modal>

		<modal name="enquiriesOnly-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
			<div class="p-4">
				<h3 class="pr-5">Enquiries Only</h3>
				<p class="mb-3">Please note that this clip cannot be downloaded, you will need to contact us
					directly.</p>
				<button type="button" @click.prevent="$modal.hide('enquiriesOnly-modal')"
								class="Button Button--purple Button--smallest">OK
				</button>
			</div>
		</modal>

		<Enquiry :id="id" :contentType="type"></Enquiry>

		<div class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>
	</div>
</template>

<script>
import SearchBar from '@/components/SearchBar';
import Enquiry from '@/components/Enquiry';
import IconEnquiry from '@/components/icons/IconEnquiry';
import IconPlus from '@/components/icons/IconPlus';
import IconCart from '@/components/icons/IconCart';
import IconTick from '@/components/icons/IconTick';
import IconAlbum from '@/components/icons/IconAlbum';
import IconDownload from '@/components/icons/IconDownload';
import IconPerson from '@/components/icons/IconPerson';
import ClipCard from '@/components/ClipCard.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Hls from 'hls.js'
import IconClose from "@/components/icons/IconClose.vue";
import LicensingTerms from "@/components/LicensingTerms";
import DownloadOptions from "@/components/DownloadOptions";
import PricingOptionsModal from "@/components/PricingOptionsModal.vue";

export default {
	components: {
		PricingOptionsModal,
		DownloadOptions,
		LicensingTerms,
		IconClose,
		SearchBar,
		Enquiry,
		IconPlus,
		IconCart,
		IconTick,
		IconEnquiry,
		IconDownload,
		IconPerson,
		Loading,
		ClipCard,
		IconAlbum
	},
	data() {
		return {
			id: this.$route.params.id,
			userId: this.$store.state.userId,
			shortsOnly: false,
			clip: [],
			type: '',
			isMaster: false,
			category: [],
			licensingTerm: [],
			tags: [],
			meta_data: [],
			media: [],
			isLoading: true,
			isError: false,
			errorMessage: '',
			lists: [],
			newListName: '',
			related: [],
			packages: [],
			hlsActive: false,
			hlsPath: '',
			restrictions: '',
			enquiriesOnly: false,
			rating: false,
			textFile: false,
			isDownloading: false,
			isDownloadReady: false,
			loadingOpacity: 0,
			loadingFullPage: false,
			downloadLink: '',
			pricingOptionSelected: false,
			roles: ['Superuser', 'Administrator', 'Manager', 'Staff']
		}
	},
	computed: {
		token() {
			return this.$store.state.token
		},
		myCart() {
			return this.$store.state.cart
		},
		isCartEmpty() {
			return this.$store.state.cart.length === 0
		},
		myQueue() {
			return this.$store.state.downloadQueue
		},
		isClipInQueue() {
			let x = this.myQueue.find(clip => clip.id === this.id)
			return x === undefined ? false : true
		},
		isClipInCart() {
			let x = this.myCart.find(o => o.id == this.id);
			return x === undefined ? false : true
		},
		canPurchase() {
			if (this.$store.state.account) {
				return this.$store.state.account.options.canPurchase;
			}
			return false
		},
		canAccess() {// ER - I know this is long but was the only way I could get it to work
			let canDo = false

			if (this.enquiriesOnly === false) {
				canDo = true

				if (this.shortsOnly === true && this.isShort === false) {
					canDo = false
				}

				if (this.shortsOnly === false && this.isShort === true) {
					canDo = false
				}
			}

			return canDo
		},
		moment() {
			return moment;
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		userRole() {
			return this.$store.state.account.userRole;
		},
		clipTitle() {
			return this.clip.title ? this.clip.title : '';
		},
		clipDescription() {
			return this.clip.description;
		},
		video() {
			return this.$refs.video;
		},
		isVideo() {
			return this.type === 'video';
		},
		isImage() {
			return this.type === 'image';
		},
		isShort() {
			return this.meta_data.short
		},
		imagePath() {
			return this.media.thumbpath ? this.fileUrl(this.media.thumbpath) : '';
		},
		pricingOptions() {
			return this.$store.state.pricingOptions[this.currency]
		},
		currency() {
			return this.$store.state.defaultCurrency.currency
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol
		},
		cartType() {
			if (this.myCart.length) {
				return this.myCart[0]['type']
			}
		},
		selectedTier() {
			return this.$store.state.selectedTier
		},
	},
	methods: {
		setTier(selection) {
			this.pricingOptionSelected = selection

			if (!this.selectedTier) {
				this.$store.commit('setPricingTier', selection)
			}

			if (this.pricingOptionSelected !== undefined) {
				this.$modal.hide('pricing-options-modal')
				this.addProductToCart(this.clip)
			} else {
				this.showNotification(false, 'There seems to be an issue with your selection - please try again');
			}
		},
		showModalOrAddToCart() {
			this.$store.commit('getPricingTier');

			if (!this.selectedTier) {
				this.$modal.show('pricing-options-modal');
			} else {
				this.addProductToCart()
			}
		},
		addProductToCart() {
			if (!this.selectedTier) {
				this.showNotification(false, 'There seems to be an issue - please refresh & try again');
			} else { // we can build our product
				if (this.isClipInCart) { // should never be able to do this but check just in case
					this.showNotification(false, 'You already have this item in your cart!');
					return false;
				}

				let mediaType = this.clip.clip_type === 'video' ? 'VID' : 'PX'
				let price = this.selectedTier.price
				let currency = this.selectedTier.currency.toLowerCase()
				let quantity = 1 // default to one but not sure why you would buy several of the same clip
				let tierData = {
					tierUid: this.selectedTier.tierUid,
					tierCode: this.selectedTier.tierCode,
					tierName: this.selectedTier.tierName,
					tierDescription: this.selectedTier.tierDescription,
					tierUsageType: this.selectedTier.tierUsageType,
					bundleRef: this.selectedTier.bundleRef
				}

				// build product
				let product = {
					id: this.clip.id,
					type: this.clip.clip_type, // video/image/bundle
					ourRef: `${mediaType}-${this.clip.id}`,
					media: mediaType,
					productRef: `${tierData.tierCode}-${mediaType}-${this.clip.id}`,
					description: this.clip.title,
					qty: quantity,
					price: quantity * price,
					currency: currency,
					tier: tierData
				}

				this.$store.commit('addToCart', product);
				this.showNotification(true, 'The clip was added to your cart');
			}
		},
		showDownloadOptionsModal() {
			this.$modal.show('download-options-modal')
		},
		showEnquiryModal() {
			this.$modal.show('enquiry-modal');
		},
		getRating(code) {
			// Hiding rating UI, request from Lianne
			return false;
			let rating = false;

			if (code) {
				switch (code) {
					case 'GD':
						rating = 'gold';
						break;
					case 'SV':
						rating = 'silver';
						break;
					case 'BR':
						rating = 'bronze';
						break;
					case 'RF':
						rating = 'rf';
						break;
				}
			}

			return rating;
		},
		async fetchClip() {
			try {
				let url = this.$apiBaseURL + `/clip/clips/${this.$route.params.id}`;
				let headers = {};

				// Check for auth users and add token to headers
				if (this.$store.state.token) {
					headers = {
						Authorization: `Bearer ${this.$store.state.token}`,
					};
				}

				fetch(url, {
					headers: headers,
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							const data = result.data

							this.clip = data;
							this.type = data.clip_type;
							this.category = data.categories;
							this.licensingTerm = data.licensing_terms;
							this.tags = data.keywords.split(",");
							this.meta_data = (this.isImage) ? data.image_metadata : data.video_metadata;
							this.media = data.media;
							this.related = data.related_items ? data.related_items : [];
							this.packages = data.packages;
							this.setTitle();
							this.hlsActive = (this.isVideo) ? data.video_metadata.hls : false;
							this.hlsPath = (this.hlsActive) ? this.fileUrl(data.media.hls_path) : '';
							this.rating = this.getRating(data.clip_rating);
							this.textFile = data.textFile;
							this.isMaster = (this.isVideo) ? data.video_metadata.master : false

							if (this.isVideo) {
								if (Hls.isSupported() && this.hlsActive) {
									const hls = new Hls();
									hls.loadSource(this.hlsPath);
									hls.attachMedia(this.video);
									window.hls = hls;
								} else {
									this.video.src = this.fileUrl(this.media.filepath);
								}
							}

							if (data.restrictions && this.isLoggedIn) {
								this.restrictions = data.restrictions;
								this.$modal.show('restrictions-modal');
							}

							if (data.enquire && this.isLoggedIn) {
								this.enquiriesOnly = true;
								this.$modal.show('enquiriesOnly-modal');
							}

							if (this.$route.query.view === 'enquire' && this.isLoggedIn) {
								this.$modal.show('enquiry-modal');
								this.$modal.hide('enquiriesOnly-modal');
								this.$router.replace({query: null});
							}
						} else {
							this.isError = true;
							this.errorMessage += result.message;
						}
						this.isLoading = false;
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		rounded(seconds) {
			return Math.floor(seconds);
		},
		addToDownloadQueue() {
			let item = {
				id: this.$data.id,
				type: this.$data.clip.clip_type,
			}

			this.$store.commit('addToDownloadQueue', item);
			this.showNotification(true, 'The ' + item.type + ' was added to your download queue');
		},
		downloadTextFile() {
			this.isLoading = false
			let arr = this.downloadLink.split("/");
			let filename = arr[arr.length - 1]

			if (this.downloadLink) {
				this.isDownloading = false;

				axios({
					url: this.downloadLink,
					method: 'GET',
					responseType: 'arraybuffer',
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res.data]));
					let link = document.createElement('a');

					link.href = url;
					link.setAttribute('download', filename);
					document.body.appendChild(link);
					link.click();
				});
			}
		},
		prepareTextFile() {
			let clipId = this.textFile;
			this.isDownloading = true;
			this.isLoading = true;

			try {
				let url = this.$apiBaseURL + `/clip/clips/text/${this.textFile}`

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json'
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.downloadLink = result.data.file_url;
							this.downloadTextFile()
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}

			// axios({
			// 	method: 'post',
			// 	url: '/api/clips/downloadText',
			// 	data: {
			// 		id: clipId
			// 	}
			// }).then(response => {
			// 	if (response.data.data.success) {
			// 		this.downloadLink = response.data.data.file_url;
			// 		this.downloadTextFile()
			// 	}
			//
			// }).catch(error => {
			// 	this.isDownloadReady = false;
			// 	console.log(error);
			// });
		},
		fetchLists() {
			try {
				let url = this.$apiBaseURL + `/clip/savelists/user/${this.userId}`

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							let userLists = result.data.lists;
							let companyLists = [];

							if (['Superuser', 'Administrator'].includes(this.userRole)) {
								companyLists = result.data.company;
							}

							this.lists = [...userLists, ...companyLists];
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		createList() {
			if (this.newListName !== '') {
				try {
					let url = this.$apiBaseURL + '/clip/savelists/create'
					let payload = {
						user_id: this.userId,
						name: this.newListName,
						clip_id: this.id,
					};

					fetch(url, {
						headers: {
							'Authorization': `Bearer ${this.token}`,
							'Content-Type': 'application/json',
						},
						method: 'POST',
						body: JSON.stringify(payload),
					}).then(response => response.json())
						.then(result => {
							if (result.success === true) {
								this.fetchLists();
								this.newListName = '';
							}

							this.showNotification(result.success, result.message);
						})
						.catch(error => console.log(error));
				} catch (error) {
					console.log(error)
				}
			}
		},
		addOrRemoveFromList(listId, event) {
			let url = this.$apiBaseURL + '/clip/savelists/';

			if (event.target.checked) {
				url += 'addClip';
			} else {
				url += 'removeClip';
			}

			try {
				let payload = {
					list_id: listId,
					clip_id: this.id,
				};

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(payload),
				}).then(response => response.json())
					.then(result => {
						this.fetchLists();
						this.showNotification(result.success, result.message);
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		isClipInList(clips) {
			let result = clips.map(a => a.id);
			return result.includes(parseInt(this.id));
		},
		setTitle() {
			document.title += ' - ' + this.clipTitle;
		},

		getSingleClipPrice(mediaType) {
			// @TODO IMPORTANT - Make sure we have tier code so we can hook onto the right one
			let pricing = this.pricingOptions[this.currency][mediaType][0]['options']['bundle_1']

			if (pricing) {
				return pricing
			}
			return false
		}
	},
	created() {
		this.fetchClip();
		// console.log(this.$store.state.account)
		if (this.isLoggedIn) {
			this.shortsOnly = this.$store.state.account.shorts
		}
	}
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to

	/* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>

<style scoped>
.Dashboard-sidebar-lists {
	background: #fff;
	max-height: 200px;
	overflow-y: scroll;
}
</style>
