<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path :fill="colourRef"
					d="M400 421.3V154.7c0-23.5-19.2-42.7-42.7-42.7H90.7C67.2 112 48 131.2 48 154.7v266.7c0 23.5 19.2 42.7 42.7 42.7h266.7c23.4-.1 42.6-19.3 42.6-42.8zM157.3 304l45.3 64 66.7-96 88 128H90.7l66.6-96z"/>
		<path :fill="colourRef"
					d="M421.3 48H154.7C131.2 48 112 67.2 112 90.7V96h261.3c23.5 0 42.7 19.2 42.7 42.7V400h5.3c23.5 0 42.7-19.2 42.7-42.7V90.7c0-23.5-19.2-42.7-42.7-42.7z"/>
	</svg>
</template>

<script>
export default {
	props: ['fill'],
	data() {
		return {
			colourRef: this.fill ? this.fill : '#5F007F'
		}
	}
}
</script>
