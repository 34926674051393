<template>
	<div class="py-4 py-md-5">
		<div class="container">
			<div class="row">
				<div class="col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
					<div class="shadow-lg p-4 bg-white">
						<h1 class="h2 mb-3">Forgot your password?</h1>
						<p>Enter the email you used to register and we'll send you a link to reset your password.</p>
						<form @submit.prevent="onSubmit" v-if="!success">
							<div class="form-group">
								<label for="passwordEmail" class="sr-only">Email</label>
								<input
									type="text"
									name="passwordEmail"
									v-model="email"
									class="form-control"
									placeholder="Email"
									required
								>
							</div>
							<div class="alert alert-danger" role="alert" v-if="errors">
								{{ errors }}
							</div>
							<div class="form-group">
								<button type="submit" name="button" class="Button Button--purple">Submit</button>
							</div>
						</form>
						<div v-else class="alert alert-success mb-3">
							{{ successMessage }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			email: '',
			success: false,
			successMessage: '',
			errors: ''
		}
	},
	beforeCreate() {
		if (this.$store.state.isLoggedIn) {
			this.$router.push({name: 'home'});
		}
	},
	methods: {
		async onSubmit() {
			let requestBody = {email: this.email}
			try {
				let url = this.$apiBaseURL + '/clip/users/forgot-password'

				const response = await fetch(url, {
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(requestBody)
				})

				const result = await response.json()

				if (result.success === true) {
					this.success = true;
					this.successMessage = result.message;
				} else {
					this.success = false;
					this.errors = result.message;
				}
			} catch (error) {
				console.error(error);
			}
		}
	}
}
</script>
