export default {
	beforeCreate: function () {
		let lastSearch = sessionStorage.getItem('lastSearch');

		if (lastSearch) {
			let currentPath = this.$router.history.current.fullPath;
			lastSearch = JSON.parse(lastSearch);

			if (lastSearch.path === currentPath) {
				this.$route.query.page = lastSearch.page;
				this.$route.query.back_on_search = '1';
			}
		}
	},
	methods: {
		// Save the last search made by the client
		bosSaveLastSearch(queryParams) {
			if (queryParams.page !== undefined && parseInt(queryParams.page) !== 1) {
				let lastSearch = {
					path: this.$router.history.current.fullPath,
					page: queryParams.page,
				}

				sessionStorage.setItem('lastSearch', JSON.stringify(lastSearch));
			}
		},
		// Set the flag which allows us to fetch all previous pages
		bosSetFlag(query) {
			if (this.$route.query.back_on_search !== undefined) {
				query.back_on_search = 1;
				delete this.$route.query.back_on_search;
			}

			return query;
		},
		bosSaveLastItemViewed(itemId) {
			sessionStorage.setItem('lastItemViewed', itemId);
		},
		bosScrollToLastItemViewed() {
			let itemId = sessionStorage.getItem('lastItemViewed');
			sessionStorage.removeItem('lastItemViewed');

			if (itemId) {
				let card = document.getElementById(`anchor-${itemId}`);

				if (card) {
					card.scrollIntoView({behavior: 'smooth', block: 'center'});
				}
			}
		}
	}
}
