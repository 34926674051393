<template>
	<div>
		<SearchBar></SearchBar>
		<div class="container py-4 py-md-5" v-if="!notFound">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<h1>{{ heading }}</h1>
					<div v-html="decodedContent"></div>
				</div>
			</div>
		</div>
		<div class="container my-5 text-center" v-else>
			<h1>Not found</h1>
		</div>
	</div>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";

export default {
	components: {SearchBar},
	data() {
		return {
			heading: '',
			content: '',
			notFound: false
		}
	},
	computed: {
		decodedContent: function () {
			let txt = document.createElement("textarea");
			txt.innerHTML = this.content;
			return txt.value;
		}
	},
	methods: {
		getContent() {
			axios({
				method: 'get',
				url: '/api/pages/display/' + this.$route.params.slug
			})
				.then(response => {
					var data = response.data.data;
					if (typeof data.Page !== 'undefined') {
						this.heading = data.Page.heading;
						this.content = data.Page.content;
						document.title += ' - ' + this.heading;
					} else {
						this.notFound = true;
					}
				})
				.catch(error => {
					console.log(error);
					this.notFound = true;
				});
		}
	},
	created() {
		this.getContent();
	}
}
</script>
