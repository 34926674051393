<template>
	<div
		ref="card"
		class="Card slider-panel"
		v-bind:class="{ 'Card-popup--left': popupLeft, 'Card--image': isImage || isPackage }"
	>
		<div @mouseover="hover($event)" @mouseleave="hoverOut">
			<router-link
				v-if="fromSearch"
				:to="{ name: routeName, params: { id: id }}"
				:key="id"
				:custom="true"
				v-slot="{href}"
			>
				<a :href="href" @click="$emit('viewItemSearched', id)">
					<img :src="thumbnail" :alt="alttext">
				</a>
			</router-link>

			<router-link
				v-else
				:to="{ name: routeName, params: { id: id }}"
				:key="id"
			>
				<img :src="thumbnail" :alt="alttext">
			</router-link>
		</div>

		<transition name="scale">
			<div
				:id="id"
				class="Card-popup"
				v-if="enablePopup && showPopup && !isMobile"
				v-on:mouseover="hoverInfo"
				v-on:mouseout="hoverOutInfo"
			>
				<div v-if="getRating(rating)" class="ContentAttributes-content-rating">
					<span :class="'ContentAttributes-content-rating-' + getRating(rating)"></span>
				</div>
				<VideoPlayer
					v-if="isVideo"
					:thumbnail="thumbnail"
					:media="media"
					:hlsmedia="hlsmedia"
					:shouldClipAutoplay="shouldClipAutoplay"
					:hlsActive="hlsActive"
				>
				</VideoPlayer>
				<img
					v-if="!isVideo"
					:src="media"
					alt=""
					class="w-100"
				>
				<div class="py-3 px-2">
					<router-link
						v-if="fromSearch"
						:to="{ name: routeName, params: { id: id }}"
						:key="id"
						:custom="true"
						v-slot="{href}"
					>
						<a :href="href" @click="$emit('viewItemSearched', id)">
							<h6 class="mb-2">{{ title }}</h6>
						</a>
					</router-link>

					<router-link
						v-else
						:to="{ name: routeName, params: { id: id }}"
						:key="id"
					>
						<h6 class="mb-2">{{ title }}</h6>
					</router-link>

					<div class="Card-popup-details">
						<p class="mb-2" v-if="isPackage">{{ packageSummary }}</p>
						<div class="d-flex justify-content-between">
							<div v-if="isVideo || isPackage">
								<template v-if="isVideo">{{ definition }}
									<template v-if="definition">|</template>
									{{ rounded(duration) | moment("mm:ss") }}
								</template>
								<template v-if="isPackage">
									{{ packageCount }} {{ 'clip' | pluralize(packageCount) }} in package
								</template>
							</div>
							<div v-if="dateAdded">
								Added: {{ dateAdded | moment("DD/MM/YYYY") }}
							</div>
						</div>
					</div>

					<div class="mt-2" v-if="notForSocial">
						<not-for-social-small></not-for-social-small>
					</div>
				</div>

				<div class="d-flex" v-if="isVideo || isImage">
					<button
						class="Button Button--hasIcon Button--green text-uppercase w-100"
						v-b-toggle.collapseLists
						v-if="isLoggedIn && !enquiriesOnly"
					>
						<span class="Button-icon"><IconPlus></IconPlus></span>
						Save to a list
					</button>
					<button
						class="Button Button--hasIcon Button--orange text-uppercase w-100"
						v-if="isLoggedIn"
						@click.prevent="addToQueue"
					>
						<span class="Button-icon">
							<IconDownload width="20px"></IconDownload>
						</span>
						<template v-if="!isClipInQueue">Download</template>
						<template v-else>
							<IconTick width="20px"></IconTick>
						</template>
					</button>
					<router-link
						class="Button Button--purple Button--hasIcon w-100"
						v-if="isLoggedIn && enquiriesOnly"
						:to="{ name: routeName, params: { id: id }, query: { view: 'enquire' }}"
						:key="id"
					>
						<span class="Button-icon">
							<IconEnquiry width="20px"></IconEnquiry>
						</span> Make an enquiry
					</router-link>
				</div>

				<div class="d-flex" v-else-if="isPackage">
					<router-link
						:to="{ name: routeName, params: { id: id }}"
						:key="id"
						class="Button Button--orange text-uppercase w-100"
					>View package
					</router-link>

					<button
						class="Button Button--hasIcon Button--green text-uppercase w-100"
						v-if="isLoggedIn"
						@click.prevent="addPackageToDownloadQueue"
					>
						<span class="Button-icon">
							<IconDownload width="20px"></IconDownload>
						</span>
						<template>Download</template>
					</button>
				</div>

				<b-collapse id="collapseLists" @shown="fetchLists" v-if="isLoggedIn && (isVideo || isImage)">
					<div class="Dashboard-sidebar-lists">
						<template v-for="(list, index) in lists">
							<div class="p-2 px-3 border-bottom">
								<div class="custom-control custom-checkbox">
									<input
										type="checkbox"
										class="custom-control-input"
										:id="`listCheck_${index}`"
										@change="addOrRemoveFromList(list.id, $event)"
										:checked="isClipInList(list.clips)"
									>
									<label class="custom-control-label" :for="`listCheck_${index}`">
										{{ list.name }}
									</label>
								</div>
							</div>
						</template>
					</div>
					<button
						type="button"
						class="Button Button--grey--light pl-5 text-left w-100"
						v-b-toggle.collapseCreateList
						v-if="isLoggedIn">
						<span class="Button-icon">
							<IconPlus width="20px" fill="#444"></IconPlus>
						</span>
						Save to a new list
					</button>
					<b-collapse id="collapseCreateList">
						<form @submit.prevent="createList">
							<div class="d-flex justify-content-between">
								<span class="d-flex flex-grow-1 flex-column">
									<input
										type="text"
										class="form-control flex-grow-1 border-0 form-control-sm pl-3"
										v-model="newListName"
										placeholder="Enter list name"
									>
								</span>
								<span class="input-group-btn">
									<button class="Button Button--green Button--smaller" type="submit">Save</button>
								</span>
							</div>
						</form>
					</b-collapse>
				</b-collapse>
			</div>
		</transition>
	</div>
</template>

<script>
import IconCart from '@/components/icons/IconCart.vue';
import IconTick from '@/components/icons/IconTick.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconEnquiry from '@/components/icons/IconEnquiry.vue';
import VideoPlayer from '@/components/video-player.vue';
import NotForSocialSmall from '@/components/messages/NotForSocialSmall.vue';
import IconDownload from "@/components/icons/IconDownload.vue";

export default {
	components: {
		IconDownload,
		IconCart,
		IconTick,
		IconPlus,
		IconEnquiry,
		NotForSocialSmall,
		VideoPlayer
	},
	props: [ // TODO THIS NEEDS TO BE SORTED AS A CLIP OBJECT
		'type',
		'enablePopup',
		'id',
		'title',
		'rating',
		'duration',
		'definition',
		'thumbnail',
		'alttext',
		'hlsActive',
		'media',
		'hlsmedia',
		'graphic',
		'packageCount',
		'packageSummary',
		'dateAdded',
		'notForSocial',
		'enquiriesOnly',
		'isShort',
		'fromSearch',
	],
	data() {
		return {
			isMobile: false,
			showPopup: false,
			popupLeft: true,
			timer: '',
			isInInfo: false,
			lists: [],
			newListName: '',
			shortsOnly: false,
		}
	},
	computed: {
		token() {
			return this.$store.state.token
		},
		userId() {
			return this.$store.state.userId
		},
		myCart() {
			return this.$store.state.cart
		},
		myQueue() {
			return this.$store.state.downloadQueue
		},
		isClipInCart() {
			let x = this.myCart.find(o => o.id == this.id);
			return x !== undefined
		},
		isClipInQueue() {
			let x = this.myQueue.find(clip => clip.id === this.id)
			return x !== undefined
		},
		moment() {
			return moment
		},
		canAccess() { // @TODO ER - Revisit this
			let canDo = false

			if (this.enquiriesOnly === false) {
				canDo = true

				if (this.shortsOnly === true && this.isShort === false) {
					canDo = false
				}

				if (this.shortsOnly === false && this.isShort === true) {
					canDo = false
				}
			}

			return canDo
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		autoplay() {
			return this.$store.state.settings.autoplay;
		},
		shouldClipAutoplay() {
			return (this.autoplay && !this.graphic);
		},
		isVideo() {
			return this.type === 'video' || this.type === 'short';
		},
		isImage() {
			return this.type === 'image';
		},
		isPackage() {
			return this.type === 'package';
		},
		routeName() {
			return this.isPackage ? 'package' : 'clip';
		}
	},

	methods: {
		getRating(code) {
			// Hiding rating UI, request from Lianne
			return false;
			let rating = false;

			if (code) {
				switch (code) {
					case 'GD':
						rating = 'gold';
						break;
					case 'SV':
						rating = 'silver';
						break;
					case 'BR':
						rating = 'bronze';
						break;
					case 'RF':
						rating = 'rf';
						break;
				}
			}

			return rating;
		},
		addToQueue() {
			let item = {
				id: this.id,
				type: this.type
			}

			this.$store.commit('addToDownloadQueue', item);
			this.showNotification(true, 'Added to download queue');
		},
		fetchLists() {
			try {
				let url = this.$apiBaseURL + `/clip/savelists/user/${this.userId}`

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							let userRole = this.$store.state.account.userRole;
							let userLists = result.data.lists;
							let companyLists = [];

							if (['Superuser', 'Administrator'].includes(userRole)) {
								companyLists = result.data.company;
							}

							this.lists = [...userLists, ...companyLists];
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		createList() {
			if (this.newListName !== '') {
				try {
					let url = this.$apiBaseURL + '/clip/savelists/create'
					let payload = {
						user_id: this.userId,
						name: this.newListName,
						clip_id: this.id,
					};

					fetch(url, {
						headers: {
							'Authorization': `Bearer ${this.token}`,
							'Content-Type': 'application/json',
						},
						method: 'POST',
						body: JSON.stringify(payload),
					}).then(response => response.json())
						.then(result => {
							if (result.success === true) {
								this.fetchLists();
								this.newListName = '';
							}

							this.showNotification(result.success, result.message);
						})
						.catch(error => console.log(error));
				} catch (error) {
					console.log(error)
				}
			}
		},
		addOrRemoveFromList(listId, event) {
			let url = this.$apiBaseURL + '/clip/savelists/';

			if (event.target.checked) {
				url += 'addClip';
			} else {
				url += 'removeClip';
			}

			try {
				let payload = {
					list_id: listId,
					clip_id: this.id,
				};

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(payload),
				}).then(response => response.json())
					.then(result => {
						this.fetchLists();
						this.showNotification(result.success, result.message);
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		isClipInList(clips) {
			let result = clips.map(a => a.id);
			return result.includes(parseInt(this.id));
		},
		rounded(seconds) {
			return Math.floor(seconds);
		},
		getOffset(el) {
			const rect = el.getBoundingClientRect();
			return {
				left: rect.left + window.scrollX,
				top: rect.top + window.scrollY
			};
		},
		isElementCloseToRight(element) {
			let window_width = window.innerWidth;
			let el_width = this.$refs.card.clientWidth;
			let offset = this.getOffset(element).left;
			let space = (window_width - offset) - el_width;

			return space < 420;
		},
		hover(e) {
			let element = e.target;
			this.popupLeft = this.isElementCloseToRight(element);
			this.timer = setTimeout(() => {
				this.$emit('popupOpened');
				this.showPopover();
			}, 100);
		},
		hoverOut() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				if (!this.isInInfo) {
					this.$emit('popupClosed');
					this.closePopover();
				}
			}, 100);
		},
		hoverInfo() {
			this.isInInfo = true;
		},
		hoverOutInfo() {
			this.isInInfo = false;
			this.hoverOut();
		},
		showPopover() {
			this.showPopup = true;
		},
		closePopover() {
			this.showPopup = false;
		},
		async addPackageToDownloadQueue() {
			let clips

			try {
				let url = this.$apiBaseURL + `/clip/packages/${this.id}`

				const response = await fetch(url, {
					method: 'GET',
				})

				const result = await response.json()

				if (result.success === true) {
					clips = result.data.clips
				}
			} catch (error) {
				console.error(error);
			}

			if (typeof clips !== "undefined" && clips.length) {
				clips.forEach((clip) => {
					let item = {
						id: clip.id,
						type: clip.type
					}

					this.$store.commit('addToDownloadQueue', item);
				});
				this.showNotification(true, 'The package was added to your downloads');
			} else {
				this.showNotification(false, 'The clips in this package cannot be downloaded, you will need to contact us directly.');
			}
		},
	},
	updated() {
		if (window.innerWidth < 1025) {
			this.isMobile = true;
		}
	},
}
</script>

<style scoped>
.scale-enter-active, .scale-leave-active {
	transition: all 0.25s;
	transform: translateY(-50%) scale(1);
	transform-origin: left;
}

.Card.Card-popup--left .scale-enter-active,
.Card.Card-popup--left .scale-leave-active {
	transform-origin: right;
}

.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: translateY(-50%) scale(0.9);
}

.Dashboard-sidebar-lists {
	background: #fff;
	max-height: 150px;
	overflow-y: scroll;
}
</style>
