<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path :fill="colourRef" d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z"/>
	</svg>
</template>

<script>
export default {
	props: ['fill'],
	data() {
		return {
			colourRef: this.fill ? this.fill : '#FFFFFF'
		}
	}
}
</script>
