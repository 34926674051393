<template>
	<div class="Dashboard">
		<div class="bg-grey--alt py-3 justify-content-start">
			<div class="container">
				<div class="d-md-flex justify-content-between align-items-center">
					<h1 class="h3 text-dark m-0 py-2 px-2 px-md-1">My Account</h1>
					<div class="d-flex py-2 flex-wrap">
						<div class="mx-2 d-flex align-items-center">
							<IconPerson class="mr-1"></IconPerson>
							{{ userFullName }}
						</div>
						<div class="mx-2 d-flex align-items-center">
							<IconPeople class="mr-1"></IconPeople>
							{{ accountName }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container py-4 py-lg-5">
			<div class="row">
				<div class="col-md-4 col-xl-3">
					<div class="Dashboard-sidebar shadow-lg d-flex flex-column">
						<Credits
							v-if="!isLoading"
							:standardCredits="standardCredits"
							:prepaidCredits="prepaidCredits"
							:canPurchase="canPurchase"
							:hasPurchases="purchases.length > 0"
							:isLoading="isLoading"
						>
						</Credits>

						<router-link
							v-if="isLoggedIn && canPurchase"
							:to="{ name: 'myPurchases' }"
							class="mb-1 Button Button--purple-outline text-left"
						>
							PURCHASE HISTORY
						</router-link>

						<router-link
							v-if="isLoggedIn"
							:to="{ name: 'myDownloads' }"
							class="mb-1 Button Button--purple-outline text-left"
						>
							DOWNLOAD HISTORY
						</router-link>

						<form @submit.prevent="searchList">
							<div class="d-flex justify-content-between">
									<span class="d-flex flex-grow-1 flex-column">
										<input
											type="text"
											class="form-control flex-grow-1 border-0 form-control-sm pl-3"
											v-model="listKeyword" placeholder="Search a list"
										>
									</span>

								<button type="submit" class="Button Button--green Button--smallest">
									<IconSearch></IconSearch>
								</button>
							</div>
						</form>

						<Lists :lists="lists" :company-lists="companyLists"></Lists>

						<button
							type="button"
							class="Button Button--grey--light pl-5 text-left"
							v-b-toggle.collapse
							v-if="isLoggedIn"
						>
							<span class="Button-icon">
								<IconPlus width="20px" fill="#444"></IconPlus>
							</span>
							Create new list
						</button>

						<b-collapse id="collapse">
							<form @submit.prevent="createList">
								<div class="d-flex justify-content-between">
									<span class="d-flex flex-grow-1 flex-column">
										<input
											type="text"
											class="form-control flex-grow-1 border-0 form-control-sm pl-3"
											v-model="newListName" placeholder="Enter list name"
										>
									</span>
									<span class="input-group-btn">
										<button class="Button Button--orange Button--smaller" type="submit">Create</button>
									</span>
								</div>
							</form>
						</b-collapse>
					</div>
				</div>

				<div class="col-md-8 col-xl-9">
					<div class="mt-5 mb-5 mt-md-0 pl-md-3 pl-lg-5">
						<h2>Most Recent downloads</h2>
						<div v-if="downloads.length || isLoading">
							<template>
								<DownloadsCard
									v-for="(item) in downloads" :key="item.id"
									:id="item.clip_id"
									:date="item.created"
									:thumbnail_prop="fileUrl(item.clip.media.thumbpath)"
									:title_prop="item.clip.title"
								>
								</DownloadsCard>
							</template>
						</div>
						<div v-else>
							No recent downloads
						</div>
					</div>

					<div v-if="canPurchase" class="mt-5 mt-md-0 pl-md-3 pl-lg-5">
						<h2>Most Recent purchases</h2>
						<div v-if="purchases.length || isLoading">
							<template>
								<PurchasesCard
									v-for="(purchase) in purchases" :key="purchase.id"
									:id="purchase.id"
									:purchaseDate="purchase.purchase_date"
									:purchaseRef="purchase.purchase_ref"
									:opid="purchase.opid"
									:product="purchase.unique_ref"
									:amount="parseFloat(purchase.credit_value)"
									:currency="purchase.currency"
									:purchasedClips="purchase.clips !== undefined ? JSON.parse(purchase.clips): []"
								>
								</PurchasesCard>
							</template>
						</div>
						<div v-else>
							<p>You have not made any purchases yet...</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>
	</div>
</template>

<script>
import Credits from '@/components/Credits';
import Lists from '@/components/Lists';
import DownloadsCard from "@/components/DownloadsCard.vue";
import PurchasesCard from '@/components/PurchasesCard';
import IconPlus from '@/components/icons/IconPlus';
import IconPerson from '@/components/icons/IconPerson';
import IconPeople from '@/components/icons/IconPeople';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import IconSearch from "@/components/icons/IconSearch.vue";


export default {
	data() {
		return {
			standardCredits: false,
			prepaidCredits: false,
			downloads: [],
			purchases: [],
			lists: [],
			companyLists: [],
			newListName: '',
			listKeyword: '',
			isLoading: true,
		}
	},
	components: {
		IconSearch,
		DownloadsCard,
		Loading,
		Credits,
		Lists,
		PurchasesCard,
		IconPlus,
		IconPerson,
		IconPeople,
	},
	computed: {
		token() {
			return this.$store.state.token
		},
		userId() {
			return this.$store.state.account.userId
		},
		userFullName() {
			return this.$store.state.account.userFullName
		},
		accountName() {
			return this.$store.state.account.accountName
		},
		accountId() {
			return this.$store.state.account.accountId
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn
		},
		canPurchase() {
			return this.$store.state.account.options.canPurchase
		}
	},
	methods: {
		async fetchStandardCreditStatus() {
			try {
				let url = this.$apiBaseURL + `/clip/credits/account/${this.accountId}`

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.standardCredits = result.data;
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		async fetchPrepaidCreditStatus() {
			try {
				let url = this.$apiBaseURL + `/clip/prepaidCredits/account/${this.accountId}/status`

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.prepaidCredits = result.data[0];
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		async fetchPurchaseHistory() {
			try {
				let url = this.$apiBaseURL + `/clip/prepaidCredits/account/${this.accountId}/recent`

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.purchases = result.data;
							this.isLoading = false;
						}
					})
					.catch(error => {
						console.log(error);
						this.isLoading = false;
					});
			} catch (error) {
				console.log(error)
			}
		},
		async fetchDownloads() {
			try {
				let url = this.$apiBaseURL + `/clip/downloads/user/${this.userId}/recent`

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.downloads = result.data;
							this.isLoading = false;
						}
					})
					.catch(error => {
						console.log(error);
						this.isLoading = false;
					});
			} catch (error) {
				console.log(error)
			}
		},
		async fetchLists(keyword = null) {
			try {
				let url = this.$apiBaseURL + `/clip/savelists/user/${this.userId}`

				if (keyword) {
					url += `?keyword=${keyword}`;
				}

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.lists = result.data.lists;
							this.companyLists = result.data.company;
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		createList() {
			if (this.newListName !== '') {
				try {
					let url = this.$apiBaseURL + '/clip/savelists/create'
					let payload = {
						user_id: this.userId,
						name: this.newListName,
					};

					fetch(url, {
						headers: {
							'Authorization': `Bearer ${this.token}`,
							'Content-Type': 'application/json',
						},
						method: 'POST',
						body: JSON.stringify(payload),
					}).then(response => response.json())
						.then(result => {
							if (result.success === true) {
								this.fetchLists();
								this.newListName = '';
							}

							this.showNotification(result.success, result.message);
						})
						.catch(error => console.log(error));
				} catch (error) {
					console.log(error)
				}
			}
		},
		searchList() {
			this.fetchLists(this.listKeyword)
		},
	},
	beforeCreate() {
	},
	created() {

	},
	beforeMount() {
		this.fetchLists();
		this.fetchDownloads();
		this.fetchPurchaseHistory();
		this.fetchPrepaidCreditStatus();
		this.fetchStandardCreditStatus();
	},
	mounted() {
	},

}
</script>
