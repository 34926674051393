import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/home'
import Login from '@/views/auth/login'
import Register from '@/views/auth/register'
import Dashboard from '@/views/user/dashboard'
import PurchaseHistory from "@/views/user/purchasehistory.vue";
import DownloadHistory from "@/views/user/downloadhistory.vue";
import DownloadLink from '@/views/user/downloadlink.vue';
import Cart from '@/views/ecommerce/cart.vue'
import Downloads from '@/views/user/download.vue'
import Checkout from '@/views/ecommerce/checkout.vue'
import Contact from '@/views/pages/contact'
import Search from '@/views/clips/search'
import Clip from '@/views/clips/detail'
import Package from '@/views/clips/package'
import List from '@/views/user/list'
import ShareList from '@/components/ShareList'
import Page from '@/views/pages/default'
import Terms from '@/views/pages/terms'
import ForgotPassword from '@/views/auth/password/forgot'
import ResetPassword from '@/views/auth/password/reset'
import ConfirmPayment from '@/views/ecommerce/confirmpayment'
import Pricing from '@/views/ecommerce/pricing'
import PurchaseOptions from '@/views/clips/purchaseoptions'
import Faq from '@/views/pages/faq'
import About from '@/views/pages/about'
import Privacy from '@/views/pages/privacy'
import Error from '@/views/404'
import store from '@/store/'



Vue.use(Router)

const router = createRouter()

export default router

function createRouter() {
	const router = new Router({
		routes: [
			{
				path: '/',
				name: 'home',
				component: Home,
				meta: {title: 'Home'}
			},
			{
				path: '/login',
				name: 'login',
				component: Login,
				meta: {title: 'Login'}
			},
			{
				path: '/register',
				name: 'register',
				component: Register,
				meta: {title: 'Register'}
			},
			{
				path: '/contact-us',
				name: 'contact',
				component: Contact,
				meta: {title: 'Contact'}
			},
			{
				path: '/search',
				name: 'search',
				component: Search,
				meta: {title: 'Search Results'}
			},
			{
				path: '/clip/:id',
				name: 'clip',
				component: Clip,
				props: true
			},
			{
				path: '/package/:id',
				name: 'package',
				component: Package, props: true
			},
			{
				path: '/dashboard',
				name: 'dashboard',
				component: Dashboard,
				meta: {title: 'My Account', requiresAuth: true}
			},
			{
				path: '/my-purchases',
				name: 'myPurchases',
				component: PurchaseHistory,
				meta: {title: 'My Purchases', requiresAuth: true}
			},
			{
				path: '/my-downloads',
				name: 'myDownloads',
				component: DownloadHistory,
				meta: {title: 'My Downloads', requiresAuth: true}
			},
			{
				path: '/list/:id',
				name: 'list',
				component: List,
				props: true,
				meta: {title: 'List', requiresAuth: true}
			},
			{
				path: '/list/share/:ref',
				name: 'sharelist',
				component: ShareList,
				props: true,
				meta: {title: 'Shared List'}
			},
			{
				path: '/downloads',
				name: 'downloads',
				component: Downloads,
				meta: {title: 'Queued Downloads', requiresAuth: true}
			},
			{
				path: '/download-link/:ref',
				name: 'downloadLink',
				component: DownloadLink,
				meta: {title: 'Download Link'}
			},
			{
				path: '/forgot-password',
				name: 'forgotPassword',
				component: ForgotPassword,
				meta: {title: 'Forgot Password'}
			},
			{
				path: '/reset-password',
				name: 'resetPassword',
				component: ResetPassword,
				meta: {title: 'Reset Password'}
			},
			{
				path: '/licensing-terms',
				name: 'licensingTerms',
				component: Terms,
				meta: {title: 'Licensing terms'}
			},
			{
				path: '/cart',
				name: 'cart',
				component: Cart,
				meta: {title: 'My Cart'}
			},
			{
				path: '/checkout',
				name: 'checkout',
				component: Checkout,
				meta: {title: 'Checkout', requiresAuth: true}
			},
			{
				path: '/pricing',
				name: 'pricing',
				component: Pricing,
				meta: {title: 'Pricing'}
			},
			{
				path: '/purchase-options',
				name: 'purchaseOptions',
				component: PurchaseOptions,
				meta: {title: 'Purchase Options'}
			},
			{
				path: '/confirm-payment',
				name: 'confirmPayment',
				component: ConfirmPayment,
				meta: {title: 'Confirm payment'}
			},
			{
				path: '/faq',
				name: 'faq',
				component: Faq,
				meta: {title: 'Frequently Asked Questions'}
			},
			{
				path: '/about',
				name: 'about',
				component: About,
				meta: {title: 'About Caters Media Group'}
			},
			{
				path: '/privacy',
				name: 'privacy',
				component: Privacy,
				meta: {title: 'Privacy Policy'}
			},
			{
				path: '*',
				name: '404',
				component: Error,
				meta: {title: 'page not found'}
			}
		],
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return {x: 0, y: 0}
			}
		}
	});

	router.beforeEach(beforeEach);

	return router
}

function beforeEach(to, from, next) {

	document.title = 'Caters Clips'

	if (to.meta.title) {
		document.title += ' - ' + to.meta.title
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!store.state.isLoggedIn) {
			next({
				path: '/login'
			})
		} else {
			next()
		}
	} else {
		next()
	}
}

// function afterEach(to, from, next) {
//
// 	localStorage.setItem("prevUrl", from.name);
// }
