<template>
	<vue-plyr
		ref="plyr"
		class="w-100"
		:options="{
			autoplay: autoplay,
			muted: true,
			storage: false,
			controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume'],
			fullscreen: { enabled: false, fallback: false, iosNative: false }
		}"
	>
		<video :src="media" ref="video" :poster="thumbnail" class="w-100"></video>
	</vue-plyr>
</template>

<script>
import Hls from 'hls.js'

export default {
	data() {
		return {
			srcLoaded: false
		}
	},
	props: ['thumbnail', 'media', 'hlsmedia', 'shouldClipAutoplay', 'hlsActive'],
	computed: {
		video() {
			return this.$refs.video;
		},
		player() {
			return this.$refs.plyr.player;
		},
		autoplay() {
			return this.hlsActive ? false : this.shouldClipAutoplay;
		}
	},
	methods: {
		setVideoSource() {
			if (Hls.isSupported() && this.hlsActive) {
				this.hls = new Hls();
				this.hls.loadSource(this.hlsmedia);
				this.hls.attachMedia(this.video);
				this.player.play();
				this.srcLoaded = true;
			} else {
				this.video.src = this.media;
				this.player.play();
				this.srcLoaded = true;
			}
		}
	},
	mounted() {
		if (this.hlsActive && this.shouldClipAutoplay) {
			this.setVideoSource();
		} else if (this.hlsActive) {
			this.player.on('play', () => {
				if (!this.srcLoaded) {
					this.player.pause();
					this.setVideoSource();
				}
			});
		}
	}
}
</script>
