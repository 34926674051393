<template>
	<div>
		<div class="container py-4 py-md-5">
			<div class="row">
				<div class="col-12">
					<h1>My Purchases</h1>
					<small>Here you can view all of your purchase history. If you have an queries regarding any of the items
						listed please contact our Licensing team on <a href="mailto:licensing@catersnews.com">licensing@catersnews.com</a> for
						assistance.
					</small>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div v-if="purchases.length || isLoading">
						<template>
							<PurchasesCard
								v-for="(purchase) in purchases" :key="purchase.id"
								:id="purchase.id"
								:purchaseDate="purchase.purchase_date"
								:purchaseRef="purchase.purchase_ref"
								:opid="purchase.opid"
								:product="purchase.unique_ref"
								:amount="parseFloat(purchase.credit_value)"
								:currency="purchase.currency"
								:purchasedClips="purchase.clips !== undefined ? JSON.parse(purchase.clips): []"
							>
							</PurchasesCard>
						</template>
					</div>
				</div>
			</div>

		</div>
	</div>

</template>


<script>

import PurchasesCard from "@/components/PurchasesCard.vue";

export default {

	data() {
		return {
			prepaidCredits: null,
			purchases: [],
			isLoading: true,
		}
	},
	components: {
		PurchasesCard
	},
	computed: {
		token() {
			return this.$store.state.token
		},
		userId() {
			return this.$store.state.account.userId
		},
		userFullName() {
			return this.$store.state.account.userFullName
		},
		accountName() {
			return this.$store.state.account.accountName
		},
		accountId() {
			return this.$store.state.account.accountId
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn
		},
		canPurchase() {
			return this.$store.state.account.options.canPurchase
		}
	},
	methods: {
		async fetchPurchaseHistory() {
			try {
				let url = this.$apiBaseURL + `/clip/prepaidCredits/account/${this.accountId}/all`

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.purchases = result.data;
							this.isLoading = false;
						}
					})
					.catch(error => {
						console.log(error);
						this.isLoading = false;
					});
			} catch (error) {
				console.log(error)
			}
		},
	},
	created() {
		this.fetchPurchaseHistory();
	},
}
</script>

<style scoped>

</style>
