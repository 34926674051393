<template>
	<div class="py-4 py-md-5">
		<div class="container PaymentConfirmation">
			<div class="row">
				<div v-if="paymentConfirmation" class="col-md-10 col-lg-8 col-xxl-6 offset-md-1 offset-lg-2 offset-xxl-3">
					<h1>Payment confirmation</h1>
					<div class="bg-white px-3 py-3">

						<div v-if="paymentConfirmation.status === 'succeeded'">
							<h5 class="text-info text-center">Thank you! Your payment was successful</h5>
							<div class="paymentConfirmation text-center">
								<p>Details of your transaction are below for reference and you will shortly receive an email
									confirmation
									for your records.</p>
								<span class="d-block mb-2">
									<b>TRANSACTION REFERENCE</b><br>{{ paymentConfirmation.id }}
								</span>
								<span class="d-block mb-2">
									<b>TRANSACTION DATE</b><br>{{ paymentConfirmation.created | moment("Do MMMM YYYY") }}
								</span>
								<span class="d-block mb-2">
									<b>TRANSACTION AMOUNT</b><br>{{ paymentAmount }}
								</span>
							</div>
							<div class="text-center" v-if="clipsInQueue">
								<p>
									Your clips are now ready for download<br>
									please click on the button below to download them
								</p>
								<router-link
									:to="{ name: 'downloads' }"
									class="Button Button--green py-1"
								>
									<span class="d-sm-none">
										<IconDownload width="20px" height="20px" fill="#fff"></IconDownload>
									</span>
									<span class="d-none d-sm-inline">CLICK TO DOWNLOAD YOUR FILE(S)</span>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import IconDownload from "@/components/icons/IconDownload.vue";
import {stripeOptions} from "@/modules/stripeOptions";

export default {
	components: {
		IconDownload
	},
	data() {
		return {
			paymentMethod: false,
			salesData: false
		}
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn
		},
		clipsInQueue() {
			return this.$store.state.downloadQueue.length > 0
		},
		moment() {
			return moment;
		},
		paymentAmount() {
			let amount = parseFloat(this.paymentConfirmation.amount) / 100
			return `${this.currencySymbol}${amount.toFixed(2)} ${this.paymentConfirmation.currency.toUpperCase()}`
		},
		currency() {
			return this.$store.state.defaultCurrency.currency
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol
		},
		accountCountryCode() {
			return this.$store.state.account.countryCode
		},
		paymentTransactionKey() {
			return this.$store.state.paymentTransactionKey
		},
		paymentConfirmation() {
			return this.$store.state.paymentTransactionData.pi
		},
		transactionComplete() {
			return this.$store.state.paymentTransactionData === false
		}
	},
	methods: {
		// FOR TESTING CHANGE Stripe KEY from stripeOptions (line 103/104) and set testMode = true (line 123)
		// OBVIOUSLY DON'T FORGET TO PUT IT BACK FOR LIVE MODE
		async getPaymentTransactionResponse() {
			if (this.paymentTransactionKey) {

				// const stripe = Stripe(stripeOptions.pkTest); // TEST KEY
				const stripe = Stripe(stripeOptions.pkLive); // LIVE KEY

				const {paymentIntent, error} = await stripe.retrievePaymentIntent(this.paymentTransactionKey);

				if (paymentIntent) {
					localStorage.removeItem('ptk')

					const piObj = { // just get the bits we need for the confirmation
						id: paymentIntent.id,
						client_secret: paymentIntent.client_secret,
						created: paymentIntent.created,
						amount: paymentIntent.amount,
						currency: paymentIntent.currency,
						status: paymentIntent.status,
					}

					await this.$store.dispatch('buildTransaction', {
						pi: piObj,
						cart: localStorage.getItem('cart'),
						testMode: false // HAVE TO SET THIS MANUALLY - CHANGE TO true IF TESTING
					})

					localStorage.removeItem('ptk')
					this.$store.commit('emptyCart')
					this.$store.commit('resetPricingTier')
				} else {
					console.log(error)
				}
			}
		},
		clearCart() { // make sure we clear the cart on confirmation
			this.$store.commit('emptyCart');
			this.$modal.hide('confirm-delete-modal')
		},
	},
	beforeMount() {
		this.$store.commit('setDefaultCurrency', this.accountCountryCode.toLowerCase()) // need to put this back
		this.getPaymentTransactionResponse()
	},
	mounted() {
	},
}

</script>


<style scoped>

</style>
