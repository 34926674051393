// noinspection JSUnresolvedReference

import Vue from 'vue';
import Vuex from 'vuex';
import Storage from 'vue-ls';
import router from '@/router';
import pricing from '@/data/pricing';

Vue.use(Vuex);
Vue.use(Storage);

let apiBaseURL = 'https://api.catersnews.com/api';

let downloadQueue = localStorage.getItem('downloads');
let cart = localStorage.getItem('cart');
let settings = localStorage.getItem('settings');
let account = localStorage.getItem('account');
let pricingOptions = localStorage.getItem('pricingOptions');
let userId = localStorage.getItem('id');
let token = Vue.ls.get('authtoken');
let userLocation = localStorage.getItem('currentLoc');
let paymentTransactionKey = localStorage.getItem('ptk');
let purchaseUsage = localStorage.getItem('usage');
let selectedTier = localStorage.getItem('currentTier');

const state = {
	userLocation: userLocation ? JSON.parse(userLocation) : false,
	defaultCurrency: false,
	isLoggedIn: !!token,
	token: token,
	userId: userId,
	account: account ? JSON.parse(account) : false,
	pricingOptions: pricingOptions ? JSON.parse(pricingOptions) : false,
	cartTotal: 0,
	cart: cart ? JSON.parse(cart) : [],
	downloadQueue: downloadQueue ? JSON.parse(downloadQueue) : [],
	settings: settings ? JSON.parse(settings) : {autoplay: true},
	categories: [{name: 'Fails', id: 249}, {name: 'Family', id: 255}, {name: 'Paranormal', id: 241}, {
		name: 'Pets',
		id: 250
	}, {name: 'Viral', id: 253}, {name: 'Wild Animals', id: 45}],
	countries: {},
	selectedType: 'video',
	selectedTier: selectedTier ? JSON.parse(selectedTier) : false,
	paymentTransactionKey: paymentTransactionKey ? JSON.parse(paymentTransactionKey) : false,
	purchaseUsage: purchaseUsage ? JSON.parse(purchaseUsage) : false,
	paymentTransactionData: false,
	authCheckout: false
};

const mutations = {
	login(state) {
		state.isLoggedIn = true;

		if (state.cart.length) {
			router.push({name: 'cart'});
		} else {
			this.commit('saveCart')
			this.commit('saveDownloadQueue')
			router.push({name: 'home'});
		}
	},
	logout(state) {
		state.isLoggedIn = false;
		state.token = '';
		state.userId = '';
		state.account = null;
		// state.cart = [];
		state.downloadQueue = [];
	},
	setUser(state, payload) {
		state.account = payload;
	},
	setAuthCheckout(state, payload) {
		state.authCheckout = payload;
	},
	setPCredits(state, payload) {
		state.account.prepaidCredits = payload;
	},
	setPricingTier(state, payload) {
		state.selectedTier = payload;
		this.commit('savePricingTier')
	},
	getQueuedDownloads(state) {
		state.downloadQueue = localStorage.getItem('downloads') ? JSON.parse(localStorage.getItem('downloads')) : [];
	},
	addToDownloadQueue(state, item) {
		this.commit('getQueuedDownloads');

		let found = state.downloadQueue.find(clip => clip.id === item.id);

		if (!found) {
			state.downloadQueue.push(item);
			Vue.set(item, 'id', item.id);
		}

		this.commit('saveDownloadQueue');
	},
	removeFromDownloadQueue(state, item) {
		let index = state.downloadQueue.indexOf(item);

		if (index > -1) {
			state.downloadQueue.splice(index, 1);
		}
		this.commit('saveDownloadQueue');
	},
	emptyDownloadQueue(state) {
		state.downloadQueue = [];
		this.commit('saveDownloadQueue');
	},
	saveDownloadQueue(state) {
		localStorage.setItem('downloads', JSON.stringify(state.downloadQueue));
	},
	getPricingTier(state) {
		state.selectedTier = localStorage.getItem('currentTier') ? JSON.parse(localStorage.getItem('currentTier')) : false;
	},
	savePricingTier(state) {
		localStorage.setItem('currentTier', JSON.stringify(state.selectedTier));
	},
	resetPricingTier(state) {
		state.selectedTier = false
		Vue.ls.remove('currentTier')
	},
	resetTransaction(state) {
		state.paymentTransactionKey = false;
		state.paymentTransactionResponse = false;
		Vue.ls.remove('tr')
	},
	addToCart(state, payload) {
		this.commit('getCart');

		let itemType = payload.type;
		let itemFound = state.cart.find(cartItem => cartItem.id === payload.id);

		// Not in the cart
		if (itemFound === undefined) {
			state.cart.push(payload);
			Vue.set(payload, 'id', payload.id);
			this.dispatch('updateCartTotal', payload.price);
		}

		// Already in the cart and is a bundle
		if (itemFound && itemType === 'bundle') {
			// Increment the quantity
			itemFound.qty += payload.qty;
			// Update cart total
			this.dispatch('updateCartTotal', payload.price);
		}

		this.commit('saveCart');
	},
	updateCart(state, payload) {
		state.cart.forEach((clip, idx) => {
			if (clip.id === payload.id && clip.type === 'bundle') {
				clip.qty = payload.inc ? clip.qty++ : clip.qty--;
			}
		});

		this.commit('saveCart');
	},
	setCartTotal(state, payload) {
		state.cartTotal = payload
	},
	removeFromCart(state, payload) {
		let index = state.cart.indexOf(payload);

		if (index > -1) {
			state.cart.splice(index, 1);
		}

		this.commit('saveCart');
		this.dispatch('updateCartTotal', false)

		if (state.cart.length === 0) {
			this.commit('resetPricingTier');
		}
	},
	emptyCart(state) {
		state.cart = [];
		this.commit('saveCart');
		this.commit('setCartTotal', 0)
		this.commit('resetPricingTier')
	},
	resetCart(state, payload) {
		state.cart = payload
		this.commit('saveCart')
	},
	getCart(state) {
		state.cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
	},
	saveCart(state) {
		localStorage.setItem('cart', JSON.stringify(state.cart));
	},
	setAutoplay(state, bool) {
		state.settings.autoplay = bool;
		this.commit('saveSettings');
	},
	saveSettings(state) {
		localStorage.setItem('settings', JSON.stringify(state.settings));
	},
	updateSelectedType(state, item) {
		state.selectedType = item;
	},
	setCountries(state, payload) {
		state.countries = payload;
	},
	setPricingOptions(state, payload) {
		state.pricingOptions = payload;
	},
	setUserLocation(state, payload) {
		state.userLocation = payload;
		this.commit('saveUserLocation');
	},
	saveUserLocation(state) {
		localStorage.setItem('currentLoc', JSON.stringify(state.userLocation));
	},
	setDefaultCurrency(state, payload) {
		let currency

		if (payload === 'us') {
			currency = {'code': 'us', 'currency': 'USD', 'currencySymbol': '$'}
		} else if (payload === 'gb') {
			currency = {'code': 'gb', 'currency': 'GBP', 'currencySymbol': '£'}
		} else if (payload === 'au' || payload === 'nz') {
			currency = {'code': 'au', 'currency': 'AUD', 'currencySymbol': '$'}
		} else if (payload !== 'au' && payload !== 'nz' && payload !== 'gb' && payload !== 'us') {
			currency = {'code': 'eu', 'currency': 'EUR', 'currencySymbol': '€'}
		} else {
			currency = {'code': 'us', 'currency': 'USD', 'currencySymbol': '$'}
		}

		state.defaultCurrency = currency;
	},
	setPaymentTransactionKey(state, payload) {
		state.paymentTransactionKey = payload;
		this.commit('savePaymentTransactionKey');
	},
	savePaymentTransactionKey(state) {
		localStorage.setItem('ptk', JSON.stringify(state.paymentTransactionKey));
	},
	setTransactionData(state, payload) {
		state.paymentTransactionData = payload;
	},
	setPurchaseUsage(state, payload) {
		state.purchaseUsage = payload;
		this.commit('savePurchaseUsage');
	},
	savePurchaseUsage(state) {
		localStorage.setItem('usage', JSON.stringify(state.PurchaseUsage));
	},
};

const getters = {
	cartHasClips: (state) => {
		let cart = state.cart;

		cart.forEach((item) => {
			if (item.type === 'image' || item.type === 'video') {
				return true
			}
		});

		return false
	},
	cartHasBundles: (state) => {
		let cart = state.cart;

		cart.forEach((item) => {
			if (item.type !== 'image' || item.type !== 'video') {
				return true
			}
		});

		return false
	},
};

const actions = {
	login({commit, dispatch}, data) {
		localStorage.removeItem('authtoken'); // remove old token
		Vue.ls.set('authtoken', data.token, 12 * 60 * 60 * 1000); // new token with 12 hour expiry
		localStorage.setItem('id', data.id);

		state.token = data.token;
		state.userId = data.id;

		commit('login');
		dispatch('fetchUser', {token: data.token, id: data.id});
	},
	logout({commit}) {
		Vue.ls.remove('authtoken');
		localStorage.removeItem('id');
		localStorage.removeItem('account');
		localStorage.removeItem('tier');
		// commit('emptyCart'); // maybe don't empty cart on logout...?
		commit('logout');
	},
	updateAuthCheckout({commit, dispatch}, payload) {
		this.commit('setAuthCheckout', payload)
	},
	updateCartTotal({commit, dispatch}, payload) {
		let total = 0

		if (payload !== false) {
			total = state.cartTotal + payload
		} else {
			if (state.cart.length > 0) {
				state.cart.forEach((o, idx) => {
					total = parseFloat(total) + (parseFloat(o.price) * parseFloat(o.qty))
				})
			}
		}

		this.commit('setCartTotal', total)
	},
	updatePricingTier({commit}, payload) {
		let bundle = state.selectedTier.bundleRef
		let pricingTier = {}

		pricingTier.tierCode = payload.code
		pricingTier.tierName = payload.name
		pricingTier.tierUid = payload.uid
		pricingTier.tierUsageType = payload.usage_type
		pricingTier.currency = state.selectedTier.currency
		pricingTier.bundleRef = bundle
		pricingTier.price = parseInt(payload.options[bundle])

		commit('setPricingTier', pricingTier);
	},
	buildTransaction({commit, dispatch}, payload) {

		let transObj = {
			pi: payload.pi,
			cart: payload.cart,
			account: state.account,
			testMode: payload.testMode
		}

		commit('setTransactionData', transObj)

		setTimeout(() => {
			dispatch('postTransaction', state.paymentTransactionData)
			dispatch('setItemsForDownload', JSON.parse(state.paymentTransactionData.cart))
		}, 500);
	},
	setItemsForDownload({commit, dispatch}, payload) {
		for (let o of payload) {
			commit('addToDownloadQueue', {id: o.id, type: o.type, product: o.productRef, tier: o.tier.tierUid})
		}
	},
	async fetchUserLocation({commit, dispatch}, payload) {
		if (payload) {
			try {
				let url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${payload.lat}&lon=${payload.long}&zoom=3`

				const response = await fetch(url, {
					method: 'GET'
				})

				const result = await response.json()

				if (result.address) {
					commit('setUserLocation', result.address);
				}
			} catch (error) {
				console.log(error)
			}
		}
	},
	async fetchUser({commit, dispatch}, data) {
		try {
			let url = apiBaseURL + `/clip/users/${data.id}`

			const response = await fetch(url, {
				headers: {
					'Authorization': `Bearer ${data.token}`,
					'Accept': 'application/json',
				},
				method: 'GET'
			})

			const result = await response.json()

			if (result.success === true) {
				const userData = result.data

				commit('setUser', userData); // store user details
				commit('setDefaultCurrency', userData.countryCode); // update the default currency based on the user
				localStorage.setItem('account', JSON.stringify(state.account)); // save account to local storage

				let locationData = {
					country: state.account.countryName,
					country_code: state.account.countryCode
				}

				localStorage.setItem('currentLoc', JSON.stringify(locationData)); // update country to local storage
				commit('setUserLocation', locationData);

				if (state.isLoggedIn) {
					// we only want to do the cart stuff below if it's a prepay customer or we empty it
					if (state.account.options.canPurchase === false || state.account.options.canPurchase === undefined) {
						commit('emptyCart')
					}

					// here we reset/refresh the cart if it has items just in case the currency changed
					// not sure if this is the best/right place but was the only place I could get it to work
					if (state.cart.length) {
						let cart = state.cart
						let currency = state.defaultCurrency.currency
						let pricing = state.pricingOptions[currency].video
						let cartTotal = 0

						cart.forEach((item, idx) => {
							if (item.currency !== currency.toLowerCase()) {
								let result = pricing.find(opt => opt.code === item.tier.tierCode)

								if (result) {
									item.currency = currency.toLowerCase()
									item.price = parseFloat(result.options.bundle_1)
									item.tier.tierUid = result.uid
									cartTotal = parseFloat(cartTotal) + parseFloat(result.options.bundle_1)
								}
							}
						})

						commit('resetCart', cart)
						dispatch('updateCartTotal', false);
						// commit('setCartTotal', cartTotal)
					}
				}

				// @todo INCLUDE PREPAID CREDITS - available
				// @todo INCLUDE REGULAR CREDITS - available
			}
		} catch (error) {
			console.error(error);
		}
	},
	async fetchPricingOptions({commit, dispatch}) {
		try {
			let apiUrl = apiBaseURL + '/clip/pricing'

			const response = await fetch(apiUrl, {
				method: 'GET',
			})

			const result = await response.json()

			if (result.success === true) {
				commit('setPricingOptions', result.data)
				localStorage.setItem('pricingOptions', JSON.stringify(state.pricingOptions));
			}
		} catch (error) {
			console.error(error);
		}
	},
	async fetchCountries({commit}) {
		try {
			let url = apiBaseURL + '/clip/countries'

			const response = await fetch(url, {
				method: 'GET',
			})

			const result = await response.json()

			if (result.success === true) {
				commit('setCountries', result.data);
			}
		} catch (error) {
			console.error(error);
		}
	},
	async postTransaction({dispatch}, payload) {
		let url = apiBaseURL + '/clip/payments/add';

		const res = await fetch(url, {
			headers: {
				'Content-Type': 'application/json'
			}, method: 'POST',
			body: JSON.stringify(payload)
		});

		const response = await res.json();

		//@TODO DO SOME CLEANUP AS TRANSACTION FULLY COMPLETED
	},
	setupLocationAndCurrency({dispatch, commit}) {
		// if we aren't logged in and there is no currency
		// we use either the location to set the currency or if no location, set the global default which is GBP
		if (
			(state.isloggedIn === false || state.isloggedIn === undefined) &&
			state.defaultCurrency === false
		) {
			// do we have a location to use?
			if (state.userLocation !== false) {
				this.commit('setDefaultCurrency', state.userLocation.country_code)
			} else {
				let location = {country: "USA", country_code: "us"}
				this.commit('setDefaultCurrency', "us")
				this.commit('setUserLocation', location)
			}

			if (
				(state.isloggedIn === false || state.isloggedIn === undefined) &&
				state.defaultCurrency === false &&
				(state.userLocation !== false || state.userLocation.country_code !== undefined)
			) {
				let location = {country: "USA", country_code: "us"}
				this.commit('setDefaultCurrency', "gb")
				this.commit('setUserLocation', location)
			}
		}

		// if we are logged in we always just use the account details
		if (this.isLoggedIn) {
			let location = {country: state.account.countryName, country_code: state.account.countryCode}
			this.commit('setDefaultCurrency', state.account.countryCode)
			this.commit('setUserLocation', location)
		}
	}
};

export default new Vuex.Store({
	state, mutations, actions, getters,
});
