<!--suppress ALL -->
<template>
	<div>
		<SearchBar></SearchBar>
		<div class="py-4 py-md-5">
			<div class="container Cart page">
				<div class="row">
					<div class="col">
						<div v-if="!isLoggedIn && cartHasItems" class="cta-login-checkout py-3 mb-5 bg-grey">

							<div class="text-center">
								<div>
									<small class="u-textColour--green">
										<b>Please login or create an account to continue with your purchase</b>
									</small>
								</div>
								<div class="">
									<router-link
										:to="{ name:'login' }"
										class="u-textColour--green"
									>
										<span class="d-none d-sm-inline">Login</span>
									</router-link>&nbsp;or
									<router-link
										:to="{ name: 'register'}"
										class="u-textColour--green"
									>
										Create Account
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-10 col-lg-8 col-xxl-6 offset-md-1 offset-lg-2 offset-xxl-3">
						<h2 class="text-uppercase">Your Cart ({{ numberInCart }})</h2>
						<template>
							<div
								v-for="(product, idx) in myCart"
								:key="idx"
							>
								<CartCard
									:product="product"
									:currencySymbol="currencySymbol"
									:currency="currency"
									:trashable="true"
									@itemDeleted="deleteFromCart(product)"
								>
								</CartCard>
							</div>
							<div
								v-if="cartTotal > 0"
								class="pt-3 Cart--total u-textColour--purple"
							>
								<span class="">CART TOTAL:&nbsp;{{ currencySymbol }}{{ cartTotal.toFixed(2) }} {{ currency }}</span>
							</div>
							<div
								v-if="myCart.length"
								class="d-sm-flex text-right justify-content-between mt-4 align-items-center"
							>

								<!-- CHECKOUT -->
								<div class="order-1">
									<router-link
										:to="{name: 'checkout'}"
										class="Button Button--orange order-1 mb-2"
									>
										Checkout
									</router-link>
								</div>

								<!-- CLEAR CART MODAL-->
								<a href="#" @click.prevent="showConfirmClearCartModal"
									 class="Button Button--grey-outline Button--smallest order-0 mb-2">Clear cart</a>
							</div>

							<div
								v-else
							>
								Cart is empty
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>

		<modal name="confirm-delete-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
			<div class="p-4">
				<h3 class="pr-5">Clear your cart</h3>
				<button type="button" name="button" class="btn btn-danger" @click.prevent="clearCart()">
					Confirm
				</button>
				<button type="button" name="button" class="btn btn-link" @click.prevent="$modal.hide('confirm-delete-modal')">
					Cancel
				</button>
			</div>
		</modal>
	</div>

</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import CartCard from '@/components/CartCard.vue';
import IconDownload from '@/components/icons/IconDownload.vue';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import IconCart from "@/components/icons/IconCart.vue";
import IconPerson from "@/components/icons/IconPerson.vue";

export default {
	props: {},
	data() {
		return {
			isDownloading: false,
			isDownloadReady: false,
			loadingFullPage: false,
			loadingOpacity: 0,
			downloadLink: '',
			isLoading: false,
		}
	},
	components: {
		IconPerson,
		IconCart,
		SearchBar,
		CartCard,
		IconDownload,
		Loading
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn
		},
		cartTotal() {
			return this.$store.state.cartTotal
		},
		numberInCart() {
			return this.$store.state.cart.length;
		},
		myCart() {
			return this.$store.state.cart;
		},
		cartHasItems() {
			return this.$store.state.cart.length > 0;
		},
		hasPPC() {
			let ppc = this.$store.state.account.prepaidCredits
			return ppc > 0;
		},
		currency() {
			return this.$store.state.defaultCurrency.currency
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol
		},
		pricingOptions() {
			return this.$store.state.pricingOptions
		}
	},
	methods: {
		showConfirmClearCartModal() {
			this.$modal.show('confirm-delete-modal');
		},
		deleteFromCart(item) {
			this.$store.commit('removeFromCart', item);
			this.showNotification(true, 'The item was removed from your cart');
		},
		clearCart() {
			this.$store.commit('emptyCart');
			this.$modal.hide('confirm-delete-modal')
		},
		calculateCart() {
			let total = 0
			if (this.myCart.length) {
				this.myCart.forEach((o, idx) => {
					total = parseFloat(total) + parseFloat(o.price)
				})

				this.$store.commit('setCartTotal', total)
			}
		},
	},
	beforeMount() {
		this.calculateCart()
	},
	mounted() {
	},
	created() {
		this.$store.commit('getCart');
		this.$store.dispatch('updateCartTotal', false)
	},
	beforeUpdate() {
	},
	updated() {
	}
}
</script>

<style scoped>
.vld-parent {
	width: 64px;
	height: 64px;
	margin: 0 auto;
}

.vld-overlay:focus {
	outline: 0;
}
</style>
