<template>
	<transition name="fade">
		<div v-if="!isLoading">
			<template v-if="canPurchase && hasPurchases">
				<div class="Dashboard-sidebar-credits-heading">My credits</div>
				<div class="Dashboard-sidebar-credits d-flex flex-wrap">
					<div class="px-3 py-2">
						<span class="font-weight-bold">VIDEO</span>
						<div class="Dashboard-sidebar-credits-figure">
							{{ prepaidCredits.available }}
						</div>
					</div>
				</div>
			</template>

			<template v-if="!canPurchase">
				<div class="Dashboard-sidebar-credits-heading">My credits</div>
				<div class="Dashboard-sidebar-credits d-flex flex-wrap">
					<div class="px-3 py-2" v-for="(creditType, key) in standardCredits" :key="key">
						<span class="font-weight-bold">{{ key.toUpperCase() }}</span>
						<div class="Dashboard-sidebar-credits-figure">
							{{ creditType.remaining }}
						</div>
					</div>
				</div>
			</template>

		</div>
	</transition>
</template>

<script>
export default {
	props: [
		'standardCredits',
		'prepaidCredits',
		'canPurchase',
		'hasPurchases',
		'isLoading'
	],
	methods: {},
	computed: {},
	created() {
	}
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to

	/* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
