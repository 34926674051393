<template>
	<div>
		<SearchBar></SearchBar>
		<div class="container page py-4 py-md-5">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<h1>Frequently Asked Questions</h1>
					<div class="Faq">

						<h3>Which Option Is Right for Me?</h3>

						<h6>Digital Editorial</h6>

						<p>Can I monetize the Licensed Material by having ads (including pre-roll and mid-roll) play against
							them?</p>

						<span>Yes, this is allowed.</span>

						<p>Can I edit videos and photos into compilations? What about other produced content like react videos or
							"Try Not to Laugh Challenges?"</p>

						<span>
							Yes, this is also allowed. You have the option to natively post a video or photo with zero
							editorialization if you choose as well as editing the Licensed Material into a new production.
						</span>

						<p>Can I edit the Licensed Material into sponsored videos or commercials for brands and post them
							online?</p>

						<span>
							No, this license, available through self-checkout, only allows for licensing photos and videos for
							online use for editorial purposes only. Editing the Licensed Material into bespoke, sponsored content
							produced specifically to promote a brand or product is outside the scope of this license and may be
							subject to take down requests, additional fees or legal action. If you are interested in producing
							sponsored content using Caters content please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative.
						</span>

						<p>Can I post the Licensed Material across all my social media platforms?</p>

						<span>
							If you control a social media brand that has a presence across multiple platforms (I.e. Facebook, X,
							Instagram, TikTok, Snapchat, YouTube, etc) then you are allowed to post the Licensed Material or
							whatever production you create that incorporates the Licensed Material across all the outlets associated
							with that original brand provided that is the original posted work and not a derivative piece of content
							(for example, you can't post a video natively on one platform and then edit it into a compilation and
							post it to a second platform; this is considered a second usage). Please be aware that there is a 5
							million total subscribers' threshold across your social media network and if the aggregate number of
							followers across all your social media outlets is greater than that number you will no longer be eligible
							for the less expensive digital editorial license. Violation of the terms may result in take down
							requests, additional fees or legal action.
						</span>

						<p>If I run several different social media brands, can I use the same photo or video across all of them at
							no additional cost?</p>

						<span>
							No, at this time we are not allowing this. Licensing is fixed to a single brand. If you wish to use the
							licensed material across multiple distinct brands, please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative to discuss options.
						</span>

						<p>If I purchase a bundle of 10 videos, do I need to select them all at the time of purchase?</p>

						<span>
							You do not. You have the option to pay for a bundle now and download videos as you need them with each
							new download debiting from the pre-paid credits in your account. Please make sure to log in to the same
							account you used at the time of purchase whenever you are downloading videos, so they are correctly
							associated with your earlier purchase.
						</span>

						<h6>National News/Variety/Talk shows</h6>

						<p>Do I qualify for this?</p>

						<span>
							Is the program you work on produced daily or weekly? Does it air nationally/globally across broadcast,
							cable, streaming or OTT? Is the usage editorial in nature (I.e., to inform the public or comment upon a
							topic)? If so, then you qualify for this rights package.
						</span>

						<p>Are there any limits on the distribution of the Licensed Material?</p>

						<span>
							The license covers all media, known or hereafter devised (excluding theatrical use), fixed to the
							production you are licensing for.
						</span>

						<p>Does the license cover archival rights?</p>

						<span>
							Anything produced using the licensed material can be archived across all media (excluding theatrical)
							worldwide, in perpetuity. If the show is posted online, either in its entirety or in excerpted form, the
							licensed material may be included and stay up online for as long as you wish. If the show is distributed
							to SVOD, OTT or TV syndication this is allowed as well. Further, in the case of talk and variety shows,
							airing as part of regularly scheduled reruns is allowed at no additional cost.
						</span>

						<p>Does the license allow for sub-licensing?</p>

						<span>
							The underlying rights to the Licensed Material are not transferable other than as part of the regular
							distribution of the program. The production may not excerpt the Licensing Material and sublicense
							it—either in part or in whole—to another production.
						</span>

						<p>Does the license allow for second uses or derivative uses (like "best of" episodes?)</p>

						<span>
							It does not. If a production needs to use a photo or video in a subsequent episode or a derivative
							episode it will need to be re-licensed for each additional usage, carrying with it an additional licensing
							fee.
						</span>

						<p>If I purchase a bundle of 10 videos or photos, do I need to select them all at the time of purchase?</p>

						<span>
							As with digital editorial usage, you do not. You have the option to pay for a bundle now and download
							videos as you need them with each new download debiting from the pre-paid credits in your account. Please
							make sure to log in to the same account you used at the time of purchase whenever you are downloading
							photos or videos, so they are correctly associated with your earlier purchase.
						</span>


						<h6>Longform productions (including clip shows, documentaries, and docu-series)</h6>

						<p>Do I qualify for this?</p>

						<span>
							Is your production produced over the course of weeks or months with the expectation of airing at an
							indeterminate point in the future? Is the production primarily comprised of archival materials? Is the
							production primarily comprised of documentary footage shot by the production that's looking to supplement
							the project with archival footage as an illustrative example or insert shot? If so, then you qualify for
							this package.
						</span>

						<p>Does the license cover playback in a scripted series like a sitcom or drama?</p>

						<span>
							At this time the license does not cover playback in a work of fiction. If you wish to license a photo or
							video for use in a scripted series, please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative to discuss options.
						</span>

						<p>Are there any limits on the distribution of the Licensed Material?</p>

						<span>
							The license covers all media, known or hereafter devised (excluding theatrical use). This includes
							broadcast, cable, SVOD, AVOD, OTT and online use. The license covers worldwide distribution with an
							in-perpetuity term. For theatrical use in a documentary please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative to discuss options.
						</span>

						<p>What if I don't require the rights of All Media/Worldwide/In Perpetuity</p>

						<span>
							Currently these are the only rights being made available through self-checkout. If you wish to discuss
							reduced rights, please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative to discuss options.
						</span>

						<p>Does the license allow for sub-licensing?</p>

						<span>
							The underlying rights to the Licensed Material are not transferable other than as part of the regular
							distribution of the production. The production may not excerpt the Licensing Material and sublicense
							it—either in part or in whole—to another production.
						</span>

						<p>Does the license allow for second uses or derivative uses (like "best of" episodes?)</p>

						<span>
							It does not. If a production needs to use a photo or video in a subsequent episode or a derivative
							episode it will need to be re-licensed for each additional usage, carrying with it an additional licensing
							fee.
						</span>

						<p>If I purchase a bundle of 10 videos or photos, do I need to select them all at the time of purchase?</p>

						<span>
							As with digital editorial usage, you do not. You have the option to pay for a bundle now and download
							videos as you need them with each new download debiting from the pre-paid credits in your account. Please
							make sure to log in to the same account you used at the time of purchase whenever you are downloading
							photos or videos, so they are correctly associated with your earlier purchase.
						</span>

						<h6>Resolving a claim</h6>

						<p>What should I do if someone makes a claim against a photo or video I license through Caters?</p>

						<span>
							In the unlikely event an ownership or third-party claim is made against a piece of Licensed
							Material purchased through self-checkout please contact
							<a href="mailto:drm@catersnews.com" title="Email Digital Rights Management"><b>drm@catersnews.com</b></a>
							 with a detailed summary of the situation. Someone should be in contact with you within 24 hours
							to discuss next steps.Provided the usage falls within the terms of the license agreement,
							Caters will resolve the claim in as timely a manner as the situation will allow for.
						</span>

						<h6>Everything Else</h6>

						<p>What if the usage I require is not listed here?</p>

						<span>
							For all other uses including, but not limited to, use in a motion picture, scripted playback, music
							videos, educational use, commercial/branded use, and affiliate licensing please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative to discuss options.
						</span>

						<p>Are the licensing terms negotiable?</p>

						<span>
							For self-checkout the terms and conditions are to be accepted as is. If you require changes to
							the agreement, then the Licensed Materials will require a custom license and may take longer to be
							agreed upon in addition to potentially being subject to additional fees. Please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative to discuss options.
						</span>

						<p>What I'm looking to purchase a large of volume of photos or videos?</p>

						<span>
							If the total amount of photos or videos you wish to license exceed the size of a bundle (<b>10 assets</b>)
							please contact
							<a href="mailto:licensing@catersnews.com" title="Email Licensing"><b>licensing@catersnews.com</b></a>
							to speak with a sales representative to discuss different pricing options for high-volume purchases.
						</span>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconAdd from "@/components/icons/IconAdd.vue";
import SearchBar from "@/components/SearchBar.vue";

export default {
	components: {SearchBar, IconAdd}
}
</script>
