<template>
	<modal name="pricing-options-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
		<div class="p-4">
			<h5>Available Usage Options</h5>
			<div class="modal-text-small">
				<p>Please select your intended usage option below.<br>For more information & terms please visit our
					<router-link
						:to="{name: 'pricing'}"
						target="_blank"
					>
						<b>Pricing</b>
					</router-link>
					page.
				</p>

				<PricingOptions @tierSelected="tierSelected"></PricingOptions>

				<div class="mt-3 alert alert-info pricing-options-alert text-center">
					<b>PLEASE NOTE: </b>Once you have selected a usage option, if you continue to add more clips they will
					automatically priced be for the same usage. If you want to reset this so that you can select pricing for
					another type of usage, you can either clear the items form your cart & start again or you will also be able
					to change this option at the checkout stage if preferred.
				</div>
				<div class="modal-close">
					<a href="#" @click.prevent="$modal.hide('pricing-options-modal')">
						<IconClose width="50px" height="50px" fill="#5F007F"></IconClose>
					</a>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>

import PricingOptions from "@/components/PricingOptions.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
	components: {IconClose, PricingOptions},
	methods: {
		tierSelected(selection) {
			this.$emit('tierSelected', selection)
		},
	}
}

</script>
