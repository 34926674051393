<template>
	<transition name="fade">
		<div>
			<router-link :to="{ name: 'clip', params: { id: clipId }}" class="Download">
				<div class="Download-image" v-bind:style="{ backgroundImage: 'url('+thumbnail+')' }"></div>
				<div class="Download-text">
					<p class="u-textColour--purple">1x {{ clipTitle.toUpperCase() }}</p>
					<div class="d-flex justify-content-between">
						<span class="Download-text-ref">
							<b>Item Code:</b> {{ product.productRef }}
						</span>
						<span class="Download-text-ref">
							<b>Fee:</b> {{ currencySymbol }}{{ product.price }} {{ currency }}
						</span>
					</div>
<!--					<div>-->
<!--						<span  class="Download-text-ref"><b>License:</b><br>-->
<!--							<small>{{ product.tier.tierName }}<br>{{ product.tier.tierDescription }}</small>-->
<!--						</span>-->
<!--					</div>-->
				</div>

				<div class="Download-trash border-left p-1 d-flex align-items-center" v-if="this.trashable"
						 @click.prevent="deleteItem()">
					<IconTrash width="25px"></IconTrash>
				</div>
			</router-link>
		</div>
	</transition>
</template>

<script>
import IconTrash from '@/components/icons/IconTrash';
import NotForSocialSmall from '@/components/messages/NotForSocialSmall';

export default {
	props: {
		id: {Type: String, default: null},
		product: {Type: Object, default: null},
		date: {Type: String, default: null},
		trashable: {Type: Boolean, default: false},
		type_prop: {Type: String, default: null},
		currencySymbol: {Type: String, default: null},
		currency: {Type: String, default: null},

	},
	components: {
		IconTrash,
		NotForSocialSmall
	},
	data() {
		return {
			clipTitle: '',
			thumbnail: '',
			clipId: ''
		}
	},
	computed: {},
	methods: {
		deleteItem() {
			this.$emit('itemDeleted');
		},
		async fetchClip() {
			try {
				let url = this.$apiBaseURL + `/clip/clips/${this.product.id}`;
				let headers = {};

				// Check for auth users and add token to headers
				if (this.$store.state.token) {
					headers = {
						Authorization: `Bearer ${this.$store.state.token}`,
					};
				}

				await fetch(url, {
					headers: headers,
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							const data = result.data;

							this.clipId = data.id;
							this.clipType = data.clip_type;
							this.clipTitle = data.title;
							this.licensingTerm = data.licensing_terms;
							this.thumbnail = this.fileUrl(data.media.thumbpath);
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
	},
	mounted() {
		if (this.product.type !== 'bundle') {
			// get the clip details if not a bundle
			// @todo Handle something for a bundle
			this.fetchClip();
		}
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
