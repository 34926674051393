<!--suppress ALL -->
<template>
	<div id="app">
		<AppHeader
			v-bind:class="[this.$route.name]"
			v-on:tierSelected="setTier(tier)"
		></AppHeader>
		<router-view v-on:makeHeaderFixed="headerFixed = true" :key="$route.path"></router-view>
		<AppFooter></AppFooter>

		<notifications position="top right" width="320px">
			<template slot="body" slot-scope="props">
				<div :class="['vue-notification-template', 'alert', props.item.type]">
					<a class="close" @click="props.close">
						x
					</a>
					<div v-html="props.item.text"></div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import SearchBar from '@/components/SearchBar.vue';
import IconClose from "@/components/icons/IconClose.vue";

export default {
	name: 'App',
	components: {
		AppHeader,
		AppFooter,
		SearchBar,
		IconClose
	},
	data() {
		return {
			coords: null
		}
	},
	computed: {
		isloggedIn() {
			return this.$store.state.isLoggedIn
		},
		locationSet() {
			return this.$store.state.userLocation !== false
		},
		showSearchBar() {
			let showOnRoutes = ['search', 'clip', 'cart'];
			return showOnRoutes.includes(this.$route.name);
		}
	},
	methods: {
		syncCart() {
			this.$store.commit('getCart');
		},
		reloadAfterAuthChange() {
			location.reload();
		},
		checkIfSingleClipPurchase() {
			let isPrepay = this.$store.state.account.canPrepay;

			if (isPrepay) {
				let ppc = this.$store.state.account.prepaidCredits;
				if (ppc === 0) {
					return true;
				}
			}
			return false;
		},
		addBundleToCart(bundle) {
			this.$modal.hide('pricing-options-modal');
			let isSingleClipPurchase = this.checkIfSingleClipPurchase();

			if (!this.$store.getters.cartHasClips) {
				let pricing = this.pricingOptions.options;
				let pname = this.pricingOptions.name + " - " + bundle.num_clips + " Credit";

				let item = {
					id: bundle.id,
					type: 'bundle',
					productId: "BDL-" + bundle.id + "-" + this.randomString(10),
					productName: pname
				}

				pricing.forEach((option) => { // get pricing for this bundle
					if (bundle.id === option.id) {
						item.price = option.price
					}
				});

				this.$store.commit('addToCart', item);
				this.justAddedToCart = true;
				this.selectedBundle = bundle.id;
				this.showNotification(true, 'The ' + item.type + ' was added to your cart');
			} else {
				this.showNotification(false, 'Please remove the clips from your cart first before purchasing credits');
			}
		},
		calculateGBPDiscount(tier, ppc) {
			let numClips = tier.num_clips;
			let stdPrice = numClips * ppc;
			return stdPrice - tier.price;
		},
		calculatePercentDiscount(tier, ppc) {
			let numClips = tier.num_clips;
			let stdPrice = numClips * ppc;
			let saving = stdPrice - tier.price;
			let percentage = 100 - ((tier.price / stdPrice) * 100);
			return percentage.toFixed(0);
		},
		showPricingOptionsModal() {
			this.pricingOptions = this.$store.state.pricingOptions
			this.$modal.show('pricing-options-modal');
		},
	},
	getLocation(position) {
		this.coords = {lat: position.coords.latitude, long: position.coords.longitude}

		if (this.coords) {
			this.$store.dispatch('fetchUserLocation', this.coords);
		}
	},
	getLocationErrors(error) { // if we have any errors for geolocation then set default of GBP
		let errorMessage = false

		switch (error.code) {
			case error.PERMISSION_DENIED:
				errorMessage = "User denied the request for Geolocation."
				break;
			case error.POSITION_UNAVAILABLE:
				errorMessage = "Location information is unavailable."
				break;
			case error.TIMEOUT:
				errorMessage = "The request to get user location timed out."
				break;
			case error.UNKNOWN_ERROR:
				errorMessage = "An unknown error occurred."
				break;
		}

		if (errorMessage) {
			this.$store.commit('setDefaultCurrency', "gb")
		}
	},
	beforeCreate() {
		this.$store.dispatch('fetchCountries');
		this.$store.dispatch('fetchPricingOptions');
	},
	beforeMount() {
		// if (!this.isloggedIn && !this.locationSet) {
		// 	if (navigator.geolocation) {
		// 		navigator.geolocation.getCurrentPosition(this.getLocation, this.getLocationErrors);
		// 	} else {
		// 		console.log('there were errors')
		// 	}
		// }

		this.$store.dispatch('setupLocationAndCurrency');
	},
	mounted() {
		this.$ls.get('authtoken');
		this.$ls.on('account', this.reloadAfterAuthChange);
		this.$ls.on('authtoken', this.reloadAfterAuthChange);

		// Remove old token
		if (localStorage.getItem('token')) {
			localStorage.removeItem('token');
			this.$store.dispatch('logout');
			location.reload();
		}

		// Show current size of localStorage....I think
		// let LS = new Blob(Object.values(localStorage)).size;
		// LS = LS/1024;
		// console.log(LS.toFixed(2)+" KB");


		// console.log(this.$store.getters.usersLocation)

		// const items = [{"id":3568435,"type":"video","ourRef":"VID-3568435","media":"VID","productRef":"DEMA-VID-3568435","description":"BABY GETTING A DERMA FACIAL MASSAGE","qty":1,"price":200,"currency":"gbp","tier":{"tierUid":"24d1b093-146e-477f-a8de-e8dcc46d7f4c","tierCode":"DEMA","tierName":"Digital Large","tierUsageType":"web","bundleRef":"bundle_1"}}];
		//
		// for (let item of items) {
		// 	// item = {id:item.id,type:item.type}
		// 	console.log(item)
		// }


	},
}
</script>

<style>
.plyr--video {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
}

.plyr__video-wrapper {
	width: 100%;
}

.plyr__control--overlaid {
	background: rgba(39, 182, 179, 0.8) !important;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
	background: #27b6b3 !important;
}

.plyr--full-ui input[type=range] {
	color: #27b6b3 !important;
}

input:required {
	box-shadow: none;
}
</style>
