<template>
	<div>
		<div class="container py-4 py-md-5">
			<div class="row">
				<div class="col-12">
					<h1>My Downloads</h1>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="mt-5 mb-5 mt-md-0 pl-md-3 pl-lg-5">
						<div v-if="downloads.length || isLoading">
							<template>
								<DownloadsCard
									v-for="(item, idx) in downloads" :key="item.id"
									:id="item.clip_id"
									:date="item.created"
									:thumbnail_prop="fileUrl(item.clip.media.thumbpath)"
									:title_prop="item.clip.title"
								>
								</DownloadsCard>
							</template>
						</div>
						<div v-else>
							No recent downloads
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>


<script>
import DownloadsCard from "@/components/DownloadsCard.vue";

export default {
	data() {
		return {
			downloads: [],
			isLoading: true,
		}
	},
	components: {
		DownloadsCard,
	},
	computed: {
		token() {
			return this.$store.state.token
		},
		userId() {
			return this.$store.state.account.userId
		},
		userFullName() {
			return this.$store.state.account.userFullName
		},
		accountName() {
			return this.$store.state.account.accountName
		},
		accountId() {
			return this.$store.state.account.accountId
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn
		},
	},
	methods: {
		async fetchDownloads() {
			try {
				let url = this.$apiBaseURL + `/clip/downloads/user/${this.userId}/all`

				await fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json',
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.downloads = result.data;
							this.isLoading = false;
						}
					})
					.catch(error => {
						console.log(error);
						this.isLoading = false;
					});
			} catch (error) {
				console.log(error)
			}
		},
	},
	created() {
		this.fetchDownloads();
	},
	mounted(){
	}
}
</script>

<style scoped>

</style>
