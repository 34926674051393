var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-plyr',{ref:"plyr",staticClass:"w-100",attrs:{"options":{
		autoplay: _vm.autoplay,
		muted: true,
		storage: false,
		controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume'],
		fullscreen: { enabled: false, fallback: false, iosNative: false }
	}}},[_c('video',{ref:"video",staticClass:"w-100",attrs:{"src":_vm.media,"poster":_vm.thumbnail}})])
}
var staticRenderFns = []

export { render, staticRenderFns }