<!--suppress ALL -->
<template>
	<header class="Header js-header">
		<div class="row">
			<div class="col-12 mb-3 Header--top">
				<div class="Header-logo">
					<router-link :to="{ name: 'home' }"><img src="@/assets/cmg-logo.svg" alt="Logo"></router-link>
				</div>
				<div class="Header-buttons">

					<template>
						<div class="Header-buttons-mobile d-flex d-md-none order-1">
							<router-link
								v-if="isLoggedIn && this.$store.state.downloadQueue.length"
								:to="{ name: 'downloads' }"
								class="Button Button--orange Button--smaller Button--hasIcon py-1 pr-3 text-uppercase">
							<span class="Button-icon">
									<IconDownload width="20px" class="ml-2"></IconDownload>
							</span>
								<span>{{ numberInQueue }}</span>
							</router-link>

							<router-link
								:to="{ name: 'cart' }"
								v-if="this.$store.state.cart.length"
								class="Button Button--orange Button--smaller Button--hasIcon py-1 pr-3 text-uppercase">
						<span class="Button-icon">
							<IconCart width="20px" class="ml-2"></IconCart>
						</span>
								<span>{{ numberInCart }}</span>
							</router-link>
							<b-dropdown
								id="ddown1"
								variant="light"
								right
								toggle-class="Button Button--grey Button--grey--light Button--smaller py-1 px-2 align-items-center">
								<template slot="button-content">
									<IconPerson width="20px" height="20px" fill="#444"></IconPerson>
								</template>
								<b-dropdown-item href="https://mailer.catersnews.com/h/t/BE5DF7049DCF2B06">
									<a href="https://mailer.catersnews.com/h/t/BE5DF7049DCF2B06" class="dropdown-item"
										 target="_blank">Newsletter</a>
								</b-dropdown-item>
								<b-dropdown-item>
									<router-link class="dropdown-item" :to="{ name: 'dashboard' }">My
										account
									</router-link>
								</b-dropdown-item>
								<b-dropdown-item>
									<a href="#" class="dropdown-item" @click.prevent="logout">Logout</a>
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>

					<div class="">
						<template v-if="isLoggedIn">
							<div class="logged-in-user pr-3">
								<span class="d-none d-lg-inline-block u-textColour--purple">
									Hello&nbsp;
								</span>
								<span class="text-secondary user-name">{{ firstname }}</span>
								<span class="d-none d-md-inline-block">
									<span class="user-pipe-divide">&nbsp;|&nbsp;</span>
								<router-link
									:to="{ name: 'dashboard' }"
									class="user-my-account u-textColour--green"
								>
									My Account
								</router-link>
							</span>
							</div>
						</template>
					</div>

					<template>
						<div class="Header-buttons-loggedOut d-flex">
							<router-link
								:to="{ name: 'pricing' }"
								class="Button Button--grey-outline py-1"
							>
								Pricing
							</router-link>
							<a href="https://mailer.catersnews.com/h/t/BE5DF7049DCF2B06" target="_blank"
								 class="d-none d-md-inline Button Button--smallest Button--purple-outline py-1 px-1 px-sm-2 align-items-center text-uppercase">
								SUBSCRIBE TO OUR NEWSLETTER
							</a>
							<router-link
								v-if="this.$store.state.downloadQueue.length"
								:to="{ name: 'downloads' }"
								class="Button Button--green Button--smaller Button--hasIcon py-1 d-none d-md-inline-block">
								<span class="Button-icon">
									<IconDownload width="20px" class="ml-2"></IconDownload>
								</span>
								<span>{{ numberInQueue }}</span>
							</router-link>

							<router-link
								v-if="this.$store.state.cart.length"
								:to="{ name: 'cart' }"
								class="Button Button--orange Button--smaller Button--hasIcon py-1 text-uppercase d-none d-md-inline-block"
							>
								<span class="Button-icon">
									<IconCart width="20px" class="ml-2"></IconCart>
								</span>
								<span>{{ numberInCart }}</span>
							</router-link>

							<router-link
								:to="{ name: 'login' }" class="Button Button--purple py-1"
								v-if="!isLoggedIn"
							>
								<span class="d-sm-none">
									<IconPerson width="20px" height="20px" fill="#fff"></IconPerson>
								</span>
								<span class="d-none d-sm-inline">Login</span>
							</router-link>
							<a
								v-if="isLoggedIn"
								@click.prevent="logout"
								href="#"
								class="d-none d-md-inline Button Button--smallest Button--grey py-1 px-1 px-sm-2 text-uppercase">
								LOGOUT
							</a>

							<router-link
								v-if="!isLoggedIn"
								:to="{ name: 'register' }"
								class="Button Button--orange py-1"
							>
								Sign Up
							</router-link>
							<button
								class="Header-buttons-settings Button Button--smallest Button--grey--light py-1 px-1 px-sm-2 align-items-center"
								@click.prevent="showSettingsModal">
								<IconSettings></IconSettings>
							</button>
						</div>
					</template>

				</div>
			</div>
		</div>

		<modal name="settings-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
			<div class="p-4">
				<h3 class="pr-5">Settings</h3>

				<div class="custom-control custom-switch">
					<input type="checkbox" class="custom-control-input" id="autoplaySwitch" v-model="autoplay"
								 @change="setAutoplay">
					<label class="custom-control-label" for="autoplaySwitch">Autoplay video previews</label>
				</div>

				<div class="modal-close">
					<a href="#" @click.prevent="$modal.hide('settings-modal')">
						<IconClose width="50px" height="50px" fill="#5F007F"></IconClose>
					</a>
				</div>
			</div>
		</modal>
	</header>

</template>

<script>
import IconPerson from '@/components/icons/IconPerson';
import IconCart from '@/components/icons/IconCart';
import IconDownload from '@/components/icons/IconDownload';
import IconSettings from '@/components/icons/IconSettings';
import IconClose from '@/components/icons/IconClose';

export default {
	data() {
		return {
			autoplay: true,
			canPrepay: false,
			pricingOptions: false,
			pCredits: false,
			showNav: false,
		}
	},
	components: {
		IconPerson,
		IconCart,
		IconDownload,
		IconSettings,
		IconClose,
	},
	methods: {
		logout() {
			this.$store.dispatch('logout');
			this.$router.push({name: 'login'});
			this.showNotification(true, 'You are now logged out.');
		},
		showSettingsModal() {
			this.$modal.show('settings-modal');
		},
		setAutoplay() {
			this.$store.commit('setAutoplay', this.autoplay);
		},
		toggleNav() {
			this.showNav = !this.showNav;
		}
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		numberInCart() {
			return this.$store.state.cart.length;
		},
		numberInQueue() {
			return this.$store.state.downloadQueue.length;
		},
		firstname() {
			return (this.$store.state.account !== null && this.$store.state.account.userFirstName !== undefined) ?
				this.$store.state.account.userFirstName : ''
		}
	},
	beforeMount() {
	},
	mounted() {
	},
	beforeUpdate() {
	}
}
</script>
