<template>

	<div class="shadow-lg categories" style="position:relative">
		<div class="container py-4">
			<h4 class="text-center mt-5">Browse video by category</h4>
			<div class="d-flex justify-content-center flex-wrap pb-3 px-xl-5">
				<template v-for="category in categories">
					<router-link
						:to="{ name: 'search', query: {'type':'video','category':category.name, 'cat':category.id} }"
						class="Button Button--grey-outline m-2">
						{{ category.name }}
					</router-link>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			categories: this.$store.state.categories
		}
	}
}
</script>
