<!--suppress ALL -->
<template>
	<div>
		<SearchBar></SearchBar>
		<div class="container page py-4 py-md-5">
			<div class="row">
				<div class="col-12">
					<h1>Pricing</h1>
				</div>
			</div>

			<template v-if="pricing">
				<div class="row Pricing d-flex">
					<template v-for="videoPricing in pricing.video">
						<div class="col-3 mb-2">
							<div class="Pricing--panel">
								<div class="Pricing--panel-top">
									<h6 class="Pricing--panel-heading">
										{{ videoPricing.name }}
										<template v-if="videoPricing.code === 'DEMA'">
											<small class="text-danger">*</small>
										</template>
									</h6>
									<p class="Pricing--panel-subheading">{{ videoPricing.description }}</p>
									<p class="Pricing--panel-single-text">
										{{ currencySymbol }}{{ videoPricing.options.bundle_1 }} {{ currencyCode }} per clip
										<template v-if="currencyCode==='GBP'|| currencyCode==='AUD'">
											<small class="text-danger">**</small>
										</template>
										<br>
									</p>
								</div>

								<div class="Pricing--panel-bottom">

									<div v-if="videoPricing.code==='DELI'">
										<span class="Pricing--panel-details">Monetization Allowed</span>
										<span class="Pricing--panel-details">Unlimited Social Media Platforms</span>
										<span class="Pricing--panel-details">Audience size: Up to 5 million</span>
									</div>

									<div v-if="videoPricing.code==='DEMA'">
										<span class="Pricing--panel-details">Monetization Allowed</span>
										<span class="Pricing--panel-details">Unlimited Social Media Platforms</span>
										<span class="Pricing--panel-details">Audience Size: 10 million or less</span>
									</div>

									<div v-if="videoPricing.code==='NNTS'">
										<span class="Pricing--panel-details">No Monetization</span>
										<span class="Pricing--panel-details">Single On-Air Use</span>
										<span class="Pricing--panel-details">All Media + Worldwide</span>
									</div>

									<div v-if="videoPricing.code==='CSDO'">
										<span class="Pricing--panel-details">No Monetization</span>
										<span class="Pricing--panel-details">Single On-Air Use</span>
										<span class="Pricing--panel-details">All Media + Worldwide</span>
									</div>

								</div>

							</div>

						</div>

					</template>

					<div class="col-12">
						<small class="d-block text-danger">
							<span style="font-size: 12px;"><i>*</i> Not Applicable for networks over 10 million, price on application. Please
								contact licensing@catersnews.com</span>
						</small>
						<small v-if="currencyCode==='GBP'|| currencyCode==='AUD'" class="d-block text-danger mb-3">
							<span style="font-size: 12px;"><i>** Local sales taxes apply</i></span>
						</small>
						<p class="u-textColour--purple font-weight-bold text-center h5">
							For multiple clips, bulk purchases and bespoke packages at competitive prices please contact
							<u class="u-textColour--green">
								<a class="u-textColour--green" href="mailto:licensing@catersnews.com" title="Email Licensing">
									<b>licensing@catersnews.com</b>
								</a>
							</u>
						</p>
					</div>

					<div class="Pricing--lower w-100">
						<a href="#" @click.prevent="showComparisonModal"
							 class="w-50 py-1 px-2 d-block mx-auto Button Button--smaller Button--grey--light">
							COMPARE THE OPTIONS IN DETAIL
						</a>
					</div>
				</div>
			</template>
		</div>

		<modal name="comparison-modal" height="auto" width="95%" :adaptive="true" :scrollable="true">
			<div class="p-4 tier-comparison-modal-content">
				<TierComparison></TierComparison>
				<div class="modal-close">
					<a href="#" @click.prevent="$modal.hide('comparison-modal')">
						<IconClose width="50px" height="50px" fill="#5F007F"></IconClose>
					</a>
				</div>
			</div>
		</modal>

	</div>

</template>

<script>
import IconCart from "@/components/icons/IconCart.vue";
import TierComparison from "@/components/TierComparison.vue";
import IconClose from "@/components/icons/IconClose.vue";
import SearchBar from "@/components/SearchBar.vue";

export default {
	components: {
		SearchBar,
		IconClose,
		TierComparison,
		IconCart
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		myCart() {
			return this.$store.state.cart
		},
		pricing() {
			return this.$store.state.pricingOptions[this.currencyCode]
		},
		currencyCode() {
			return this.$store.state.defaultCurrency.currency
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol
		},
		cartType() {
			if (this.myCart.length) {
				return this.myCart[0]['type']
			}
		}
	},
	methods: {
		showComparisonModal() {
			this.$modal.show('comparison-modal');
		},
		getPricingInfo(bundleName, value, type, options = null) {
			if (!bundleName || !value || !type) {
				return 'error'
			}

			let discount = 0
			let numClips = parseInt(bundleName.slice(7));
			let pricePerClip = value / numClips

			if (options) {
				let singlePrice = options.bundle_1
				discount = 100 - (pricePerClip / singlePrice * 100)
				discount = discount.toFixed(1)
			}

			if (type === 'numClips') {
				return numClips
			}

			if (type === 'single') {
				return singlePrice
			}

			if (type === 'perClip') {
				return pricePerClip
			}

			if (type === 'discount') {
				return discount
			}
		},
		addProductToCart(tier, uid, bundle, price, mediaType, quantity = 1) {
			if (!this.myCart.length || this.cartType === 'bundle') {
				let currency = this.$store.state.defaultCurrency.currency
				let bundleType = mediaType === 'video' ? 'VID' : 'PX'
				let productRef = `${tier}-${bundle}-${bundleType}-${this.randomString(10)}`
				let productDescription = `CMG CLIPS ${mediaType.toUpperCase()} PACKAGE (${parseInt(bundle.slice(7))} clips)`

				// check if product already added by type & bundle - this does not really work currently
				let productExists = this.$store.state.cart.find((product) => product.productRef === productRef);
				let typeExists = this.$store.state.cart.find((product) => product.type === mediaType);

				if (!productExists) { // is the product already in the cart?

					/**
					 * @todo we may need some other reference here to indicate the tier if someone is allowed to buy the same
					 * type of bundle from multiple tiers - I don't think that's allowed but it might be
					 */
					let product = {
						id: uid,
						tier: tier,
						type: 'bundle',
						ourRef: bundle,
						media: bundleType,
						productRef: productRef,
						description: productDescription,
						qty: quantity,
						price: quantity * price,
						currency: currency.toLowerCase()
					}

					this.$store.commit('addToCart', product);
					this.showNotification(true, 'The product was added to your cart');
				} else {
					this.showNotification(false, 'You already have this product in your cart!');
				}
			} else {
				this.showNotification(false, 'You cannot add individual clips & bundles at the same time');
			}
		},
		randomString: function (length) {  // move to a mixin/helper
			return Math.random()
				.toString(36)
				.substring(2, length)
				.split("")
				.map(e => (Math.random() < Math.random() ? e.toUpperCase() : e))
				.join()
				.replaceAll(",", "");
		}
	},
	beforeCreate() {
		this.$store.dispatch('fetchPricingOptions');
	},
	beforeMount() {
	},
	mounted() {
	},
	created() {
	}
}
</script>

<style scoped></style>
