<template>
	<swiper :options="swiperOption" ref="mySwiper" v-bind:class="{ 'popupActive': popupActive }">
		<swiper-slide v-for="(clip, index) in this.clips" :key="index">
			<clip-card
				enablePopup=true
				:type="clip.type"
				:id="clip.id"
				:title="clip.title"
				:rating="clip.meta.rating"
				:duration="clip.meta.duration"
				:definition="clip.meta.definition"
				:thumbnail="fileUrl(clip.thumbpath)"
				:hlsActive="clip.meta.hls"
				:media="fileUrl(clip.filepath)"
				:hlsmedia="fileUrl(clip.meta.hls_path)"
				:graphic="clip.meta.graphic"
				:enquiriesOnly="false"
				:dateAdded="clip.created"
				:notForSocial="clip.meta.notSocial"
				@popupOpened="popupActive = true"
				@popupClosed="popupActive = false">
			</clip-card>
		</swiper-slide>

		<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
		<div class="swiper-button-next swiper-button-white" slot="button-next"></div>
	</swiper>
</template>

<script>
import ClipCard from '@/components/ClipCard.vue'
import 'swiper/dist/css/swiper.css'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import image from "@/assets/placeholder.png";

const placeholder = new Image();
placeholder.src = image;

export default {
	data() {
		return {
			placeholder: placeholder.src,
			popupActive: false,
			swiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				loop: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			}
		}
	},
	props: ['clips'],
	components: {
		ClipCard,
		swiper,
		swiperSlide
	}
}
</script>

<style scoped>
.swiper-slide {
	width: 300px;
}

.swiper-container {
	overflow: visible;
}

.popupActive {
	z-index: 99;
}
</style>
