<template>
	<div class="row">
		<table class="table table-sm">
			<tbody>
			<template v-for="po in pricingOptions.video">


				<tr>
					<th>
						<span class="u-textColour--green">{{ po.name.toUpperCase() }}</span>
					</th>
					<td class="text-right">
						<b-btn
							class="Button Button--orange w-100"
							v-if="!selectedTier || selectedTier=== po.code"
							squared
							@click.prevent="$emit('tierSelected', {
							tierUid:po.uid,
							tierCode:po.code,
							tierName:po.name,
							tierDescription:po.description,
							tierUsageType:po.usage_type,
							bundleRef:'bundle_1',
							price:parseInt(po.options.bundle_1),
							currency:currency
						})"
							size="sm"
							variant="success"
						>
							{{ currencySymbol }}{{ po.options.bundle_1 }}&nbsp;{{ currency }}
						</b-btn>
						<b-btn
							v-else
							@click.prevent="alert('you cannot choose this option')"
							squared
							size="sm"
							variant="danger"
						>
							{{ currencySymbol }}{{ po.options.bundle_1 }}&nbsp;{{ currency }}
						</b-btn>
					</td>
				</tr>
			</template>
			</tbody>
		</table>
	</div>
</template>

<script>

export default {
	computed: {
		pricingOptions() {
			return this.$store.state.pricingOptions[this.currency]
		},
		currency() {
			return this.$store.state.defaultCurrency.currency
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol
		},
		selectedTier() {
			return this.$store.state.selectedTier
		},
	},
	methods: {}
}


</script>

<style scoped>

</style>
