<template>
	<div>
		<SearchBar></SearchBar>
		<div class="container page py-4 py-md-5">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<h1>PRIVACY NOTICE</h1>
					<div>
						<div class="privacy">
							<h3>Introduction</h3>
							<h4>Data Controller</h4>
							<p>Under the EU General Data Protection Regulation (<b>GDPR</b>) which comes into force on 25 May 2018, we
								have to say who the 'data controller' is for <b>clips.catersnews.com</b> (<b>Caters Clips</b>) and
								services provided through it. The data controller is the organisation responsible for protecting
								information and, in our case, this is <b>Caters Media Group Limited</b>, a company incorporated in
								England, with company number <b>09635833</b>, whose registered office address is <b>68 Argyle Street,
									Birkenhead, Merseyside, United Kingdom, CH41 6AF</b>, which is part of the <b>Caters News Media
									Group</b> (<b>Caters</b>). ICO Registration Number: <b>ZA286679</b></p>

							<h3>How to contact us</h3>
							<p>Please address requests and questions about this or any other question about this Privacy Policy
								to the Data Protection Officer at <a href="mailto:dpo@catersnews.com">dpo@catersnews.com</a>, or call us
								on <b>+44 (0)121 616 1100</b> or write to us at <b>Caters Media Group Limited, The Drop Forge, 6-10
									Hockley Street, B18 6BL, United Kingdom.</b></p>

							<h3>Legal information</h3>
							<p>Complaints will be dealt with by the Data Protection Officer and will be responded to within 30 days at
								the latest.</p>

							<h4>If you are not satisfied with the way your complaint was handled</h4>
							<p>You are able to refer your complaint to your local data protection regulator. For example,
								in the United Kingdom this is the <i>Information Commissioner's Office</i>, and in Australia is the
								<i>Office of the Australian Information Commissioner</i>. We take your privacy very seriously and we
								ask that you read this Privacy Policy carefully as it contains important information on:</p>

							<ul>
								<li>the personal data we collect about you;</li>
								<li>how we collect your personal data;</li>
								<li>how and why we use your personal data;</li>
								<li>disclosures of your personal data; and</li>
								<li>your legal rights in relation to your personal data</li>
							</ul>

							<h3>The data we collect about you</h3>
							<p>Personal data, or personal information, means any information about an individual from which that
								person
								can be identified. It does not include data where the identity has been removed (anonymous data).
								We may collect, use, store and transfer different kinds of personal data about you which we have grouped
								together follows:</p>

							<ul>
								<li><b>Identity Data</b> includes first name, last name, username or similar identifier.</li>
								<li><b>Contact Data</b> includes email address and telephone numbers.</li>
								<li><b>Technical Data</b> includes details of visits to our site, internet protocol (IP) address, your
									login data, browser type and version, time zone setting and location, browser plug-in types and
									versions, operating system and platform and other technology on the devices you use to access this
									website.
								</li>
								<li><b>Profile Data</b> includes your username and password.</li>
								<li><b>Usage Data</b> includes information about how you use our website, products and services.</li>
								<li><b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us
									and our third parties and your communication preferences.
								</li>
							</ul>

							<p>We may also collect, use and share <b>Aggregated Data</b> such as statistical or demographic data for
								any
								purpose. Aggregated Data may be derived from your personal data but is not considered personal data in
								law
								as this data does not directly or indirectly reveal your identity. For example, we may aggregate your
								Usage Data to calculate the percentage of users accessing a specific website feature. However, if we
								combine or connect Aggregated Data with your personal data so that it can directly or indirectly
								identify
								you, we treat the combined data as personal data which will be used in accordance with this Privacy
								Policy.We do not collect any <b> Special Categories of Personal Data </b> about you (this includes
								details
								about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
								political
								opinions, trade union membership, information about your health and genetic and biometric data). Nor do
								we
								collect any information about criminal convictions and offences.</p>

							<h3>Personal Data about other individuals</h3>
							<p>If you give us information on behalf of someone else, you confirm that the other person has appointed
								you to act on his/her behalf and has agreed that you can:</p>

							<ul>
								<li>give consent on his/her behalf to the processing of his/her personal data;</li>
								<li>receive on his/her behalf any data protection notices; and</li>
								<li>give consent to the transfer of his/her personal data abroad.</li>
							</ul>

							<p>Where <b>Caters News</b> proposes to use your personal data for any other purpose we will ensure that
								we
								notify you first. You will also be given the opportunity to withhold or withdraw your consent for the
								use
								of your personal data for purposes other than those listed above.</p>

							<h3>Cookies</h3>
							<p>A cookie is a small amount of data, which often includes a unique identifier that is sent to your
								computer or mobile phone (referred to here as a "<b>device</b>") browser from a website's computer
								and is stored on your device's hard drive. Each website can send its own cookie to your browser if your
								browser's preferences allow it, but (to protect your privacy) your browser only permits a website to
								access the cookies it has already sent to you, not the cookies sent to you by other websites.
								Many websites do this whenever a user visits their website in order to track online traffic flows.
								<b>Caters News</b> uses Google Analytics, a web analytics service provided by
								Google, Inc. ("<b>Google</b>"). Google Analytics uses cookies and the information generated by the
								cookie about your use of the website (including your IP address) will be
								transmitted to and stored by Google on servers in the United States. Google will use this information
								for
								the purpose of evaluating your use of <b>Caters News</b>, compiling reports on website activity for
								website operators and providing other services relating to website activity and internet usage. Google
								may
								also transfer this information to third parties where required to do so by law, or where such third
								parties process the information on Google's behalf. Google will not associate your IP address with any
								other data held by Google. By using <b>Caters News</b>, you consent to the processing of data about you
								by
								Google in the manner and for the purposes set out above.</p>

							<p>Please refer to Google's privacy policy for more information on how it treats your data:
								<a href="http://www.google.com/privacypolicy.html">http://www.google.com/privacypolicy.html</a>. If
								you would like to opt-out of Google Analytics monitoring your behaviour on <b>Caters News</b> please use
								this link
								(<a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>).</p>

							<p>If you wish to restrict or block the cookies which are set when you visit <b>Caters News</b>, you can
								do so through your browser settings, however please note that if you do this you may not be able to
								use the full functionality of <b>Caters News</b>.
								Please visit<a href="https://www.aboutcookies.org">https://www.aboutcookies.org</a>
								for comprehensive information on how to do this on a wide variety of browsers. You will also find
								details
								on how to delete cookies from your machine as well as more general information about cookies.</p>


							<h3>Users aged 16 or under</h3>
							<p>We do not knowingly collect or store any personal data about children under the age
								of 16 and we do not offer any of our products or services directly to children under the age of 13.<h3>
									If
									you fail to provide personal data</h3>Where we need to collect personal data by law, or under the
								terms
								of a contract we have with you and you fail to provide that data when requested, we may not be able to
								perform the contract we have or are trying to enter into with you (for example, to provide you with
								goods
								or services). In this case, we may have to cancel a product or service you have with us but we will
								notify
								you if this is the case at the time.
							</p>

							<h3>Sharing of data</h3>
							<p>Depending on where you live, your personal information may be shared with the Caters News Media Group
								of
								companies that are based in the UK, USA, Australia &amp; India. For example, if you are based in
								Australia, your data may initially be held on our systems in the United Kingdom and then shared with
								our subsidiary <i>Caters News Australia, 55 Pyrmont
									Bridge Road, NSW 2009</i> in Australia for administration purposes.We will not share your personal
								data with others for marketing purposes unless you have given us your permission. If we have your
								permission, we will share your personal data only with other organisations we have chosen carefully.
								This
								is set out in more detail later in the Privacy Policy.We may make other organisations' services
								available,
								through our sites, although we are not necessarily operating these sites. We process any information
								which
								we collect when you access a service provided by another organisation under this Privacy Policy.
								Information these other organisations collect is governed by their own privacy policies.We can access
								and
								release personal data to keep to relevant laws and government requests, to operate our systems properly
								and to protect both us and our users. Any other organisations who access your information in the course
								of
								providing services on our behalf will be governed by strict contractual restrictions to make sure that
								they protect your information and keep to data-protection and privacy laws which apply. We may also
								independently audit these service providers to make sure that they meet our standards. We may use
								service
								providers to help us run these sites (or services available on the sites), some of whom may be based
								outside the European Economic Area (<b>EEA</b>).Any transfer of your data out of the EEA will be subject
								to a European Commission approved contract that will safeguard your privacy rights and give you remedies
								in the unlikely event of a security breach.Some of our webpages use plug-ins from other organisations
								(such as the 'Facebook Share' function). These other organisations may use information about your visit
								to
								<b>Caters News</b> or our other websites on their pages. If you browse these pages while still also
								logged
								in to your account with us, information they collect may be connected to your account on their site. For
								more information on how these organisations use information, please read their privacy policies.</p>

							<h3>How is your personal data collected?</h3>
							<p>We use different methods to collect data from and about you including through:</p>

							<ul>
								<li><b>Direct correspondence</b>. You may give us your Identity and Contact Data by filling in forms or
									by corresponding with us by post, phone, email or otherwise. This includes personal data you provide
									when you:
									<ul>
										<li>apply for our products or services;</li>
										<li>create an account on our website;</li>
										<li>subscribe to our service or publications;</li>
										<li>request marketing to be sent to you;</li>
										<li>subscribe to a promotion or survey; or</li>
										<li>give us some feedback.</li>
									</ul>
								</li>
								<li><b>Automated technologies or interactions</b>. As you interact with our website, we may
									automatically collect Technical Data about your equipment, browsing actions and patterns. We collect
									this personal data by using cookies and other similar technologies. We may also receive Technical Data
									about you if you visit other websites employing our cookies. Please see our cookie policy <a
										href="https://www.catersnews.com/cookies">https://www.catersnews.com/cookies</a> for further
									details.
								</li>
								<li><b>Third parties or publicly available sources</b>. We may receive personal data about you from
									various third parties including Technical Data from Google, as set out previously.
								</li>
							</ul>

							<h3>How and why we use your personal data</h3>
							<p>We will only use your personal data when the law allows us to. Most commonly, we will use your
								personal data in the following circumstances:</p>

							<ul>
								<li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
								<li>Where it is necessary for our legitimate interests (or those of a third party) and your interests
									and fundamental rights do not override those interests.
								</li>
								<li>Where we need to comply with a legal or regulatory obligation.</li>
							</ul>

							<p>Generally we do not rely on consent as a legal basis for processing your personal data other than in
								relation to sending third party direct marketing communications to you via email or text message. You
								have
								the right to withdraw consent to marketing at any time by contacting us.The table below sets out how we
								use your data and the lawful basis for doing so.</p>
							<table class="table table-sm table-bordered">
								<thead>
								<tr>
									<th>Activity</th>
									<th>Which data?</th>
									<th>Why?</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>To register you as a new customer</td>
									<td>(a) Identity(b) Contact</td>
									<td>Performance of a contract with you</td>
								</tr>
								<tr>
									<td>To contact you regarding completion of the registration process</td>
									<td>(a) Identity(b) Contact</td>
									<td>Performance of a contract with you</td>
								</tr>
								<tr>
									<td>To process and deliver your order including:(a) Manage payments, fees and charges(b) Collect and
										recover money owed to us
									</td>
									<td>(a) Identity(b) Contact(c) Financial(d) Transaction(e) Marketing and Communications</td>
									<td>(a) Performance of a contract with you(b) Necessary for our legitimate interests (to recover debts
										due to us)
									</td>
								</tr>
								<tr>
									<td>To manage our relationship with you which will include:(a) Notifying you about changes to our
										terms or privacy policy(b) Asking you to leave a review or take a survey
									</td>
									<td>(a) Identity(b) Contact(c) Profile(d) Marketing and Communications</td>
									<td>(a) Performance of a contract with you(b) Necessary to comply with a legal obligation(c) Necessary
										for our legitimate interests (to keep our records updated and to study how customers use our
										products/services)
									</td>
								</tr>
								<tr>
									<td>To enable you to complete a survey</td>
									<td>(a) Identity(b) Contact(c) Profile(d) Usage(e) Marketing and Communications</td>
									<td>(a) Performance of a contract with you(b) Necessary for our legitimate interests (to study how
										customers use our products/services, to develop them and grow our business)
									</td>
								</tr>
								<tr>
									<td>To administer and protect our business and this website (including troubleshooting, data analysis,
										testing, system maintenance, support, reporting and hosting of data)
									</td>
									<td>(a) Identity(b) Contact(c) Technical</td>
									<td>(a) Necessary for our legitimate interests (for running our business, provision of administration
										and IT services, network security, to prevent fraud and in the context of a business reorganisation
										or group restructuring exercise)(b) Necessary to comply with a legal obligation
									</td>
								</tr>
								<tr>
									<td>To deliver relevant website content and advertisements to you and measure or understand the
										effectiveness of the advertising we serve to you
									</td>
									<td>(a) Identity(b) Contact(c) Profile(d) Usage(e) Marketing and Communications(f) Technical</td>
									<td>Necessary for our legitimate interests (to study how customers use our products/services, to
										develop them, to grow our business and to inform our marketing strategy)
									</td>
								</tr>
								<tr>
									<td>To use data analytics to improve our website, products/services, marketing, customer relationships
										and experiences
									</td>
									<td>(a) Technical(b) Usage</td>
									<td>Necessary for our legitimate interests (to define types of customers for our products and
										services, to keep our website updated and relevant, to develop our business and to inform our
										marketing strategy)
									</td>
								</tr>
								<tr>
									<td>To make suggestions and recommendations to you about goods or services that may be of interest to
										you on the basis of previous sales to you via electronic means (telephone, email, SMS or App push
										notifications)
									</td>
									<td>(a) Identity(b) Contact(c) Technical(d) Usage(e) Profile</td>
									<td>Necessary for our legitimate interests (to develop our products/services and grow our business)
									</td>
								</tr>
								</tbody>
							</table>

							<p>Note that we may process your personal data for more than one lawful ground depending on the specific
								purpose for which we are using your data. Please contact us if you need details about the specific legal
								ground we are relying on to process your personal data where more than one ground has been set out in
								the
								table above.</p>

							<h3>Promotional offers from us</h3>
							<p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we
								think you may want or need, or what may be of interest to you. This
								is how we decide which products, services and offers may be relevant for you (we call this
								marketing). You will receive marketing communications from us if you have requested information from us
								or purchased
								goods or services from us or if you provided us with your details when you registered for a promotion
								and, in
								each case, you have not opted out of receiving that marketing.</p>

							<h3>Opting out</h3>
							<p>You can ask us or third parties to stop sending you marketing messages by contacting us at any time.
								Where you opt out of receiving these marketing messages, this will not apply to personal data provided
								to us as a result of a
								product/service purchase. We will continue to communicate with you for reasons essential to the
								fulfilment
								of any orders.</p>

							<h3>Disclosures of your personal data</h3>
							<p>We may have to share your personal data with the parties set out below, for the purposes outlined in
								the table, above.</p>

							<ul>
								<li>Service providers who provide IT and system administration services.</li>
								<li>Professional advisers including lawyers, bankers, auditors and insurers based who provide
									consultancy, banking, legal, insurance and accounting services.
								</li>
								<li>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our
									assets.
									Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to
									our
									business, then the new owners may use your personal data in the same way as set out in this Privacy
									Policy.
								</li>
							</ul>

							<p>We require all third parties to respect the security of your personal data and to treat it in
								accordance
								with the law. We do not allow our third-party service providers to use your personal data for their own
								purposes and only permit them to process your personal data for specified purposes and in accordance
								with
								our instructions.</p>

							<h3>Your rights under the GDPR</h3>
							<p>Under the Data Protection Act 1998 (DPA) and now
								under the GDPR, organisations must set out how they intend to use your personal data and the rights you
								have
								in relation to this and must do so in a concise, transparent, intelligible and easily accessible way.
								Privacy Notices should also include the lawful basis for processing your personal data, that an
								organisation is
								choosing to rely upon.</p>

							<h3>What rights do you have</h3>
							<h4>Data Security</h4>
							<p>We have put in place appropriate security measures to prevent your personal data from being
								accidentally lost, used or
								accessed in an unauthorised way, altered or disclosed.We have put in place procedures to deal with any
								suspected
								personal data breach and will notify you and any applicable regulator of a breach where we are legally
								required to do so.</p>

							<h4>Data Retention</h4>
							<h4>How long will you use my personal data for?</h4>
							<p>We will only
								retain your personal data for as long as necessary to fulfil the purposes we collected it for, including
								for the purposes of satisfying any legal, accounting, or reporting requirements.To determine the
								appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the
								personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the
								purposes for which we process your personal data and whether we can achieve those purposes through other
								means, and the applicable legal requirements.In some circumstances you can ask us to delete your data:
								see
								<a href="#erase"><i>Request erasure</i></a>below for further information.For Personal Data that is not
								required to be kept for legal purposes, we will retain your data for <b>24 months</b> from the point at
								which you provide it.If you contact us to delete your personal data from your system, we will send you
								one final email to confirm that this has been done and we will only retain the email from you which
								requests
								the deletion of that data.In some circumstances we may anonymise your personal data (so that it can no
								longer be associated with you) for research or statistical purposes in which case we may use this
								information indefinitely without further notice to you.</p>

							<h3>Your legal rights</h3>
							<p>Under certain
								circumstances, you have rights under data protection laws in relation to your personal data, these
								include:</p>

							<ul>
								<li>Request access to your personal data.</li>
								<li>Request correction of your personal data.</li>
								<li>Request erasure of your personal data.</li>
								<li>Object to processing of your personal data.</li>
								<li>Request restriction of processing your personal data.</li>
								<li>Request transfer of your personal data.</li>
								<li>Right to withdraw consent.</li>
							</ul>

							<p>If you wish to exercise any of the rights set out above, please contact us at <a
								href="mailto:dpo@catersnews.com">dpo@catersnews.com</a>. You have the right to:</p>

							<ul>
								<li><b>Request access</b> to your personal data (commonly known as a "data subject access request").
									This enables you to receive a copy of the personal data we hold about you and to check that we are
									lawfully processing it.
								</li>
								<li><b>Request correction</b> of the personal data that we hold about you. This enables you to have
									any
									incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy
									of the new data you provide to us.
								</li>
								<li><b>Request erasure</b> of your personal data. This enables you to ask us to delete
									or
									remove personal data where there is no good reason for us continuing to process it. You also have
									the
									right to ask us to delete or remove your personal data where you have successfully exercised your
									right to object to processing (see below), where we may have processed your information unlawfully
									or
									where we are required to erase your personal data to comply with local law. Note, however, that we
									may
									not always be able to comply with your request of erasure for specific legal reasons which will be
									notified to you, if applicable, at the time of your request.
								</li>
								<li><b>Object to processing</b> of your personal data where we are relying on a legitimate interest
									(or
									those of a third party) and there is something about your particular situation which makes you want
									to
									object to processing on this ground as you feel it impacts on your fundamental rights and freedoms.
									You also have the right to object where we are processing your personal data for direct marketing
									purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process
									your
									information which override your rights and freedoms.
								</li>
								<li><b>Request restriction of processing</b> of your personal data. This enables you to ask us to
									suspend the processing of your personal data in the following scenarios: (a) if you want us to
									establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to
									erase it; (c) where you need us to hold the data even if we no longer require it as you need it to
									establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we
									need to verify whether we have overriding legitimate grounds to use it.
								</li>
								<li><b>Request the transfer</b> of your personal data to you or to a third party. We will provide to
									you, or a third party you have chosen, your personal data in a structured, commonly used,
									machine-readable format. Note that this right only applies to automated information which you
									initially provided consent for us to use or where we used the information to perform a contract with
									you.
								</li>
								<li><b>Withdraw consent at any time</b> where we are relying on consent to process your personal data.
									However, this will not affect the lawfulness of any processing carried out before you withdraw your
									consent. If you withdraw your consent, we may not be able to provide certain products or services to
									you. We will advise you if this is the case at the time you withdraw your consent.
								</li>
							</ul>

							<h3>No fee usually required</h3>
							<p>You will not have to pay a fee to access your personal data (or to
								exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly
								unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these
								circumstances.</p>

							<h3>What we may need from you</h3>
							<p>We may need to request specific information from you to
								help us confirm your identity and ensure your right to access your personal data (or to exercise any of
								your other rights). This is a security measure to ensure that personal data is not disclosed to any
								person
								who has no right to receive it. We may also contact you to ask you for further information in relation
								to
								your request to speed up our response.</p>

							<h3>Time limit to respond</h3>
							<p>We try to respond to all legitimate
								requests within one month. Occasionally it may take us longer than a month if your request is
								particularly
								complex or you have made a number of requests. In this case, we will notify you and keep you
								updated.</p>
							<h3>Changes to the Privacy Policy</h3>
							<p>Should we elect to change our Privacy Policy we will post the
								changes
								here. Where the changes are significant, we may also choose to email all our registered users with the
								new
								details. Where required by law, will we obtain your consent to make these changes.</p>

							<p><small><i>Last updated: October 2023</i></small></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";

export default {
	components: {
		SearchBar
	}
}
</script>
<style>

</style>
