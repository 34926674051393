<template>
	<modal name="enquiry-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
		<div class="p-4">
			<h3 class="pr-5">Enquire about this {{ contentType }}</h3>
			<p><b>Reference: {{ `${contentType.toUpperCase()} - ${id}` }}</b></p>
			<form class="mt-4" v-if="!enquiry.success" @submit.prevent="onSubmit">
				<div class="form-group">
					<label for="name">Your full name (required)</label>
					<input type="text" class="form-control" id="name" v-model="enquiry.name" required>
				</div>
				<div class="form-group">
					<label for="email">Your email address (required)</label>
					<input type="email" class="form-control" id="email" v-model="enquiry.email" required>
				</div>
				<div class="form-group">
					<label for="company">Your organisation (required)</label>
					<input type="text" class="form-control" id="company" v-model="enquiry.company" required>
				</div>
				<div class="form-group">
					<label for="type">Type of enquiry (required)</label>
					<select class="custom-select" id="type" v-model="enquiry.type" required>
						<option value="" selected disabled>Select an option</option>
						<option value="1">Licensing</option>
						<option value="2">Editorial</option>
						<option value="3">Support</option>
						<option value="0">Other</option>
					</select>
				</div>
				<div class="form-group">
					<label for="message">Your message</label>
					<textarea id="message" class="form-control" rows="5" v-model="enquiry.message" required></textarea>
				</div>
				<div class="alert alert-danger" role="alert" v-if="enquiry.errors">
					{{ enquiry.errors }}
				</div>
				<div class="form-group">
					<button type="submit" class="btn btn-primary">Send
						<IconArrow class="ml-2"></IconArrow>
					</button>
				</div>
			</form>
			<div v-if="enquiry.success" class="alert alert-success">
				{{ enquiry.success }}
			</div>

			<div class="modal-close">
				<a href="#" @click.prevent="$modal.hide('enquiry-modal')">
					<IconClose width="50px" height="50px" fill="#5F007F"></IconClose>
				</a>
			</div>
		</div>
	</modal>
</template>

<script>
import IconEnquiry from '@/components/icons/IconEnquiry';
import IconArrow from '@/components/icons/IconArrow';
import IconClose from '@/components/icons/IconClose';

export default {
	data() {
		return {
			enquiry: {
				name: '',
				email: '',
				company: '',
				contentType: '',
				message: '',
				errors: '',
				success: ''
			},
		}
	},
	props: [
		'id',
		'contentType'
	],
	components: {
		IconEnquiry, IconArrow, IconClose
	},
	methods: {
		async onSubmit() {
			let requestBody = {
				reference: `${this.contentType.toUpperCase()} - ${this.id}`,
				name: this.enquiry.name,
				email: this.enquiry.email,
				company: this.enquiry.company,
				type: this.enquiry.type,
				message: this.enquiry.message
			}

			try {
				let url = this.$apiBaseURL + `/clip/forms/enquire`

				const response = await fetch(url, {
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(requestBody)
				})

				const result = await response.json()

				if (result.success === true) {
					this.success = result.success;
					this.enquiry.success = result.message;
				} else {
					this.errors = result.success;
					this.enquiry.errors = result.message;
				}
			} catch (error) {
				this.showNotification(false, 'An error occurred');
				console.error(error);
			}


			// axios({
			// 	method: 'post',
			// 	url: '/api/user_forms/enquire',
			// 	data: {
			// 		reference: this.type + '-' + this.id,
			// 		name: this.enquiry.name,
			// 		email: this.enquiry.email,
			// 		company: this.enquiry.company,
			// 		type: this.enquiry.type,
			// 		message: this.enquiry.message
			// 	}
			// })
			// 	.then(response => {
			// 		if (response.data.data.success) {
			// 			if (response.data.data.message)
			// 				this.enquiry.success = response.data.data.message;
			// 			else
			// 				this.enquiry.success = 'Your message has been sent.';
			// 		} else {
			// 			if (response.data.data.message)
			// 				this.enquiry.errors = response.data.data.message;
			// 			else
			// 				this.enquiry.errors = 'There was an error';
			// 		}
			// 	})
			// 	.catch(error => {
			// 		console.log(error);
			// 	});
		},
	}
}
</script>
