<template>
	<div>
		<VideoBg :sources="[heroVideo]"></VideoBg>
		<div class="Hero">
			<div class="Hero-content">
				<h1><slot name="title"></slot></h1>
				<p><slot name="tagline"></slot></p>

				<div class="Hero-content-search">
					<h2>{{ searchMessage }}</h2>
					<form class="SearchBox SearchBox--large mb-3" @submit.prevent="submitSearch">
						<input type="text" name="keyword" class="form-control" placeholder="Enter keyword" v-model="keyword">
						<select class="custom-select" v-model="type">
							<option value="video">Videos</option>
							<option value="image">Images</option>
							<option value="packages">Packages</option>
							<option value="short">Shorts</option>
						</select>
						<button type="submit" class="SearchBox-button">
							<IconSearch></IconSearch>
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import IconSearch from '@/components/icons/IconSearch';
import VideoBg from '@vroger/vue-videobg';

export default {
	data () {
		return {
			keyword: null
		}
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		type: {
			get () {
				return this.$store.state.selectedType
			},
			set (value) {
				this.$store.commit('updateSelectedType', value);
			}
		},
		searchMessage() {
			return "Search for videos and images";
		}
	},
	props: ['heroVideo'],
	components: {
		IconSearch,
		VideoBg
	},
	methods: {
		submitSearch() {
			this.$router.push({ name: 'search', query: {
					term: this.keyword,
					type: this.type
				}})
		}
	}
}
</script>

<style scoped>
.VideoBg {
	position: fixed;
	top: 0;
	width: 100%;
	background: #333;
	z-index: -1;
}
</style>
