var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('table',{staticClass:"table table-sm"},[_c('tbody',[_vm._l((_vm.pricingOptions.video),function(po){return [_c('tr',[_c('th',[_c('span',{staticClass:"u-textColour--green"},[_vm._v(_vm._s(po.name.toUpperCase()))])]),_c('td',{staticClass:"text-right"},[(!_vm.selectedTier || _vm.selectedTier=== po.code)?_c('b-btn',{staticClass:"Button Button--orange w-100",attrs:{"squared":"","size":"sm","variant":"success"},on:{"click":function($event){$event.preventDefault();_vm.$emit('tierSelected', {
						tierUid:po.uid,
						tierCode:po.code,
						tierName:po.name,
						tierDescription:po.description,
						tierUsageType:po.usage_type,
						bundleRef:'bundle_1',
						price:parseInt(po.options.bundle_1),
						currency:_vm.currency
					})}}},[_vm._v(" "+_vm._s(_vm.currencySymbol)+_vm._s(po.options.bundle_1)+" "+_vm._s(_vm.currency)+" ")]):_c('b-btn',{attrs:{"squared":"","size":"sm","variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.alert('you cannot choose this option')}}},[_vm._v(" "+_vm._s(_vm.currencySymbol)+_vm._s(po.options.bundle_1)+" "+_vm._s(_vm.currency)+" ")])],1)])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }