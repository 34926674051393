import Vue from 'vue'
import App from './App'

import mixins from './mixins'

import store from './store'
import router from './router'

import axios from 'axios'

Vue.prototype.$apiBaseURL = 'https://api.catersnews.com/api';

window.axios = axios
axios.defaults.headers.common['Accept'] = "application/json"
// axios.defaults.headers.common['Content-Type'] = "application/json"
axios.defaults.baseURL = 'https://clipsadmin.catersnews.com'
//axios.defaults.baseURL = 'https://clipsv1.local'

axios.interceptors.request.use(
	(config) => {
		let token = store.state.token;

		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}

		return config;
	},

	(error) => {
		return Promise.reject(error);
	}
);

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

Vue.use(VuePlyr)

import vmodal from 'vue-js-modal'

Vue.use(vmodal)

import moment from 'vue-moment'

Vue.use(moment)

import {ButtonPlugin} from 'bootstrap-vue'
import {CardPlugin} from 'bootstrap-vue'
import {CollapsePlugin} from 'bootstrap-vue'

Vue.use(CollapsePlugin)
Vue.use(CardPlugin)
Vue.use(ButtonPlugin)

import {DropdownPlugin} from 'bootstrap-vue'
Vue.use(DropdownPlugin)

import IntersectionObserver from 'intersection-observer'

Vue.use(IntersectionObserver)

import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

import VuePluralize from 'vue-pluralize'

Vue.use(VuePluralize)

import Storage from 'vue-ls'

Vue.use(Storage)

Vue.config.productionTip = false

import "./assets/styles/toolkit.scss"

new Vue({
	el: '#app',
	router,
	store,
	components: {App},
	template: '<App/>',
})
