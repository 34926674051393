<template>
	<div class="TopPicks bg-light" v-if="featuredItems.length">
		<div class="container">
			<h2 class="h1 text-center">What's trending</h2>
		</div>

		<div class="Featured__row">
			<swiper :options="swiperOption">
				<div class="swiper-pagination swiper-pagination-clickable" slot="pagination"></div>
				<swiper-slide v-for="(item, index) in featuredItems" :key="index">
					<router-link
						:to="{name: 'clip', params: {id:item.id}}"
						:class="{'TopPick--green': index % 2 === 0, 'TopPick--white': index % 2 !== 0 }"
						class="TopPick"
					>
						<div class="px-1">
							<div class="Featured__thumbnail-container">
								<img
									:src="item.thumbnail"
									:alt="item.heading"
									class="Featured__thumbnail-image"
								/>
							</div>
							<span
								class="Featured__text--category"
								@click.prevent="$router.push({name:'search', query: {category: item.category, cat: item.cat_id }})"
							>
								<b>{{ item.category }}</b>
							</span>
							<span class="Featured__text--definition">
								{{ item.definition }}
								<template v-if="item.definition">|</template>
								{{ rounded(item.duration) | moment("mm:ss") }}
							</span>
							<h4 class="Featured__text--title">{{ item.heading }}</h4>
						</div>
					</router-link>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import {swiper, swiperSlide} from 'vue-awesome-swiper'

export default {
	data() {
		return {
			featuredItems: [],
			swiperOption: {
				slidesPerView: '5',
				spaceBetween: 0,
				loop: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: false
				},
				speed: 1000,
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				breakpoints: {
					1599: {
						slidesPerView: '4'
					},
					1199: {
						slidesPerView: '3'
					},
					991: {
						slidesPerView: '2'
					},
					599: {
						slidesPerView: '1'
					}
				}
			}
		}
	},
	components: {
		swiper,
		swiperSlide
	},
	methods: {
		rounded(seconds) {
			return Math.floor(seconds);
		}
	},
	beforeCreate() {
		try {
			let url = this.$apiBaseURL + '/clip/clips/featured'

			fetch(url, {
				method: 'GET',
			}).then(response => response.json())
				.then(result => {
					if (result.success === true) {
						const data = result.data
						let self = this // I don't know what this is for?

						data.forEach((obj) => {
							if (obj.type !== 'short') {
								let item = {
									id: obj.id,
									type: obj.type,
									heading: obj.title,
									thumbnail: this.fileUrl(obj.thumbpath),
									cat_id: obj.meta.category_id,
									category: obj.meta.category,
									definition: obj.meta.definition,
									duration: obj.meta.duration
								}

								this.featuredItems.push(item);
							}
						});
					}
				}).catch(error => console.log(error));
		} catch (error) {
			console.log(error)
		}
	}
}
</script>

<style scoped>
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	background-image: none;
}
</style>
<style>
.swiper-pagination-bullet {
	width: 14px !important;
	height: 14px !important;
	background: #DDD !important;
	opacity: 1 !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: 15px !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 6px !important;
}

.swiper-pagination-bullet-active {
	background: #27b6b3 !important;
}
</style>
