<template>
	<transition name="fade">
		<div class="CheckoutSummary--product d-flex bg-grey mb-1">
			<div class="CartSummary--product-details pt-2 pb-2 border-bottom w-100">
				<span class="d-block"><b>Item: </b>{{ product.type.toUpperCase() }} ID: {{ product.id}}</span>
				<span class="d-block"><b>Title: </b> {{ product.description }}</span>
				<span class="d-block"><b>Fee:</b> {{ this.$store.state.defaultCurrency.currencySymbol }}{{ product.price.toFixed(2) }}</span>

			</div>
		</div>
	</transition>
</template>

<script>

export default {
	props: {
		product: {Type: Object, default: null},
		id: {Type: String, default: null},
		date: {Type: String, default: null},
		type_prop: {Type: String, default: null},
		prepay: {Type: Boolean, default: false},
		currencySymbol: {Type: String, default: null}
	},
	components: {
	},
	data() {
		return {
			item_id: this.id,
			licensingTerm: null,
			canPrepay: this.prepay,
		}
	},
	methods: {},
	mounted() {
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
