<template>
	<div>
		<SearchBar></SearchBar>
		<template v-if="isDownloading">
			<div class="text-center mt-4">
				Preparing file - Please stay on this page.
			</div>
		</template>
		<transition name="fade">
			<div class="container py-3 page" v-if="!isLoading && !isError">
				<div class="row">
					<div class="col-lg-3 p-0">
						<div class="PackageAttributes mt-1 p-0">
							<h5 class="d-flex align-items-center p-3 mb-0">
								<IconAlbum width="22px" height="22px"></IconAlbum>
								<span class="ml-2">Package</span>
							</h5>

							<div class="px-3 pb-3">
								<h2 class="h4 mb-3 text-uppercase">
									{{ packageName }}
								</h2>
								<p v-if="packageSummary" class="mb-3">
									{{ packageSummary }}
								</p>
								<p class="mb-0 mb-2" v-if="packageDate">
									Added: {{ packageDate | moment("DD/MM/YYYY") }}
								</p>
								<p class="mb-0">
									{{ results.length }} {{ 'clip' | pluralize(results.length) }} in package
								</p>
							</div>
						</div>
						<button
							class="Button Button--purple text-uppercase w-100 mt-2 text-nowrap"
							@click="showEnquiryModal"
						>
							<span class="Button-icon">
								<IconEnquiry width="20px"></IconEnquiry>
							</span>
							Make an enquiry
						</button>
						<div>
							<button
								class="Button Button--grey text-uppercase w-100 pl-5 mt-2 text-nowrap"
								v-if="isLoggedIn && textFile !== false"
								@click="prepareTextFile"
							>
							<span class="Button-icon">
								<IconDownload width="20px"></IconDownload>
							</span>
								Download Text
							</button>

							<router-link
								v-if="!isLoggedIn"
								:to="{ name: 'login' }"
								class="Button Button--grey text-uppercase w-100 mt-2 text-nowrap"
							>
								<span>Login to view text</span>
							</router-link>&nbsp;
						</div>


						<div class="Filters pb-2 mt-1 mb-3" v-if="isLoggedIn">
							<div class="form-group mb-0 px-2 text-sm">
								<div>
									<input type="checkbox" class="sr-only" id="selectAllCheckbox" v-model="selectAll">
									<label class="font-weight-normal" for="selectAllCheckbox">
										<span class="btn btn-link p-0 border-0">
											<IconTick width="22px" fill="#5F007F"></IconTick>
											<span class="ml-2">
												Select all on page
											</span>
										</span>
									</label>
								</div>

								<a href="#" @click.prevent="deselectAll" class="d-inline-flex">
									<IconClose width="22px" fill="#5F007F"></IconClose>
									<span class="ml-2">
										Deselect all
									</span>
								</a>

							</div>
						</div>
					</div>

					<div class="col-lg-9 col-xl-9">
						<div class="ResultsGrid ResultsGrid--narrow pl-lg-2">
							<template v-for="result in results">
								<div class="ResultsGrid-item">

									<ClipCard
										enablePopup="true"
										type="image"
										:id="result.id"
										:title="result.title"
										:thumbnail="fileUrl(result.thumbpath)"
										:media="fileUrl(result.thumbpath)"
										:dateAdded="result.created"
										:enquiriesOnly="result.meta.enquire"
										:notForSocial="result.meta.notSocial"
									>
									</ClipCard>

									<div class="ResultsGrid-item-checkbox" v-if="isLoggedIn">
										<div class="custom-control custom-checkbox" v-if="!result.enquire">
											<input
												type="checkbox" class="custom-control-input"
												:id="`checkbox${result.id}-${result.type}`"
												:value="`${result.id}-${result.type}`"
												v-model="selectedClips"
											>
											<label
												class="custom-control-label"
												:for="`checkbox${result.id}-${result.type}`"
											>
											</label>
										</div>
										<div v-else>
											<span class="text-muted text-uppercase small">
												<small>Enquiries only</small>
											</span>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<div class="container py-5 mt-1" v-if="!isLoading && isError">
				<div class="row">
					<div class="col-lg-8 offset-lg-2">
						<div class="alert alert-danger text-center">
							{{ errorMessage }}
						</div>
					</div>
				</div>
			</div>
		</transition>

		<div class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>

		<transition name="fade">
			<div class="SelectionBar" v-if="selectedClips.length">
				<div class="container px-2 px-lg-3">
					<div class="d-flex align-items-center justify-content-between justify-content-sm-end">
						<span class="mr-2 mr-sm-3">
							<b>
								{{ selectedClips.length }} {{ 'clip' | pluralize(selectedClips.length) }} selected
							</b>
						</span>
						<button
							class="Button Button--hasIcon Button--green text-uppercase"
							@click.prevent="addSelectionsToQueue"
						>
							<span class="Button-icon">
							<IconDownload width="20px"></IconDownload>
						</span>
							<template>Download</template>
						</button>
					</div>
				</div>
			</div>
		</transition>

		<Enquiry :id="id" contentType="package"></Enquiry>
	</div>
</template>

<script>
import SearchBar from '@/components/SearchBar';
import Enquiry from '@/components/Enquiry';
import Loading from 'vue-loading-overlay';
import ClipCard from '@/components/ClipCard.vue';
import IconEnquiry from '@/components/icons/IconEnquiry';
import IconClose from '@/components/icons/IconClose';
import IconTick from '@/components/icons/IconTick';
import IconAlbum from '@/components/icons/IconAlbum';
import IconDownload from '@/components/icons/IconDownload';
import 'vue-loading-overlay/dist/vue-loading.css';
import IconPerson from "@/components/icons/IconPerson.vue";

export default {
	data() {
		return {
			id: this.$route.params.id,
			packageName: '',
			packageSummary: '',
			packageDate: '',
			isLoading: true,
			results: [],
			selectedClips: [],
			isError: false,
			errorMessage: '',
			hasText: false,
			textFile: false,
			isDownloading: false,
			isDownloadReady: false,
			loadingOpacity: 0,
			loadingFullPage: false,
			downloadLink: '',
		}
	},
	components: {
		IconPerson,
		SearchBar,
		Enquiry,
		Loading,
		ClipCard,
		IconClose,
		IconTick,
		IconAlbum,
		IconEnquiry,
		IconDownload
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		selectedClipsMessage() {
			return this.selectedClips.length > 1 ? this.selectedClips.length + ' items selected' : this.selectedClips.length + ' item selected';
		},
		selectAll: {
			get: function () {
				return this.results ? this.selectedClips.length === this.results.length : false;
			},
			set: function (value) {
				let selected = [];

				if (value) {
					this.results.forEach(function (result) {
						let item = result.id + '-' + result.type

						if (!result.meta.enquire) {
							selected.push(item);
						}
					});
				}

				this.selectedClips = selected;
			}
		}
	},
	methods: {
		async fetchPackage() {
			try {
				let url = this.$apiBaseURL + `/clip/packages/${this.id}`

				const response = await fetch(url, {
					method: 'GET',
				})

				const result = await response.json()

				if (result.success === true) {
					let data = result.data
					this.results = data.clips
					this.packageName = data.name
					this.packageSummary = data.summary
					this.packageDate = data.created
					this.textFile = data.textFile

					this.isLoading = false;
				}
			} catch (error) {
				console.error(error);
			}
		},
		addSelectionsToQueue() {
			if (this.selectedClips.length) {
				this.selectedClips.forEach((clip) => {
					let arr = clip.split("-");
					let id = arr[0]
					let type = arr[1]

					if (id !== undefined && type !== undefined) {
						let item = {
							id: id,
							type: type,
						}
						this.$store.commit('addToDownloadQueue', item);
					}
				});

				this.showNotification(true, 'Clips added to cart');
				this.selectedClips = [];
			}
		},
		deselectAll() {
			this.selectedClips = [];
		},
		downloadTextFile() {
			this.isLoading = false
			let arr = this.downloadLink.split("/");
			let filename = arr[arr.length - 1]

			if (this.downloadLink) {
				this.isDownloading = false;

				axios({
					url: this.downloadLink,
					method: 'GET',
					responseType: 'arraybuffer',
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res.data]));
					let link = document.createElement('a');

					link.href = url;
					link.setAttribute('download', filename);
					document.body.appendChild(link);
					link.click();
				});
			}
		},
		prepareTextFile() {
			let clipId = this.textFile;
			this.isDownloading = true;
			this.isLoading = true;

			try {
				let url = this.$apiBaseURL + `/clip/clips/text/${this.textFile}`

				fetch(url, {
					headers: {
						'Authorization': `Bearer ${this.token}`,
						'Accept': 'application/json'
					},
					method: 'GET',
				}).then(response => response.json())
					.then(result => {
						if (result.success === true) {
							this.downloadLink = result.data.file_url;
							this.downloadTextFile()
						}
					})
					.catch(error => console.log(error));
			} catch (error) {
				console.log(error)
			}
		},
		showEnquiryModal() {
			this.$modal.show('enquiry-modal');
		},
	},
	created() {
		this.fetchPackage();
	}
}
</script>

<style></style>
