<template>
	<div class="py-4 py-md-5">
		<div class="container">
			<div class="row">
				<div class="col-md-10 col-lg-8 col-xxl-6 offset-md-1 offset-lg-2 offset-xxl-3">
					<div class="shadow-lg p-4 bg-white">
						<h1 class="mb-2 text-uppercase">Create your account</h1>

						<div class="row">
							<div class="col-lg-12 col-xl-12">
								<form class="mt-2" v-if="!success" @submit.prevent>
									<div class="form-group">
										<label for="name">Your name (required)</label>
										<input
											type="text"
											class="form-control"
											id="name"
											v-model="name"
											required
											@blur="v$.name.$touch"
										>
										<div v-if="v$.name.$error" class="text-danger">
											<template v-for="error of v$.name.$errors">
												<small :key="error.$uid">
													<strong>{{ error.$message }}</strong>
												</small>
												<br>
											</template>
										</div>
									</div>

									<div class="form-group">
										<label for="email">Your email (required)</label>
										<input
											type="email"
											class="form-control"
											id="email"
											v-model="email"
											required
											@blur="v$.email.$touch"
										>
										<div v-if="v$.email.$error" class="text-danger">
											<template v-for="error of v$.email.$errors">
												<small :key="error.$uid">
													<strong>{{ error.$message }}</strong>
												</small>
												<br>
											</template>
										</div>
									</div>

									<div class="form-group">
										<label for="company">Company name (required)</label>
										<input
											type="text"
											class="form-control"
											id="company"
											v-model="company"
											required
											@blur="v$.company.$touch"
										>
										<div v-if="v$.company.$error" class="text-danger">
											<template v-for="error of v$.company.$errors">
												<small :key="error.$uid">
													<strong>{{ error.$message }}</strong>
												</small>
												<br>
											</template>
										</div>
									</div>

									<!-- NOT SURE IF WE NEED THIS NOW -->
									<!--									<div class="form-group">-->
									<!--										<label for="company">Company type/usage (required)</label>-->
									<!--										<select-->
									<!--											id="customerTypeId"-->
									<!--											v-model="customerTypeId"-->
									<!--											class="custom-select"-->
									<!--											required @blur="v$.customerTypeId.$touch"-->
									<!--										>-->
									<!--											<option value>Please select an option</option>-->
									<!--											<option value="1">For Personal/Educational Use</option>-->
									<!--											<option value="2">For growing audiences</option>-->
									<!--											<option value="3">For large followings</option>-->
									<!--										</select>-->
									<!--										<div v-if="v$.customerTypeId.$error" class="text-danger">-->
									<!--											<template v-for="error of v$.customerTypeId.$errors">-->
									<!--												<small :key="error.$uid">-->
									<!--													<strong>{{ error.$message }}</strong>-->
									<!--												</small>-->
									<!--												<br>-->
									<!--											</template>-->
									<!--										</div>-->
									<!--									</div>-->

									<div class="form-group">
										<label>Address (required)</label>
										<input
											type="text"
											class="form-control"
											placeholder="Address line one"
											id="address1"
											v-model="address1"
											required
											@blur="v$.address1.$touch"
										>
										<div v-if="v$.address1.$error" class="text-danger">
											<template v-for="error of v$.address1.$errors">
												<small :key="error.$uid">
													<strong>{{ error.$message }}</strong>
												</small>
												<br>
											</template>
										</div>
									</div>

									<div class="form-group">
										<input
											type="text"
											class="form-control"
											placeholder="Address line two"
											id="address1"
											v-model="address2"
										>
									</div>

									<div class="form-group">
										<input
											type="text"
											class="form-control"
											placeholder="Town/City"
											id="city"
											v-model="city"
											required @blur="v$.city.$touch"
										>
										<div v-if="v$.city.$error" class="text-danger">
											<template v-for="error of v$.city.$errors">
												<small :key="error.$uid">
													<strong>{{ error.$message }}</strong>
												</small>
												<br>
											</template>
										</div>
									</div>

									<div class="form-group">
										<input
											type="text"
											class="form-control"
											placeholder="County"
											id="county"
											v-model="state"
											required @blur="v$.state.$touch"
										>
										<div v-if="v$.state.$error" class="text-danger">
											<template v-for="error of v$.state.$errors">
												<small :key="error.$uid">
													<strong>{{ error.$message }}</strong>
												</small>
												<br>
											</template>
										</div>
									</div>

									<div class="form-group">
										<input
											type="text"
											class="form-control col-sm-6"
											placeholder="Postcode"
											id="postcode"
											v-model="postcode"
											required
											@blur="v$.postcode.$touch"
										>
										<div v-if="v$.postcode.$error" class="text-danger">
											<template v-for="error of v$.postcode.$errors">
												<small :key="error.$uid">
													<strong>{{ error.$message }}</strong>
												</small>
												<br>
											</template>
										</div>
									</div>

									<div class="form-group">
										<label>Country (required)</label>
										<div class="pb-4">
											<select
												id="countryId"
												class="custom-select"
												v-model="countryId"
												required
											>
												<option v-for="country in countryList" :value="country.id">
													{{ country.name }}
												</option>
											</select>
										</div>
									</div>
									<!--									<div class="form-group">-->
									<!--										<div class="custom-control custom-control-sm custom-checkbox">-->
									<!--											<input-->
									<!--												type="checkbox"-->
									<!--												class="custom-control-input custom-control-input-sm"-->
									<!--												id="prepay"-->
									<!--												v-model="prepay"-->
									<!--											>-->
									<!--											<label class="custom-control-label" for="prepay">-->
									<!--												Would you like to purchase content from our website using a credit card?-->
									<!--												<router-link-->
									<!--													:to="{name: 'pricing'}"-->
									<!--													style="cursor: pointer;"-->
									<!--													class="text-purple underline"-->
									<!--													title="click for more info"-->
									<!--												>-->
									<!--													More info on paying by credit card-->
									<!--												</router-link>-->
									<!--											</label>-->
									<!--										</div>-->
									<!--									</div>-->
									<div class="form-group">
										<div class="custom-control custom-control-sm custom-checkbox">
											<input
												type="checkbox"
												class="custom-control-input custom-control-input-sm"
												id="agreeterms"
												v-model="agreeterms"
												@blur="v$.agreeterms.$touch"
											>
											<label class="custom-control-label" for="agreeterms">
												I have read and agree to the
												<a href="./docs/licensing-terms.pdf" target="_blank">Terms & Conditions</a>
											</label>
											<div v-if="v$.agreeterms.$error" class="text-danger">
												<template v-for="error of v$.agreeterms.$errors">
													<small :key="error.$uid">
														<strong>{{ error.$message }}</strong>
													</small>
												</template>
											</div>
										</div>
									</div>
									<div class="alert alert-danger" role="alert" v-if="errors">
										{{ errorMessage }}
									</div>
									<input type="hidden" id="ashp" v-model="ashp">
									<button
										type="submit"
										class="btn btn-primary"
										:disabled="lockSubmit"
										@click="onClickSubmit"
									>
										Register
										<IconArrow class="ml-2"></IconArrow>
									</button>
								</form>

								<div v-if="success" class="alert alert-success">
									{{ successMessage }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>

		<!--		<modal name="prepay-info-modal" height="auto" :width="800" :adaptive="true" :scrollable="true">-->
		<!--			<div class="p-4">-->
		<!--				<h3 class="pr-5">CMG PREPAY</h3>-->

		<!--				<template>-->
		<!--					<p>Display some useful info about prepay ie. who it's for, what you can do, what you need to do etc-->
		<!--					</p>-->
		<!--				</template>-->
		<!--			</div>-->
		<!--		</modal>-->

	</div>
</template>

<script>
import IconArrow from '@/components/icons/IconArrow';
import Loading from 'vue-loading-overlay';

import {useVuelidate} from '@vuelidate/core'
import {helpers, required, email, sameAs} from '@vuelidate/validators'


export default {
	setup() {
		return {v$: useVuelidate()}
	},
	validations() {
		return {
			name: {required: helpers.withMessage('Please enter your name', required)},
			email: {
				required: helpers.withMessage('Please enter your email address', required),
				email: helpers.withMessage('Please enter a valid email address', email)
			},
			company: {required: helpers.withMessage('Please enter your company name', required)},
			// customerTypeId: {required: helpers.withMessage('Please select a valid option', required)},
			address1: {required: helpers.withMessage('Please enter your address', required)},
			city: {required: helpers.withMessage('Please enter your town or city', required)},
			state: {required: helpers.withMessage('Please enter your region', required)},
			postcode: {required: helpers.withMessage('Please enter your post code', required)},
			countryId: {required: helpers.withMessage('Please select a valid option', required)},
			agreeterms: {sameAs: helpers.withMessage('You must read and agree to the terms and conditions', sameAs(true))},
		}
	},
	data() {
		return {
			name: '',
			email: '',
			company: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			postcode: '',
			countryId: '225', // default to UK
			agreeterms: false,
			ashp:'',
			test: true,
			errors: false,
			success: false,
			successMessage: '',
			errorMessage: '',
			lockSubmit: false,
			isLoading: false,
		}
	},
	components: {
		IconArrow,
		Loading,
	},
	computed: {
		countryList() {
			return this.$store.state.countries
		},
		cartNotEmpty() {
			return this.$store.state.cart.length > 0
		}
	},
	methods: {
		async onClickSubmit() {
			this.lockSubmit = true;
			this.errors = '';
			let result = await this.v$.$validate();

			if (!result) {
				this.lockSubmit = false;
				this.errors = 'Please ensure you complete all required fields.'
				return false;
			}

			await this.onSubmit();
		},
		async onSubmit() {
			this.isLoading = true;
			this.errors = '';

			let requestBody = {
				name: this.name,
				email: this.email,
				company: this.company,
				address1: this.address1,
				address2: this.address2,
				city: this.city,
				state: this.state,
				postcode: this.postcode,
				countryId: this.countryId,
				checkout: this.cartNotEmpty, // if items in cart & trying to register then we assume we are at checkout
				reg: true,
				ashp: this.ashp, // Ant-Spam honeypot
			}

			try {
				let url = this.$apiBaseURL + '/clip/forms/register';

				const response = await fetch(url, {
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(requestBody)
				})

				const result = await response.json()

				if (result.success === true) {
					this.success = result.success;
					this.successMessage = result.message;
				} else {
					this.errors = result.success;
					this.errorMessage = result.message;
				}
				this.isLoading = false
			} catch (error) {
				this.showNotification(false, 'An error occurred');
				console.error(error);
			}
		},
	},
	mounted() {
	},
	beforeCreate() {
		if (this.$route.query.checkout !== undefined) {
			this.$store.dispatch('updateAuthCheckout', true)
		}

		if (this.$store.state.isLoggedIn) {
			this.$router.push({name: 'dashboard'});
		}
	},
	created() {
		console.log(this.cartEmpty)
	},
}
</script>
