import Vue from "vue";
import Notifications from "vue-notification";

Vue.use(Notifications);

Vue.mixin({
	data() {
		return {
			notForSocialMessage: "Not for publication on Social Media"
		};
	},
	methods: {
		showNotification: function (success, message, duration) {
			if (message) {
				let typename = success ? "success" : "error";
				this.$notify({
					type: typename,
					text: message,
					duration: duration ? duration : 4000,
					width: "800px"
				});
			}
		},

		fileUrl: function (filepath) {
			return "https://clipsadmin.catersnews.com" + filepath;
		},

		notForPublicationOnSocialMedia: function (licensingTerm) {
			let categories = licensingTerm.map(a => a.id);
			return categories.includes("8");
		},
		randomString: function (length) {
			return Math.random()
				.toString(36)
				.substring(2, length)
				.split("")
				.map(e => (Math.random() < Math.random() ? e.toUpperCase() : e))
				.join()
				.replaceAll(",", "");
		}
	}
});
