<template>
	<div>
		<div class="container py-4 py-md-5">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<template>
						<h1>CMG Terms & Conditions</h1>
						<p>Please click on any panel below to view the terms</p>
						<div class="LicensingTerms">
							<div class="accordion" role="tablist">
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-1 variant="secondary">CONTENT LICENSING</b-button>
									</b-card-header>
									<b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-card-text>
												<LicensingTerms></LicensingTerms>
											</b-card-text>
										</b-card-body>
									</b-collapse>
								</b-card>

								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-2 variant="secondary">CONTENT DOWNLOADS</b-button>
									</b-card-header>
									<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-card-text>
												<LicensingTerms></LicensingTerms>
											</b-card-text>
										</b-card-body>
									</b-collapse>
								</b-card>

								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-3 variant="secondary">TERMS OF BUSINESS</b-button>
									</b-card-header>
									<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-card-text>
												<LicensingTerms></LicensingTerms>
											</b-card-text>
										</b-card-body>
									</b-collapse>
								</b-card>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LicensingTerms from '@/components/LicensingTerms.vue'

export default {
	components: {
		LicensingTerms
	},
}
</script>
