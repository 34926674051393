<!--suppress ALL -->
<template>
	<div>
		<SearchBar></SearchBar>
		<div class="py-4 py-md-5">
			<div class="container page">
				<div class="row">
					<div class="col-12">
						<h1 class="mb-1">Checkout</h1>
						<small class="d-block mb-3 text-primary">Please ensure that you review your items before continuing with
							payment</small>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="px-5 pt-5 pb-2 mb-3 bg-grey border">
							<h6 class="text-info">Cart Summary</h6>

							<div v-for="(product, idx) in myCart" :key="idx">
								<CheckoutSummaryCard
									:product="product"
									:currencySymbol="currencySymbol"
									:trashable="true"
									:canPurchase="canPurchase"
									@itemDeleted="deleteFromCart(product)">
								</CheckoutSummaryCard>
							</div>
							<div class="pt-3">
								<p class="text-right">
									<b>TOTAL NET FEE:</b> {{ currencySymbol }}{{ cartTotal.toFixed(2) }} {{ cartCurrency.toUpperCase() }}
									<br>
									<b>SALES TAX:</b> {{ currencySymbol }}{{ salesTaxAmount.toFixed(2) }} {{ cartCurrency.toUpperCase() }}
									<br>
									<b>TOTAL FEE:</b> {{ currencySymbol }}{{ feeTotal.toFixed(2) }} {{ cartCurrency.toUpperCase() }}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-6">

						<div class="p-5 bg-white">
							<h6 class="text-info pb-0 mb-0">Please complete the following information</h6>
							<small class="d-inline-block pb-3 text-danger">All fields are required</small>
							<form id="usage">
								<div class="form-group">
									<label for="company">Your Full Name</label>
									<input
										type="text"
										class="form-control"
										id="orderedBy"
										name="orderedBy"
										v-model="userFullName"
										required
										readonly
									>
								</div>

								<div class="form-group">
									<label for="company">Your Company/Organisation Name</label>
									<input
										type="text"
										class="form-control"
										id="accountName"
										name="accountName"
										v-model="accountName"
										required
										readonly
									>
								</div>

								<div class="form-group">
									<label for="userEmail">Your Email Address</label>
									<input
										type="email"
										class="form-control"
										id="userEmail"
										name="userEmail"
										v-model="userEmail"
										required
										readonly
									>
								</div>

								<div class="form-group">
									<label for="usageType">
										Usage Type
										<a href="#" @click.prevent="showUsageTypeModal" class="">
											<IconInfo size="15px" class="text-primary"></IconInfo>
										</a>
									</label>
									<select
										@change="getSelectedUsage"
										id="usageType"
										:selected="`${usageType}`"
										name="usageType"
										class="custom-select"
										v-model="usageType"
										required
									>
										<option value>Please select an option</option>
										<option value="DELI">Digital Editorial (Under 5 million)</option>
										<option value="DEMA">Digital Editorial (Over 5 million)</option>
										<option value="NNTS">National News/Talk Show</option>
										<option value="CSDO">Clip Shows</option>
									</select>
									<div v-if="v$.usageType.$error" class="text-danger">
										<template v-for="error of v$.usageType.$errors">
											<small :key="error.$uid">
												<strong>{{ error.$message }}</strong>
											</small>
											<br>
										</template>
									</div>
								</div>

								<div v-if="usageType==='NNTS' || usageType==='CSDO'" class="form-group">
									<label for="production">Program/Production</label>
									<input
										@input="updateProgramUsage"
										type="text"
										class="form-control"
										id="pUsage"
										name="pUsage"
										v-model="pUsage"
										required
									>
									<div v-if="v$.pUsage.$error" class="text-danger">
										<template v-for="error of v$.pUsage.$errors">
											<small :key="error.$uid">
												<strong>{{ error.$message }}</strong>
											</small>
											<br>
										</template>
									</div>
								</div>

								<div v-if="usageType==='DELI' || usageType==='DEMA'" class="form-group">
									<label for="digital">Website/Social Media URLs</label>
									<textarea
										@input="updateDigitalUsage"
										class="form-control"
										id="dUsage"
										name="dUsage"
										v-model="dUsage"
										required
										placeholder="Please list all of the URLs associated with your brand and separate them using a comma"
									>
									</textarea>
									<div v-if="v$.dUsage.$error" class="text-danger">
										<template v-for="error of v$.dUsage.$errors">
											<small :key="error.$uid">
												<strong>{{ error.$message }}</strong>
											</small>
											<br>
										</template>
									</div>
								</div>
								<input type="hidden" id="accountOrigin" name="accountOrigin" v-model="accountOrigin" required>
								<input type="hidden" id="accountId" name="accountId" v-model="accountId" required>
								<input type="hidden" id="billingId" name="billingId" v-model="billingId" required>
							</form>
						</div>
					</div>

					<div class="col-6">
						<div class="p-5 bg-white">
							<h6 class="text-info">Billing Information</h6>
							<form id="payment-form">
								<div class="mb-3" id="address-element">
								</div>
								<h6 class="text-info">Payment Methods</h6>
								<div class="mb-3" id="express-element">
								</div>
								<div id="payment-element">
								</div>
								<div class="mt-3">
									<input
										class="d-inline-block mr-2"
										type="checkbox"
										id="checkTerms"
										name="checkTerms"
										v-model="checkTerms"
									>
									<label class="d-inline-block" for="vehicle1"> I agree to the&nbsp;</label>
									<a href="./docs/licensing-terms.pdf" target="_blank">
										<b>Licensing Terms & Conditions</b>
									</a>
									<div v-if="v$.checkTerms.$error" class="text-danger">
										<template v-for="error of v$.checkTerms.$errors">
											<small :key="error.$uid">
												<strong>{{ error.$message }}</strong>
											</small>
											<br>
										</template>
									</div>
								</div>
								<button class="Button Button--purple-outline mt-3 w-100" id="submit">
									Pay {{ currencySymbol }}{{ feeTotal.toFixed(2) }} {{ cartCurrency.toUpperCase() }}
								</button>
								<div id="error-message" class="w-100 py-1 text-danger"></div>
							</form>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isLoading" class="vld-parent">
			<loading :active.sync="isLoading"></loading>
		</div>

		<modal name="usage-type-modal" height="auto" :width="600" :adaptive="true" :scrollable="true">
			<div class="p-4">
				<h3 class="pr-5">Usage Type Definitions</h3>
				<UsageTypes></UsageTypes>
				<div class="modal-close">
					<a href="#" @click.prevent="$modal.hide('usage-type-modal')">
						<IconClose width="50px" height="50px" fill="#5F007F"></IconClose>
					</a>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {useVuelidate} from '@vuelidate/core'
import {helpers, required, email, sameAs, requiredIf} from '@vuelidate/validators'
import {stripeOptions} from "@/modules/stripeOptions";

import SearchBar from '@/components/SearchBar.vue';
import CheckoutSummaryCard from "@/components/CheckoutSummaryCard.vue";
import LicensingTerms from '@/components/LicensingTerms.vue'
import UsageTypes from '@/components/UsageTypes.vue'
import IconInfo from "@/components/icons/IconInfo.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
	setup() {
		return {
			v$: useVuelidate({
				$autoDirty: true,
				$lazy: true,
			})
		}
	},
	validations() {
		return {
			usageType: {required: helpers.withMessage('Please select a usage type', required)},
			pUsage: {
				requiredIf: helpers.withMessage('Please fill in this field',
					requiredIf(() => {
						return ['NNTS', 'CSDO'].includes(this.usageType);
					})),
			},
			dUsage: {
				requiredIf: helpers.withMessage('Please fill in this field',
					requiredIf(() => {
						return ['DEMA', 'DELI'].includes(this.usageType);
					})),
			},
			checkTerms: {
				sameAs: helpers.withMessage('You must read and agree to the terms and conditions', sameAs(true))
			},
		}
	},
	props: {},
	data() {
		return {
			isLoading: false,
			checkTerms: false,
			usageType: this.$store.state.selectedTier ? this.$store.state.selectedTier.tierCode : null,
			pUsage: null, // program usage
			dUsage: null, // digital usage,
			rd: {},
			selectedUsage: false,
			salesTax: false,
			salesTaxAmount: 0,
			saleFeetotal: this.cartTotal
		}
	},
	components: {
		IconClose,
		IconInfo,
		CheckoutSummaryCard,
		LicensingTerms,
		UsageTypes,
		SearchBar,
		Loading
	},
	computed: {
		numberInCart() {
			return this.$store.state.cart.length;
		},
		myCart() {
			return this.$store.state.cart;
		},
		feeTotal() {
			return this.cartTotal + this.salesTaxAmount;
		},
		cartTotal() {
			return this.$store.state.cartTotal;
		},
		cartCurrency() {
			return this.currency.toLowerCase();
		},
		currency() {
			return this.$store.state.defaultCurrency.currency;
		},
		currencySymbol() {
			return this.$store.state.defaultCurrency.currencySymbol;
		},
		pricingOptions() {
			return this.$store.state.pricingOptions
		},
		userId() {
			return this.$store.state.account.userId
		},
		userFullName() {
			return this.$store.state.account.userFullName
		},
		userEmail() {
			return this.$store.state.account.userEmail
		},
		accountOrigin() {
			return this.$store.state.account.origin
		},
		accountName() {
			return this.$store.state.account.accountName
		},
		accountId() {
			return this.$store.state.account.accountId
		},
		accountCountryCode() {
			return this.$store.state.account.countryCode
		},
		accountPostCode() {
			return this.$store.state.account.postcode
		},
		billingId() {
			return this.$store.state.account.billingId
		},
		canPurchase() {
			return this.$store.state.account.options.canPurchase
		},
		selectedTier() {
			return this.$store.state.selectedTier
		}
	},
	methods: {
		showLoading() {
			this.isLoading = true;
		},
		showUsageTypeModal() {
			this.$modal.show('usage-type-modal');
		},
		deleteFromCart(item) {
			this.$store.commit('removeFromCart', item);
			this.calculateCart()
			this.showNotification(true, 'The item was removed from your cart');
		},
		getSelectedUsage: function (event) { // get the selected usage & set as the current tier
			let tierCode = event.target.value;
			let currency = this.currency
			let pricing = this.pricingOptions[currency].video // for now we can hard code for video
			let tier = pricing.find(i => i.code === tierCode);

			if (tier) {
				this.$store.dispatch('updatePricingTier', tier)
				this.updateCartForSelectedUsage()
			}
		},
		updateCartForSelectedUsage() { // update the cart items based on the selected tier
			this.myCart.forEach((clip, idx) => {
				let bundle = clip.tier.bundleRef
				clip.tier = this.selectedTier
				clip.ourRef = `${clip.media}-${clip.id}`
				clip.productRef = `${this.selectedTier.tierCode}-${clip.ourRef}`
				clip.price = this.selectedTier.price
				this.$store.commit('saveCart')
			});

			this.showNotification('true', "Checkout has been updated")
		},
		async checkForTaxes() {
			let host = window.location.hostname
			let testMode = false

			// conditions for testmode
			if (host === 'localhost' || host === 'ecommtest.catersnews.com') {
				testMode = true
			}

			let taxableCurrencies = ['gbp', 'aud']

			if (taxableCurrencies.includes(this.cartCurrency)) {
				let lineItems = [];

				this.myCart.forEach(function (item, idx) {
					let lineItem = {
						amount: item.price * 100,
						reference: item.productRef,
						quantity: item.qty,
						tax_behavior: 'exclusive'
					}

					lineItems.push(lineItem)
				})

				let customer = {
					address: {
						country: this.accountCountryCode.toUpperCase(),
						postal_code: this.accountPostCode
					},
					address_source: 'shipping'
				}

				let requestBody = {
					currency: this.cartCurrency,
					line_items: lineItems,
					customer_details: customer,
					testMode: testMode
				}

				// Get the sales tax amounts to apply
				const res = await fetch(this.$apiBaseURL + '/clip/payments/salestax', {
					headers: {
						"Content-Type": "application/json",
						"Accept": "application/json",
					},
					method: "POST",
					body: JSON.stringify(requestBody)
				});

				const response = await res.json();

				if (response.success === true) {
					const result = response.data
					this.salesTaxAmount = result.tax_amount_exclusive / 100
					this.salesTax = true
				}
			}
		},
		initStripe() {
			let host = window.location.hostname
			let testMode = false // default setting is for LIVE
			let pKey = stripeOptions.pkLive // default setting is for LIVE

			// Check if we should be in TEST mode
			if (host === 'localhost' || host === 'ecommtest.catersnews.com') {
				pKey = stripeOptions.pkTest
				testMode = true
			}

			let nameAddress = {
				name: this.userFullName,
				address: {
					country: this.accountCountryCode,
				},
			}

			// Now we can continue with the request for a paymentIntent from Stripe
			const stripe = Stripe(pKey);

			const options = {
				mode: 'payment',
				amount: this.feeTotal * 100,
				currency: this.cartCurrency,
				appearance: {
					theme: 'flat'
				}, // @todo Add more customisation as required
			};

			const addressOptions = {
				mode: 'billing',
				defaultValues: nameAddress
			};

			// Set up Stripe and Elements to use in checkout form
			const elements = stripe.elements(options);

			// Create Elements
			const addressElement = elements.create('address', addressOptions);
			const expressElement = elements.create('expressCheckout');
			const paymentElement = elements.create('payment');

			// Mount elements
			addressElement.mount('#address-element');
			expressElement.mount('#express-element')
			paymentElement.mount('#payment-element');

			const form = document.getElementById('payment-form');
			const submitBtn = document.getElementById('submit');

			// custom form elements - all of these need to be validated
			const orderedBy = document.getElementById('orderedBy');
			const email = document.getElementById('userEmail');
			const account = document.getElementById('accountName');
			const usageType = document.getElementById('usageType');
			const dUsage = document.getElementById('dUsage');
			const pUsage = document.getElementById('pUsage');
			const termsCheck = document.getElementById('checkTerms');

			// validation for card element
			paymentElement.addEventListener('change', function (event) {
				let displayError = document.getElementById('error-message');
				if (event.error) {
					displayError.textContent = event.error.message;
				} else {
					displayError.textContent = '';
				}
			});

			// -- FORM SUBMISSION --
			form.addEventListener('submit', async (event) => {
				// We don't want to let default form submission happen here,
				// which would refresh the page.
				event.preventDefault();

				// Prevent multiple form submissions
				submitBtn.disabled = true;

				// Trigger form validation and wallet collection
				let isFormCorrect = await this.v$.$validate();
				const {error: submitError} = await elements.submit();

				// Form invalid
				if (!isFormCorrect || submitError) {
					submitBtn.disabled = false;
					return false;
				}

				let customer = {
					accountOrigin: this.accountOrigin,
					accountId: this.accountId,
					billingId: this.billingId,
					accountName: this.accountName,
					accountEmail: this.userEmail,
					orderedBy: this.userFullName,
				}

				let usageDetails = '';

				if (['DEMA', 'DELI'].includes(this.usageType)) {
					usageDetails = this.dUsage;
				} else {
					usageDetails = this.pUsage;
				}

				let usage = {
					orderedBy: this.userFullName,
					usageType: this.usageType,
					usageDetails: usageDetails,
				}

				let purchase = {
					cartContents: this.myCart,
					cartTotal: this.cartTotal,
					salesTax: this.salesTax,
					salesTaxAmount:this.salesTaxAmount,
					totalSaleAmount: this.feeTotal,
					currency: this.cartCurrency,
				}

				let requestBody = {
					purchase: purchase,
					usage: usage,
					customer: customer,
					testMode:testMode
				}

				// Create the PaymentIntent and obtain clientSecret
				const res = await fetch(this.$apiBaseURL + '/clip/payments/intent', {
					headers: {
						"Content-Type": "application/json"
					},
					method: "POST",
					body: JSON.stringify(requestBody)
				});

				const response = await res.json();
				const result = response.data

				if (response.success === true) {
					const clientSecret = result.client_secret // this is the unique payment intent reference identifier

					this.$store.commit('setPaymentTransactionKey', clientSecret)
					this.$store.commit('purchaseUsage', usage)

					let returnUrl = 'https://clips.catersnews.com/#/confirm-payment/'

					if (host === 'localhost') {
						returnUrl = 'http://localhost:8080/#/confirm-payment/'
					}

					if (host === 'ecommtest.catersnews.com') {
						returnUrl = 'https://ecommtest.catersnews.com/#/confirm-payment/'
					}

					// Confirm the PaymentIntent using the details collected by the Payment Element
					const {error} = await stripe.confirmPayment({
						elements,
						clientSecret,
						confirmParams: {
							return_url: returnUrl
						},
					});

					if (error) {
						// This point is only reached if there's an immediate error when
						// confirming the payment. Show the error to your customer (for example, payment details incomplete)
						handleError(error);
					} else {
						// Your customer is redirected to your `return_url`. For some payment
						// methods like iDEAL, your customer is redirected to an intermediate
						// site first to authorize the payment, then redirected to the `return_url`.
					}
				} else {
					this.showNotification(false, response.message)
					submitBtn.disabled = false;

					return false;
				}
			});

			const handleError = (error) => {
				const messageContainer = document.querySelector('#error-message');
				messageContainer.textContent = error.message;
				submitBtn.disabled = false;
			}
		},
		calculateCart() {
			let total = 0
			if (this.myCart.length) {
				this.myCart.forEach((o, idx) => {
					total = total + (o.price * o.qty)
				})

				this.$store.commit('setCartTotal', total)
			}
		},
		updateProgramUsage: function (event) {
			this.pUsage = event.target.value;
		},
		updateDigitalUsage: function (event) {
			this.dUsage = event.target.value;
		},
	}
	,
	beforeMount() {
		// reset transaction
		this.$store.commit('resetTransaction')
		this.calculateCart()
	}
	,
	mounted() {
		this.checkForTaxes();
		this.initStripe();
	},
	updated() {
		this.calculateCart()
	}
}
</script>

<style scoped>
.vld-parent {
	width: 64px;
	height: 64px;
	margin: 0 auto;
}

.vld-overlay:focus {
	outline: 0;
}
</style>
