<template>
	<div>
		<SearchBar></SearchBar>
		<div class="container page py-4 py-md-5">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<h1>About Caters Media Group</h1>
					<div>

						<p>Founded in 1927 by Herbert S Cater, Caters News Agency is the UK’s oldest independent press agency.
							Today, the Caters group works across four continents out of our Birmingham HQ, our bureaus in New York,
							Los Angeles, Sydney and New Delhi, alongside our sister agency, Mercury Press based in Liverpool.</p>

						<p>With our team of multi-lingual and dynamic journalists, we not only source and license the most
							up-to-date news and viral User Generated Content, we also film our own professional shoots. This means
							Caters can service the world’s most influential media with compelling rights cleared video.</p>

						<p>Browse our archive of more than 12,000 videos or contact our devoted licensing team by completing the
							form here or you can email us directly at licensing@catersnews.com</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconAdd from "@/components/icons/IconAdd.vue";
import SearchBar from "@/components/SearchBar.vue";

export default {
	components: {SearchBar, IconAdd}
}
</script>
