<template>
	<div>
		<div v-if="tierCode==='DELI'">
			<span>Yes</span>
			<span>Unlimited</span>
			<span>5 million or less</span>
			<span>1</span>
			<span>1</span>
			<span>N/A</span>
			<span>Yes</span>
			<span>Social Media and dedicated websites</span>
			<span>Yes</span>
		</div>

		<div v-if="tierCode==='DEMA'">
			<span>Yes</span>
			<span>Unlimited</span>
			<span>10 million or less</span>
			<span>1</span>
			<span>1</span>
			<span>N/A</span>
			<span>Yes</span>
			<span>Social Media and dedicated websites</span>
			<span>Yes</span>
		</div>

		<div v-if="tierCode==='NNTS'">
			<span>N/A</span>
			<span>N/A</span>
			<span>N/A</span>
			<span>N/A</span>
			<span>N/A</span>
			<span>1</span>
			<span>Yes</span>
			<span>All Media + Worldwide</span>
			<span>Yes</span>
		</div>

		<div v-if="tierCode==='CSDO'">
			<span>N/A</span>
			<span>N/A</span>
			<span>N/A</span>
			<span>N/A</span>
			<span>N/A</span>
			<span>1</span>
			<span>Yes</span>
			<span>All Media + Worldwide</span>
			<span>Yes</span>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		tierCode: {Type: String}
	}
}


</script>

<style scoped>

</style>
